import { UploadOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { formatMessageError } from "@functions/formatMessageError";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { IAnexos } from "@interfaces/IAnexos";
import { apiService } from "@services/api";
import { Button, message, Tooltip, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { Buffer } from "buffer";
import { migrarTermoAntigo } from "pages/tif/functions/migrarTermoAntigo";
import { formatToFormData } from "../../../functions/formatToFormData";
import { useTIF } from "../../../hooks/useTIF";

export function SubmitBackupTIF() {
  const { adicionarTifByFiltro, atualizarTif, listaTif, getAnexo } = useTIF();

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeUpload = async (
    info: UploadChangeParam<UploadFile<any>>
  ) => {
    const keyMessage = "termo";

    try {
      const anexo = await getAnexosFormatadosBase64(info.fileList);
      //SLICE NA POSICAO 14 PARA REMOVER CARACTERES ESPECIAIS
      const json = Buffer.from(anexo[0].arquivo, "base64").toString();
      let jsonObject = JSON.parse(json.slice(14));

      if (
        jsonObject?.respostasAtividades?.find(
          (resposta: any) => resposta?.pergunta?.formatoResposta === undefined
        )
      ) {
        jsonObject = migrarTermoAntigo(jsonObject);
      }

      const anexosFile = jsonObject.anexosTIF.map((anexo: IAnexos) => {
        return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
      });

      message.loading({
        duration: 0,
        content: "Enviando Termo...",
        key: keyMessage,
      });

      const result = await apiService.tif.enviar(
        formatToFormData(jsonObject, anexosFile)
      );

      const tifInApp = listaTif.find((tif) => tif.numero === result.numero);

      if (tifInApp) {
        atualizarTif({ ...tifInApp, id: result.id });
        message.destroy(keyMessage);
        openNotification({
          type: "success",
          message: "TIF enviado com sucesso",
        });

        return;
      }

      if (!result.id) return;

      const responseTif = await apiService.tif.byId(result.id);

      const dadosAnexos = responseTif.anexosTIF.map((anexo) => {
        return {
          nomeUpload: anexo.nomeUpload,
          nomeArquivo: anexo.nomeArquivo,
        };
      });

      if (dadosAnexos.length <= 0) {
        adicionarTifByFiltro({
          ...responseTif,
          anexosTIF: [],
        });
      } else {
        const anexos = await getAnexo(dadosAnexos);
        // console.log(anexos);

        adicionarTifByFiltro({
          ...responseTif,
          anexosTIF: anexos,
        });
      }

      message.destroy(keyMessage);
      openNotification({
        type: "success",
        message: "TIF enviado com sucesso",
      });
    } catch (error) {
      console.log(error);
      message.destroy(keyMessage);
      const messageError = formatMessageError(error);

      openNotification({
        type: "error",
        message: "Não foi possivel enviar backup do TIF",
        description: messageError,
      });
    }
  };

  return (
    <>
      <Tooltip title="Enviar backup do TIF" placement="left">
        <Upload
          customRequest={dummyRequest}
          onChange={handleChangeUpload}
          fileList={[]}
        >
          <Button
            disabled={!navigator.onLine}
            style={{ boxShadow: "5px 5px 5px rgba(0,0,0,0.2)" }}
          >
            <UploadOutlined />
          </Button>
        </Upload>
      </Tooltip>
    </>
  );
}
