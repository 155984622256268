import { ExclamationCircleOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { formatMessageError } from "@functions/formatMessageError";
import { masks } from "@functions/mascaras";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { useForms } from "@hooks/useForms";
import { IAssinante } from "@interfaces/IAssinante";
import { ICultivares } from "@interfaces/ICultivares";
import { IFormValuesTermoColeta } from "@interfaces/IFormValuesTermoColeta";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import {
  Button,
  Divider,
  Form,
  message,
  Modal,
  Result,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import confirm from "antd/lib/modal/confirm";
import fs from "file-saver";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Amostrador } from "../components/Amostrador";
import { AmostrasTermo } from "../components/AmostrasTermo";
import { AssinaturasTermo } from "../components/AssinaturasTermo";
import { DadosGeraisTCA } from "../components/DadosGeraisTCA";
import { OrigemTCA } from "../components/OrigemTCA";
import { useTCA } from "../hooks/useTCA";

export function EditTermoColeta() {
  const [formColeta] = Form.useForm<IFormValuesTermoColeta>();
  const [formDadosAmostra] = Form.useForm();
  const { numTermo } = useParams();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const refAmostrador = useRef<any>(null);
  const { cpfCnpjMask } = masks;
  const {
    getAmostrador,
    getEspecieSelected,
    getTermoExistente,
    getFormSubmit,
    adicionarTermoColeta,
    removerTermoColeta,
    atualizarTermoColeta,
    listaTermos,
    removerTodasAmostras,
    adicionarAmostra,
    listaAmostras,
  } = useTCA();
  const { messageSubmitFailed } = useForms();

  const termoColetaSelected = listaTermos.find(
    (termo) => termo.termoColetaAmostra === numTermo?.replaceAll("_", "/")
  );

  const [statusAssinaturaColeta, setStatusAssinaturaColeta] = useState<
    CheckboxValueType[]
  >([]);
  const [assinaturaAmostrador, setAssinaturaAmostrador] = useState("");
  const [assinaturaFiscalizado, setAssinaturaFiscalizado] =
    useState<IAssinante>({
      assinatura: null,
      nomeAssinante: termoColetaSelected?.nomeAssinante || "",
      cpfAssinante: termoColetaSelected?.cpfAssinante || "",
      funcaoAssinante: termoColetaSelected?.funcaoAssinante || "",
    });
  const [isOpenViewAmostras, setIsOpenViewAmostras] = useState(false);
  const [cultivaresOptions, setCultivaresOptions] = useState<ICultivares[]>([]);
  const [isSavingTermo, setIsSavingTermo] = useState(false);

  const handleCancelEdit = () => {
    confirm({
      title: `Tem certeza que deseja cancelar alterações do Termo de coleta?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        navigate("/termo-coleta/listagem");
      },
    });
  };

  const handleSubmitForm = async (values: IFormValuesTermoColeta) => {
    const keyMessage = "termo-coleta";
    const amostrador = getAmostrador(
      values.matriculaAmostrador.replace(/\D/g, "") || ""
    );
    const especieSelected = getEspecieSelected(values.especieId);
    const tifExiste = await getTermoExistente(values.numeroTIF);

    //VALIDACOES
    //VALIDACAO
    if (!amostrador) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description: "Preencha os dados do amostrador.",
      });

      return;
    }

    if (
      (!assinaturaFiscalizado.nomeAssinante ||
        !assinaturaFiscalizado.cpfAssinante ||
        !assinaturaFiscalizado.funcaoAssinante) &&
      statusAssinaturaColeta.length === 0
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description: "Preencha os dados do assinante",
      });

      return;
    }

    if (
      statusAssinaturaColeta[0] === ASSINATURA_FISICA &&
      (!assinaturaFiscalizado.nomeAssinante ||
        !assinaturaFiscalizado.cpfAssinante ||
        !assinaturaFiscalizado.funcaoAssinante)
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description: "Preencha os dados do assinante",
      });
      return;
    }

    if (
      getStatusAssinatura(statusAssinaturaColeta) === "ASSINADO_DIGITALMENTE" &&
      (!assinaturaAmostrador || !assinaturaFiscalizado.assinatura)
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description: "Preencha as assinaturas pendentes",
      });

      return;
    }

    if (
      (getStatusAssinatura(statusAssinaturaColeta) === "AUSENTE" ||
        getStatusAssinatura(statusAssinaturaColeta) === "RECUSADO") &&
      !assinaturaAmostrador
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de coleta",
        description: "Preencha a assinatura do amostrador",
      });

      return;
    }

    if (listaAmostras.length === 0) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Coleta",
        description: "Adicione ao menos uma amostra ao Termo.",
      });
      return;
    }

    if (!tifExiste && values.numeroTIF) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Coleta",
        description:
          "Selecione um Termo de Fiscalização válido para concluir o Termo.",
      });

      return;
    }

    try {
      const formValues = await getFormSubmit({
        assinaturaFiscalizado,
        statusAssinaturaColeta,
        assinaturaAmostrador,
        values,
        especieSelected,
        numTermoColeta: termoColetaSelected?.termoColetaAmostra || "",
        amostrador,
      });

      if (navigator.onLine) {
        setIsSavingTermo(true);
        message.loading({
          key: keyMessage,
          duration: 0,
          content: "Salvando Termo...",
        });

        await apiService.termoColeta.enviar(formValues).then((res) => {
          atualizarTermoColeta({ ...formValues, id: res.data.id });
          setIsSavingTermo(false);
          message.destroy(keyMessage);
          openNotification({
            type: "success",
            message: "Termo de Coleta Atualizado com sucesso!",
            description: "Termo salvo na base de dados",
          });
          removerTodasAmostras();
          navigate("/termo-coleta/listagem");
        });
      } else {
        removerTermoColeta(formValues.termoColetaAmostra);
        adicionarTermoColeta(formValues);

        // MODAL DE CONFIRMAÇÃO SE O USUARIO QUISER BAIXAR UM BACKUP
        modal.info({
          title: `Gostaria de baixar um backup do Termo de Coleta?`,
          icon: <ExclamationCircleOutlined />,
          okText: "Sim",
          onOk: () => {
            let blob = new Blob([JSON.stringify(formValues)], {
              type: "text/plain;charset=utf-8",
            });
            fs.saveAs(
              blob,
              `Termo de Coleta ${formValues.termoColetaAmostra}.txt`,
              {
                autoBom: true,
              }
            );
            openNotification({
              type: "success",
              message: "Termo de Coleta atualizado com sucesso!",
              description:
                "Salvamento do termo pendente, aplicativo sem acesso a internet. ",
            });

            removerTodasAmostras();
            navigate("/termo-coleta/listagem");
          },
        });
      }
    } catch (error) {
      console.log(error);
      setIsSavingTermo(false);
      message.destroy(keyMessage);
      const messageError = formatMessageError(error);
      openNotification({
        type: "error",
        message: "Não foi possivel editar Termo de coleta de amostras",
        description: messageError,
      });
    }
  };

  useEffect(() => {
    document.title = "Edição Termo de coleta | SISDEV-mobile";
    setIsOpenViewAmostras(true);
    removerTodasAmostras();
    termoColetaSelected?.itens.forEach((amostra) => {
      adicionarAmostra(amostra);
    });

    formColeta.setFieldsValue({
      matriculaAmostrador: termoColetaSelected?.renasemMatriculaAmostrador,
      nomeAmostrador: termoColetaSelected?.amostrador?.pessoa.nome,
      destino: termoColetaSelected?.destino,
      tipoAmostra: termoColetaSelected?.tipoColeta,
      paisOrigem: termoColetaSelected?.paisOrigem,
      safra: termoColetaSelected?.safra,
      dadosAgrotoxicos: termoColetaSelected?.dadosTratamentoSementes,
      especieId: termoColetaSelected?.especie?.id,
      dataColeta: moment(termoColetaSelected?.dataColeta),
      numeroTIF: termoColetaSelected?.termoFiscalizacao,
      municipioColetaId: termoColetaSelected?.municipioColeta?.id,
      observacoes: termoColetaSelected?.observacao,
      cpfCnpjOrigem: cpfCnpjMask(termoColetaSelected?.cpfCnpjRequerente || ""),
      nomeOrigem: termoColetaSelected?.nomeRequerente,
      caracteristicaOrigem: termoColetaSelected?.propriedade
        ? "PROPRIEDADE"
        : "ESTABELECIMENTO",
    });

    // eslint-disable-next-line
  }, []);

  if (!termoColetaSelected) {
    return (
      <>
        <Result
          status="error"
          title="Erro ao buscar Termo de Coleta"
          subTitle="Por favor tente novamente, se o erro persistir entre em contato com o suporte."
        />
      </>
    );
  }

  return (
    <Fragment>
      {contextHolder}
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Termo de Coleta de Amostras (Edição)
      </Typography.Title>
      <Divider />
      <Form
        form={formColeta}
        id="form-coleta-amostras"
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "200px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        onFinish={handleSubmitForm}
        onFinishFailed={messageSubmitFailed}
      >
        <Amostrador formColeta={formColeta} />

        <Divider />
        <OrigemTCA
          formColeta={formColeta}
          assinaturaFiscalizado={assinaturaFiscalizado}
          setAssinaturaFiscalizado={setAssinaturaFiscalizado}
          termoEdit={termoColetaSelected}
        />

        <Divider />
        <DadosGeraisTCA
          formColeta={formColeta}
          setCultivares={setCultivaresOptions}
          setIsOpenViewAmostras={setIsOpenViewAmostras}
          formDadosAmostra={formDadosAmostra}
          termoEdit={termoColetaSelected}
        />
      </Form>

      {isOpenViewAmostras && (
        <Fragment>
          <AmostrasTermo
            formDadosAmostra={formDadosAmostra}
            tipoAmostra={formColeta.getFieldValue("tipoAmostra")}
            cultivares={cultivaresOptions}
          />

          <AssinaturasTermo
            assinaturaFiscalizado={assinaturaFiscalizado}
            assinaturaAmostrador={assinaturaAmostrador}
            setAssinaturaFiscalizado={setAssinaturaFiscalizado}
            setAssinaturaAmostrador={setAssinaturaAmostrador}
            statusAssinatura={statusAssinaturaColeta}
            setStatusAssinatura={setStatusAssinaturaColeta}
            tipoColeta={formColeta.getFieldValue("tipoAmostra")}
            formRegistro={formColeta}
            refAmostrador={refAmostrador}
          />
          <ButtonFooterContainer>
            <Button onClick={handleCancelEdit} disabled={isSavingTermo}>
              Cancelar
            </Button>
            <Button
              disabled={isSavingTermo}
              htmlType="submit"
              type="primary"
              form="form-coleta-amostras"
            >
              Salvar
            </Button>
          </ButtonFooterContainer>
        </Fragment>
      )}
    </Fragment>
  );
}
