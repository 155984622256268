import { readerBlobToFileReader } from "@functions/readerBlobToFileReader";
import { IItemTermoApreensao } from "@interfaces/IItemTermoApreensao";
import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import { apiService } from "@services/api";
import { filtroTermoApreensaoAction } from "@store/actions/filtroTermoApreensaoAction";
import { itensTermoApreensaoLiberacaoAction } from "@store/actions/itensTermoApreensaoLiberacaoAction";
import { termoApreensaoLiberacaoAction } from "@store/actions/termoApreensaoLiberacaoAction";
import { getFiltroTermoApreensao } from "@store/feature/filtroTermoApreensaoSlice";
import { getItensTAL } from "@store/feature/itensTALSlice";
import {
  getPersistedAtTermoApreensaoLiberacao,
  getTermosApreensaoLiberacao,
} from "@store/feature/termoApreensaoLiberacaoSlice";
import { useDispatch, useSelector } from "react-redux";

export const useTAL = () => {
  const appDispatch = useDispatch();
  const listaTermos = useSelector(getTermosApreensaoLiberacao);
  const listaFiltroTermos = useSelector(getFiltroTermoApreensao);
  const termoApreensaoLiberacaoPersistedAtItems = useSelector(
    getPersistedAtTermoApreensaoLiberacao
  );
  const listaItens = useSelector(getItensTAL);
  const { addItem, removeItem, removeAllItens, updateItem } =
    itensTermoApreensaoLiberacaoAction;
  const {
    addTermo,
    addTermoByFilter,
    removeTermo,
    updateTermo,
    updateAssinatura,
    addTimePersistedAtItems,
    removeTimePersistedAtItems,
  } = termoApreensaoLiberacaoAction;
  const { addFiltroTermoApreensao, removeFiltroTermoApreensao } =
    filtroTermoApreensaoAction;

  const getTermoApreensao = async (numTermo: string) => {
    if (navigator.onLine) {
      const params = new URLSearchParams();
      params.append("numero", numTermo);

      const termoApreensaoContent =
        await apiService.termoApreensaoLiberacao.listar(params.toString());

      if (termoApreensaoContent.content.length === 0) {
        return {
          termo: null,
          message:
            "Por favor verifique se o número do termo está preenchido corretamente.",
        };
      }

      const termoApreensaoOnline =
        await apiService.termoApreensaoLiberacao.byId(
          termoApreensaoContent.content[0].id
        );

      if (termoApreensaoOnline.tipoTermo === "LIBERACAO") {
        return {
          termo: null,
          message:
            "O Número do Termo preenchido corresponde a um Termo de Liberação.",
        };
      }

      if (
        termoApreensaoOnline &&
        termoApreensaoOnline.servidores.length === 0
      ) {
        return {
          termo: null,
          message:
            "Termo de Apreensão informado possivelmente foi criado no antigo Sisdev-mobile",
        };
      }

      return {
        termo: termoApreensaoOnline,
        message: "Termo de Apreensão encontrado com sucesso!",
      };
    } else {
      const termoSelected = listaTermos.find(
        (termo) => termo.numero === numTermo
      );

      if (!termoSelected) {
        return {
          termo: null,
          message:
            "Por favor verifique se o número do termo está preenchido corretamente.",
        };
      }

      if (termoSelected?.tipoTermo === "LIBERACAO") {
        return {
          termo: null,
          message:
            "O Número do Termo preenchido corresponde a um Termo de Liberação.",
        };
      }

      return {
        termo: termoSelected,
        message: "Termo de Apreensão encontrado com sucesso!",
      };
    }
  };

  const getAnexo = async (
    dadosAnexos: {
      nomeUpload: string;
      nomeArquivo: string;
    }[]
  ) => {
    let dataUrls: Blob[] = [];
    let response: any[] = [];

    await Promise.allSettled(
      dadosAnexos.map((anexo) =>
        apiService.termoApreensaoLiberacao.getAnexo(anexo.nomeUpload)
      )
    ).then((data) => {
      data.forEach((infoAnexo) => {
        if (infoAnexo.status === "fulfilled") {
          dataUrls.push(infoAnexo.value.data);
        }
      });
    });

    await Promise.allSettled(
      dataUrls.map((data) => readerBlobToFileReader(data))
    ).then((res) => {
      res.forEach((infoAnexo, ind) => {
        if (infoAnexo.status === "fulfilled") {
          // console.log(infoAnexo.value.result);
          response.push({
            ...dadosAnexos[ind],
            arquivo: infoAnexo.value.result,
          });
        }
      });
    });

    return response;
  };

  const adicionarPersistedAtTermoApreensaoLiberacao = (time: number) => {
    appDispatch(addTimePersistedAtItems(time));
  };

  const removerPersistedAtTermoApreensaoLiberacao = () => {
    appDispatch(removeTimePersistedAtItems());
  };

  const adicionarItem = (item: IItemTermoApreensao) => {
    appDispatch(addItem(item));
  };

  const removerItem = (itemId: number) => {
    appDispatch(removeItem(itemId));
  };

  const removerTodosItems = () => {
    appDispatch(removeAllItens());
  };

  const atualizarItem = (item: IItemTermoApreensao) => {
    appDispatch(updateItem(item));
  };

  const adicionarTermo = (termoJson: ITermoApreensaoLiberacao) => {
    appDispatch(addTermo(termoJson));
  };

  const removerTermo = (numTermo: string) => {
    appDispatch(removeTermo(numTermo));
  };

  const atualizarTermo = (termo: ITermoApreensaoLiberacao) => {
    appDispatch(updateTermo(termo));
  };

  const adicionarTermoByFiltro = (termo: ITermoApreensaoLiberacao) => {
    appDispatch(addTermoByFilter(termo));
  };

  const atualizarAssinatura = (
    dados: any,
    termoId: number,
    termo: ITermoApreensaoLiberacao
  ) => {
    appDispatch(updateAssinatura(dados, termoId, termo));
  };

  const aplicarFiltro = (content: any) => {
    appDispatch(addFiltroTermoApreensao(content));
  };

  const removerFiltro = () => {
    appDispatch(removeFiltroTermoApreensao());
  };

  return {
    getTermoApreensao,
    getAnexo,
    termoApreensaoLiberacaoPersistedAtItems,
    adicionarPersistedAtTermoApreensaoLiberacao,
    removerPersistedAtTermoApreensaoLiberacao,
    listaItens,
    adicionarItem,
    removerItem,
    removerTodosItems,
    atualizarItem,
    adicionarTermo,
    removerTermo,
    atualizarTermo,
    adicionarTermoByFiltro,
    atualizarAssinatura,
    aplicarFiltro,
    removerFiltro,
    listaFiltroTermos,
    listaTermos,
  };
};
