import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ModalInformarNumeroTermo } from "@components/ModalInformarNumeroTermo";
import { openNotification } from "@components/Notifications";
import { TabelaServidoresForm } from "@components/TabelaServidoresForm";
import { UploadFilesForm } from "@components/UploadFilesForm";
import { INITIAL_VALUES_ASSINATURA } from "@constants/INITIAL_VALUES_ASSINATURA";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { formatMessageError } from "@functions/formatMessageError";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { useForms } from "@hooks/useForms";
import { useMunicipio } from "@hooks/useMunicipio";
import { useServidores } from "@hooks/useServidores";
import { IFormValuesTID } from "@interfaces/IFormValuesTID";
import { IServidorForm } from "@interfaces/IServidorForm";
import { ITID } from "@interfaces/ITid";
import { ITif } from "@interfaces/ITif";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Typography,
  UploadFile,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import fs from "file-saver";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AssinaturaTID } from "./components/AssinaturaTID";
import { AtividadeTID } from "./components/AtividadeTID";
import { PreviaTID } from "./components/PreviaTID";
import { RegistroFiscalizadoTID } from "./components/RegistroFiscalizadoTID";
import { SelectTipoTermoTID } from "./components/SelectTipoTermoTID";
import { formatToFormData } from "./functions/formatToFormData";
import { useTID } from "./hooks/useTID";

export function TID() {
  const { state } = useLocation();
  const [form] = Form.useForm<IFormValuesTID>();
  const [formServidores] = Form.useForm();
  const { removerTodosServidoresSelecionados } = useServidores();

  const { getMunicipioPorId } = useMunicipio();
  const [modal, contextHolder] = Modal.useModal();
  const { messageSubmitFailed, gerarNumeroTermo } = useForms();
  const {
    adicionarTermo,
    adicionarTermoSubmited,
    buscarTermoInterdicao,
    resetarCamposFiscalizado,
  } = useTID();
  const [isOpenModalInformarNumTermo, setIsOpenModalInformarNumTermo] =
    useState(false);
  const [isTidAfterTif, setIsTidAfterTif] = useState(false);
  const [isInterdicao, setIsInterdicao] = useState(true);
  const [isSavingTid, setIsSavingTid] = useState(false);
  const [numTermo, setNumTermo] = useState("");
  const [tifSelected, setTifSelected] = useState<Partial<ITif> | null>(null);
  const [anexo, setAnexo] = useState<UploadFile[]>([]);
  const [motivoNaoAssinatura, setMotivoNaoAssinatura] = useState<
    CheckboxValueType[]
  >([]);

  const [servidores, setServidores] = useState<IServidorForm[]>([]);
  const [fiscalizadoSignature, setFiscalizadoSignature] = useState(
    INITIAL_VALUES_ASSINATURA
  );

  //ENVIAR FORMULARIO
  const handleSubmit = async (formValues: IFormValuesTID) => {
    const keyMessage = "tid";
    const termoInterdicaoSelected = await buscarTermoInterdicao(
      formValues.numTermoInterdicao
    );

    const termoInterdicaoSelectedSubmit = termoInterdicaoSelected
      ? {
          dataFiscalizacao: termoInterdicaoSelected.dataFiscalizacao,
          id: termoInterdicaoSelected.id || 0,
          municipioFiscalizacao: termoInterdicaoSelected.municipioFiscalizacao,
          nomeFiscalizado: termoInterdicaoSelected.nomeFiscalizado,
          numero: termoInterdicaoSelected.numero,
          tipoTermo: termoInterdicaoSelected.tipoTermo,
          statusAssinaturaFiscalizado:
            termoInterdicaoSelected.statusAssinaturaFiscalizado,
        }
      : null;

    const dadosAssinante = Object.entries(fiscalizadoSignature).filter(
      (item) => item[0] !== "assinatura"
    );

    if (!tifSelected) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar TID.",
        description: "Adicione um TIF válido",
      });
      return;
    }

    if (
      formValues.tipoTermo === "DESINTERDICAO" &&
      !termoInterdicaoSelectedSubmit
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar TID.",
        description: "Adicione um Termo de Interdição válido",
      });
      return;
    }

    if (
      dadosAssinante.some((item) => !item[1]) &&
      (motivoNaoAssinatura.length === 0 ||
        motivoNaoAssinatura[0] === "ASSINADO_FISICAMENTE")
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar TID.",
        description:
          "Preencha os dados do assinante Fiscalizado para salvar o TID",
      });
      return;
    }

    if (servidores.length === 0) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar TID.",
        description: "Adicione ao menos um servidor.",
      });
      return;
    }

    if (!isSignaturesFilled()) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar TID.",
        description: "Preencha as assinaturas pendentes",
      });
      return;
    }

    const servidorSelected = servidores.map((serv) => {
      return {
        assinatura:
          getStatusAssinatura(motivoNaoAssinatura) === "ASSINADO_FISICAMENTE"
            ? ""
            : serv.assinatura,
        servidor: {
          cpfCnpj: serv.servidor.cpfCnpj,
          id: serv.servidor.id,
          inscricaoEstadual: serv.servidor.inscricaoEstadual,
          matricula: serv.servidor.matricula,
          nome: serv.servidor.nome,
        },
      };
    });

    const atividadesSelected = tifSelected?.atividades
      ?.filter((atv) => formValues.atividadesId.includes(atv.id))
      .map((atv) => {
        return {
          areaAtividade: atv.areaAtividade,
          atividade: {
            id: atv.atividade.id,
            nome: atv.atividade.nome,
          },
          id: atv.id,
        };
      });

    // console.log(atividadesSelected);

    try {
      let tid: ITID = {
        dataCadastro: `${moment().format("YYYY-MM-DD[T]HH:mm:ss")}`,
        servidores: servidorSelected,
        anexos: [],
        assinaturaFiscalizado:
          motivoNaoAssinatura.length === 0
            ? fiscalizadoSignature?.assinatura
                ?.getTrimmedCanvas()
                .toDataURL() || ""
            : "",
        nomeAssinanteFiscalizado: fiscalizadoSignature.nomeAssinante,
        funcaoAssinanteFiscalizado: fiscalizadoSignature.funcaoAssinante,
        atividadesInterditadas: formValues.amparoLegal || "",
        cpfAssinanteFiscalizado: fiscalizadoSignature.cpfAssinante.replace(
          /[^\d]+/g,
          ""
        ),
        cepFiscalizado:
          form.getFieldValue("cepFiscalizado")?.replace(/[^\d]+/g, "") || "",
        emailFiscalizado: form.getFieldValue("emailFiscalizado") || "",
        cpfCnpjFiscalizado:
          form.getFieldValue("cpfCnpjFiscalizado")?.replace(/[^\d]+/g, "") ||
          "",
        nomeFiscalizado: form.getFieldValue("nomeFiscalizado") || "",
        inscricaoEstadualFiscalizado: form.getFieldValue("rgFiscalizado") || "",
        renasemFiscalizado: form.getFieldValue("renasemFiscalizado") || "",
        enderecoFiscalizado: form.getFieldValue("enderecoFiscalizado") || "",
        numero:
          numTermo ||
          gerarNumeroTermo(form.getFieldValue("municipioFiscalizacaoId") || ""),
        tipoTermo: formValues.tipoTermo,
        tif: tifSelected,
        municipioFiscalizacao: getMunicipioPorId(
          form.getFieldValue("municipioFiscalizacaoId") || ""
        ),
        municipioFiscalizado: getMunicipioPorId(
          form.getFieldValue("municipioFiscalizadoId") || ""
        ),
        tipoRepresentante:
          form.getFieldValue("tipoRepresentante") !== "TRANSPORTADOR"
            ? form.getFieldValue("tipoRepresentante")
            : "",
        telefoneFiscalizado:
          form.getFieldValue("telefoneFiscalizado")?.replace(/[^\d]+/g, "") ||
          "",
        statusAssinaturaFiscalizado: getStatusAssinatura(motivoNaoAssinatura),
        termoInterdicao: termoInterdicaoSelectedSubmit,
        dataFiscalizacao: moment(form.getFieldValue("dataTIF")).format(
          "YYYY-MM-DD"
        ),
        atividades: atividadesSelected || [],
      };

      // console.log(tid);

      let anexosSubmitToTermo: (File | undefined)[] = [];

      if (anexo.length > 0) {
        await getAnexosFormatadosBase64(anexo).then((res) => {
          tid = { ...tid, anexos: res };

          const aux = res.map((anexo) => {
            return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
          });

          anexosSubmitToTermo = aux.filter((anexo) => anexo !== undefined);
        });
      }

      //CASO TENHA VINDO DO TIF
      if (state) {
        tid = { ...tid, tipoTermo: state?.tipo };
      }

      const formData = formatToFormData(tid, anexosSubmitToTermo);
      const tipoTermoFormatado =
        tid.tipoTermo === "INTERDICAO" ? "Interdição" : "Desinterdição";

      if (navigator.onLine) {
        setIsSavingTid(true);
        message.loading({
          key: keyMessage,
          duration: 0,
          content: "Salvando Termo...",
        });

        await apiService.tid.enviar(formData).then((data) => {
          adicionarTermoSubmited({ ...tid, id: data.id });
          message.destroy(keyMessage);
          setIsSavingTid(false);
          openNotification({
            type: "success",
            message: `Termo de ${tipoTermoFormatado} criado com sucesso!`,
          });

          handleResetForm();
        });
      } else {
        adicionarTermo(tid);
        // MODAL DE CONFIRMAÇÃO SE O USUARIO QUISER BAIXAR UM BACKUP

        modal.info({
          title: `Gostaria de baixar um backup do Termo de ${tipoTermoFormatado}?`,
          icon: <ExclamationCircleOutlined />,
          okText: "Sim",
          onOk: () => {
            let blob = new Blob([JSON.stringify(tid)], {
              type: "text/plain;charset=utf-8",
            });
            fs.saveAs(
              blob,
              `Termo de ${tipoTermoFormatado} ${tid.numero}.txt`,
              {
                autoBom: true,
              }
            );
            openNotification({
              type: "success",
              message: `Termo de ${tipoTermoFormatado} criado com sucesso!`,
              description: `Salvamento do Termo de ${tipoTermoFormatado} pendente, para quando aplicativo houver conexão com a internet.`,
            });

            handleResetForm();
          },
        });
      }
    } catch (error) {
      console.log(error);
      message.destroy(keyMessage);
      setIsSavingTid(false);
      const messageError = formatMessageError(error);

      openNotification({
        type: "error",
        message: "Não foi possivel salvar TID",
        description: messageError,
      });
    }
  };

  const isSignaturesFilled = () => {
    if (motivoNaoAssinatura[0] === "ASSINADO_FISICAMENTE") {
      return true;
    }

    if (
      fiscalizadoSignature?.assinatura?.toData().length === 0 &&
      motivoNaoAssinatura.length === 0
    ) {
      return false;
    }

    const isSignatureServEmpty = servidores.some(
      (serv) => serv.assinatura.length === 0
    );

    if (isSignatureServEmpty) {
      return false;
    }

    return true;
  };

  // RESETAR DADOS DO TERMO
  const handleResetForm = () => {
    form.resetFields();
    form.setFieldValue("tipoTermo", "INTERDICAO");
    setFiscalizadoSignature(INITIAL_VALUES_ASSINATURA);

    setTifSelected(null);
    setIsInterdicao(true);
    setAnexo([]);
    setMotivoNaoAssinatura([]);
    removerTodosServidoresSelecionados();
  };

  const modalConfirm = () => {
    modal.confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        handleResetForm();
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  useEffect(() => {
    document.title = "Novo TID | SISDEV-mobile";
    if (state) {
      setIsTidAfterTif(true);
      setIsInterdicao(state.tipo === "INTERDICAO");
      form.setFieldValue("tipoTermo", state.tipo);
      window.scroll(0, 0);
    } else {
      setIsTidAfterTif(false);
    }

    removerTodosServidoresSelecionados();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {contextHolder}
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Termo de{" "}
        {isTidAfterTif
          ? `${state.tipo === "INTERDICAO" ? "Interdição" : "Desinterdição"}`
          : "Interdição/Desinterdição"}
      </Typography.Title>
      <Divider />

      <Form
        initialValues={{ dataTIF: moment(), tipoTermo: "INTERDICAO" }}
        id="tid"
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "200px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={messageSubmitFailed}
      >
        {!isTidAfterTif && (
          <SelectTipoTermoTID
            form={form}
            setIsInterdicao={setIsInterdicao}
            resetFields={() => {
              setTifSelected(null);
              form.resetFields();
            }}
          />
        )}

        {!isInterdicao && (
          <Form.Item
            label="N° Termo de Interdição"
            name="numTermoInterdicao"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Digite o N° do Termo de Interdição"
              onChange={(e) => {
                setTifSelected(null);
                resetarCamposFiscalizado(form);
              }}
            />
          </Form.Item>
        )}

        <RegistroFiscalizadoTID
          form={form}
          setFiscalizadoSignature={setFiscalizadoSignature}
          setTifSelected={setTifSelected}
        />
        <Divider />

        <UploadFilesForm setAnexos={setAnexo} anexos={anexo} />

        <AtividadeTID
          isInterdicao={isInterdicao}
          isTifSelected={Boolean(tifSelected)}
          atividadeOptions={tifSelected?.atividades}
        />
      </Form>

      <Divider />

      <TabelaServidoresForm form={formServidores} />

      <AssinaturaTID
        ativarModalInformarNumTermo={() => setIsOpenModalInformarNumTermo(true)}
        form={form}
        servidores={servidores}
        setServidores={setServidores}
        fiscalizado={fiscalizadoSignature}
        setFiscalizado={setFiscalizadoSignature}
        motivoNaoAssinatura={motivoNaoAssinatura}
        setMotivoNaoAssinatura={setMotivoNaoAssinatura}
      />

      <ButtonFooterContainer>
        <Button form="tid" onClick={modalConfirm} disabled={isSavingTid}>
          Cancelar
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          form="tid"
          disabled={isSavingTid}
        >
          Salvar
        </Button>

        <PreviaTID
          anexos={anexo}
          fiscalizadoSignature={fiscalizadoSignature}
          form={form}
          tifSelected={tifSelected}
        />
      </ButtonFooterContainer>

      <ModalInformarNumeroTermo
        isOpen={isOpenModalInformarNumTermo}
        onClose={() => setIsOpenModalInformarNumTermo(false)}
        numTermo={numTermo}
        setNumTermo={setNumTermo}
      />
    </Fragment>
  );
}
