import { IArmadilha } from "@interfaces/IArmadilha";
import { IListagemArmadilha } from "@interfaces/IListagemArmadilha";
import { ISubmitArmadilha } from "@interfaces/ISubmitArmadilha";
import { api } from "@services/api";

interface ParamsGetArmadilha {
  codigo?: string;
  nomeMunicipio?: string;
  page?: number;
}

export const httpArmadilha = {
  getArmadilhas: async (params: ParamsGetArmadilha) => {
    const { data } = await api.get<IListagemArmadilha>(`/armadilhas`, {
      params: {
        ...params,
        page: params.page || 0,
        size: 20,
      },
    });
    return data;
  },

  byMunicipio: async (idMunicipio: number) => {
    const { data } = await api.get<IArmadilha[]>(
      `/armadilhas/municipio/${idMunicipio}`
    );
    return data;
  },

  byId: async (id: number) => {
    const { data } = await api.get<IArmadilha>(`/armadilhas/${id}`);
    return data;
  },

  enviar: async (armadilha: ISubmitArmadilha) => {
    const { data } = await api.post<IArmadilha>("/armadilhas", armadilha);
    return data;
  },

  inativar: async (
    id: number,
    { motivo, dataRemocao }: { motivo: string; dataRemocao: string }
  ) => {
    await api.patch(`/armadilhas/${id}`, {
      motivoRemocao: motivo,
      dataRemocao,
    });
    return;
  },
};
