import { readerBlobToFileReader } from "@functions/readerBlobToFileReader";
import { IFormValuesTID } from "@interfaces/IFormValuesTID";
import { IDadosAssinaturaFiscalizadoTID, ITID } from "@interfaces/ITid";
import { apiService } from "@services/api";
import { filtroTidAction } from "@store/actions/filtroTidAction";
import { tidAction } from "@store/actions/tidAction";
import { getFiltroTid } from "@store/feature/filtroTidSlice";
import { getTIDs } from "@store/feature/tidSlice";
import { FormInstance } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

export const useTID = () => {
  const appDispatch = useDispatch();
  const listaFiltroTID = useSelector(getFiltroTid);
  const listaTIDs = useSelector(getTIDs);
  const { addFiltro, removeFiltro } = filtroTidAction;
  const {
    addTermo,
    addTermoCompleto,
    removeTermo,
    updateTermo,
    updateAssinatura,
  } = tidAction;

  const resetarCamposFiscalizado = (form: FormInstance<IFormValuesTID>) => {
    form.resetFields([
      "nomeFiscalizado",
      "cpfCnpjFiscalizado",
      "ufFiscalizadoId",
      "municipioFiscalizadoId",
      "municipioFiscalizacaoId",
      "enderecoFiscalizado",
      "emailFiscalizado",
      "telefoneFiscalizado",
      "rgFiscalizado",
      "cepFiscalizado",
      "renasemFiscalizado",
      "nomePropriedade",
      "enderecoPropriedade",
      "tipoRepresentante",
      "localizacao",
    ]);

    form.setFieldValue("dataTIF", moment());
  };

  const buscarTermoInterdicao = async (numTermo: string | undefined) => {
    if (!numTermo) return;

    if (navigator.onLine) {
      const params = new URLSearchParams();
      params.append("numero", numTermo);

      const listaTermos = await apiService.tid.listar(params.toString());

      if (!listaTermos.content[0]) return;

      const data = await apiService.tid.byId(listaTermos.content[0].id);

      if (data.servidores.length === 0 || data.tipoTermo === "DESINTERDICAO")
        return;

      return data;
    } else {
      const tid = listaTIDs.find(
        (termo) => termo.numero === numTermo && termo.tipoTermo === "INTERDICAO"
      );

      return tid;
    }
  };

  const getAnexo = async (
    dadosAnexos: {
      nomeUpload: string;
      nomeArquivo: string;
    }[]
  ) => {
    let dataUrls: Blob[] = [];
    let response: any[] = [];

    await Promise.allSettled(
      dadosAnexos.map((anexo) => apiService.tid.getAnexo(anexo.nomeUpload))
    ).then((data) => {
      data.forEach((infoAnexo) => {
        if (infoAnexo.status === "fulfilled") {
          dataUrls.push(infoAnexo.value);
        }
      });
    });

    await Promise.allSettled(
      dataUrls.map((data) => readerBlobToFileReader(data))
    ).then((res) => {
      res.forEach((infoAnexo, ind) => {
        if (infoAnexo.status === "fulfilled") {
          // console.log(infoAnexo.value.result);
          response.push({
            ...dadosAnexos[ind],
            arquivo: infoAnexo.value.result,
          });
        }
      });
    });

    return response;
  };

  const adicionarTermo = (tidJson: ITID) => {
    appDispatch(addTermo(tidJson));
  };

  const adicionarTermoSubmited = (termo: ITID) => {
    appDispatch(addTermoCompleto(termo));
  };

  const removerTermo = (numTermo: string) => {
    appDispatch(removeTermo(numTermo));
  };

  const atualizarTermo = (termo: ITID) => {
    appDispatch(updateTermo(termo));
  };

  const aplicarFiltro = (content: any) => {
    appDispatch(addFiltro(content));
  };

  const removerFiltro = () => {
    appDispatch(removeFiltro());
  };

  const atualizarAssinatura = (
    dadosAssinatura: IDadosAssinaturaFiscalizadoTID,
    termoId: number,
    tid: ITID
  ) => {
    appDispatch(updateAssinatura(dadosAssinatura, termoId, tid));
  };

  return {
    adicionarTermo,
    removerTermo,
    atualizarTermo,
    adicionarTermoSubmited,
    listaTIDs,
    listaFiltroTID,
    removerFiltro,
    aplicarFiltro,
    atualizarAssinatura,
    resetarCamposFiscalizado,
    buscarTermoInterdicao,
    getAnexo,
  };
};
