import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { DatePicker, Form, FormInstance, Input } from "antd";

export function Campo({
  pergunta,
  perguntasForm,
}: {
  pergunta: IPerguntaAtividade;
  perguntasForm: FormInstance;
}) {
  return (
    <>
      {pergunta.pergunta.campos.map((campo, ind) => {
        return (
          <div
            key={ind}
            style={{
              display: "flex",
              gap: 5,
              flexDirection: "column",
            }}
          >
            <label>{campo.nome}</label>
            {campo.formatoResposta === "DATE" && (
              <Form.Item noStyle={false}>
                <DatePicker
                  format={"DD/MM/YYYY"}
                  style={{ maxWidth: 400 }}
                  disabled
                  value={perguntasForm.getFieldValue(
                    `listaPerguntas.${pergunta.pergunta.id}.campos.${ind}.resposta`
                  )}
                />
              </Form.Item>
            )}

            {(campo.formatoResposta === "TEXTO" ||
              campo.formatoResposta === "NUMBER") && (
              <Form.Item noStyle={false}>
                <Input
                  style={{ maxWidth: 400 }}
                  value={perguntasForm.getFieldValue(
                    `listaPerguntas.${pergunta.pergunta.id}.campos.${ind}.resposta`
                  )}
                />
              </Form.Item>
            )}
          </div>
        );
      })}
    </>
  );
}
