import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { formatMessageError } from "@functions/formatMessageError";
import { useArmadilha } from "@hooks/useArmadilha";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { useMunicipio } from "@hooks/useMunicipio";
import { usePropriedade } from "@hooks/usePropriedade";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Grid,
  message,
  Progress,
  Row,
  Select,
  Typography,
} from "antd";
import moment from "moment";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import { HeaderCard } from "./components/HeaderCard";
import { SyncAutomatico } from "./components/SyncAutomatico";
import { SyncDadosCadastroArmadilha } from "./components/SyncDadosCadastroArmadilha";
import { SyncDadosTAL } from "./components/SyncDadosTAL";
import { SyncDadosTCA } from "./components/SyncDadosTCA";
import { SyncDadosTIF } from "./components/SyncDadosTif";
import { SyncDadosTV } from "./components/SyncDadosTV";
import styles from "./style.module.css";

moment.locale("pt-br");
const LIMITE_DOWNLOAD_MUNICIPIOS = 3;

interface IFormSync {
  municipioId: number;
}

export function OfflineMode() {
  const {
    municipiosMt,
    adicionarMunicipiosSincronizados,
    timeExpireMunicipioSincronizados,
    municipioSincronizados,
  } = useMunicipio();
  const { listaPropriedadeSincronizadas, sincronizarPropriedades } =
    usePropriedade();
  const { listaEstabelecimentoSincronizados, sincronizarEstabelecimentos } =
    useEstabelecimento();
  const { sincronizarArmadilhas, listaArmadilhasSincronizadas } =
    useArmadilha();
  const screens = Grid.useBreakpoint();

  const [form] = Form.useForm<IFormSync>();
  const [isDownloading, setIsDownloading] = useState(false);
  const [loadingUpdateMunicipio, setLoadingUpdateMunicipio] = useState({
    loading: false,
    municipioId: 0,
  });
  const [memorySpace, setMemorySpace] = useState(0);

  const handleSubmit = async ({ municipioId }: IFormSync) => {
    const dateTimeCreate = Date.now();
    const errors: string[] = [];

    if (!navigator.onLine) {
      openNotification({
        type: "error",
        message: "Não foi possivel sincronizar dados do municipio",
        description:
          "Sem accesso a internet para baixar dados. Tente novamente mais tarde.",
      });
      return;
    }

    if (
      listaPropriedadeSincronizadas.some(
        (prop) => prop.municipioId === municipioId
      )
    ) {
      openNotification({
        type: "error",
        message: "Não foi possivel sincronizar dados do municipio",
        description: "Municipio já sincronizado.",
      });

      return;
    }

    if (listaPropriedadeSincronizadas.length >= LIMITE_DOWNLOAD_MUNICIPIOS) {
      openNotification({
        type: "error",
        message: "Não foi possivel sincronizar dados do municipio",
        description: "Limite de municipios excedido.",
      });

      return;
    }

    //COLOCANDO MUNICIPIO NA LISTA DE MUNICIPIOS BAIXADOS
    const municipiosBaixados = [
      ...municipioSincronizados,
      municipiosMt.filter((municipio) => municipio.id === municipioId)[0],
    ];
    adicionarMunicipiosSincronizados(municipiosBaixados, dateTimeCreate);

    //SINCRONIZANDO ARMADILHAS DE CADA MUNICIPIO
    const paramArmadilhas = new URLSearchParams();
    paramArmadilhas.append("codigoIbge", municipioId.toString());

    setIsDownloading(true);
    message.loading({
      key: "loading",
      content: "Sincronizando dados do municipio...",
    });

    await Promise.allSettled([
      apiService.propriedades.byMunicipio(municipioId),
      apiService.estabelecimentos.byMunicipio(municipioId),
      apiService.armadilha.byMunicipio(municipioId),
    ]).then((response) => {
      const [propriedade, estabelecimento, armadilha] = response;

      message.destroy("downloadMunicipio");
      setIsDownloading(false);
      form.resetFields();

      if (propriedade.status === "rejected") {
        const messageError = formatMessageError(propriedade.reason);
        openNotification({
          type: "error",
          message: "Não foi possivel sincronizar propriedades",
          description: messageError,
        });
        return;
      }

      if (estabelecimento.status === "rejected") {
        const messageError = formatMessageError(estabelecimento.reason);
        openNotification({
          type: "error",
          message: "Não foi possivel sincronizar Estabelecimentos",
          description: messageError,
        });
        return;
      }

      if (armadilha.status === "rejected") {
        const messageError = formatMessageError(armadilha.reason);
        openNotification({
          type: "error",
          message: "Não foi possivel sincronizar Armadilhas",
          description: messageError,
        });
        return;
      }

      sincronizarPropriedades(
        {
          municipioId,
          propriedades: propriedade.value,
        },
        dateTimeCreate
      );

      sincronizarEstabelecimentos(
        {
          municipioId,
          estabelecimentos: estabelecimento.value,
        },
        dateTimeCreate
      );

      sincronizarArmadilhas(armadilha.value, municipioId, dateTimeCreate);

      openNotification({
        type: "success",
        message: `Sincronização feita com sucesso!`,
        description: `Dados do municipio de ${
          municipiosMt.find((municipio) => municipio.id === municipioId)?.nome
        } agora podem ser utilizados no modo offline`,
      });
    });
  };

  useEffect(() => {
    document.title = "Sincronização de Dados | SISDEV-mobile";

    navigator.storage
      .estimate()
      .then((estimate) => {
        if (!estimate.usage || !estimate.quota) return;
        setMemorySpace(
          parseFloat(((estimate.usage / estimate.quota) * 100).toFixed(2)) || 0
        );
      })
      .catch((error) => {
        console.log(error);
        const messageError = formatMessageError(error);
        openNotification({
          type: "error",
          message: "Não foi possivel calcular o uso de memória",
          description: messageError,
        });
      });
  }, []);

  return (
    <>
      <Typography.Title
        level={screens.lg ? 2 : 3}
        style={{ textAlign: "center" }}
      >
        Sincronizar Dados
      </Typography.Title>

      <Divider />

      <div style={{ paddingBottom: 20 }}>
        <Typography style={{ paddingBottom: "5px", opacity: 0.7 }}>
          Após 3 dias desde a ultima atualização os dados sincronizados serão
          apagados
        </Typography>
      </div>

      <div>
        <SyncDadosTIF />
        <SyncDadosTCA />
        <SyncDadosTAL />
        <SyncDadosTV />
        <SyncDadosCadastroArmadilha />

        <Form
          initialValues={{ idUf: 51 }}
          onFinish={handleSubmit}
          form={form}
          style={{ paddingBottom: 20 }}
          layout="vertical"
        >
          <Divider>
            <Typography.Title level={screens.lg ? 4 : 5}>
              Municípios Sincronizados {municipioSincronizados.length}/
              {LIMITE_DOWNLOAD_MUNICIPIOS}
            </Typography.Title>
          </Divider>

          <Row gutter={screens.lg ? 16 : 24} align="middle" justify="center">
            <Col span={screens.lg ? 12 : 24}>
              <Form.Item
                label="Município"
                name="municipioId"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input) ||
                    (option!.children as unknown as string).includes(
                      input.toUpperCase()
                    )
                  }
                  placeholder="Selecione um Municípo para sincronizar os dados"
                  style={{ width: "100%", minWidth: 200 }}
                  disabled={isDownloading}
                >
                  {municipiosMt.map((municipio) => (
                    <Select.Option
                      key={municipio.id}
                      value={municipio.id}
                    >{`${municipio.nome}-${municipio.uf.sigla}`}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <ButtonFooterContainer>
            <Button type="primary" htmlType="submit" disabled={isDownloading}>
              Baixar
            </Button>
          </ButtonFooterContainer>
        </Form>

        {municipioSincronizados.length === 0 && (
          <Empty
            description="Nenhum municipio sincronizado"
            style={{ margin: "20px 0px" }}
          />
        )}

        {municipioSincronizados.length > 0 && (
          <>
            <Typography
              style={{ opacity: 0.7, paddingBottom: 20, fontSize: 13 }}
            >
              <strong>Atualizado em:</strong>{" "}
              {moment(timeExpireMunicipioSincronizados).format("llll")}
            </Typography>
            <div className={styles["container-card"]}>
              {municipioSincronizados.map((municipio, ind) => {
                const [propriedadesMunicipio] =
                  listaPropriedadeSincronizadas.filter(
                    (prop) => prop.municipioId === municipio.id
                  );

                const [estabelecimentosMunicipio] =
                  listaEstabelecimentoSincronizados.filter(
                    (estab) => estab.municipioId === municipio.id
                  );

                const armadilasMunicipio = listaArmadilhasSincronizadas.find(
                  (arm) => arm.municipioId === municipio.id
                );

                return (
                  <Card
                    key={ind}
                    title={
                      <HeaderCard
                        municipio={municipio}
                        isDownloading={isDownloading}
                        loadingUpdateMunicipio={loadingUpdateMunicipio}
                        setLoadingUpdateMunicipio={setLoadingUpdateMunicipio}
                      />
                    }
                    style={{
                      width: 500,
                      boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                    }}
                    bordered
                  >
                    <p>
                      propriedades...
                      {propriedadesMunicipio === undefined ||
                      (loadingUpdateMunicipio.loading &&
                        loadingUpdateMunicipio.municipioId === municipio.id) ? (
                        <LoadingOutlined />
                      ) : propriedadesMunicipio.error ? (
                        <CloseOutlined />
                      ) : (
                        <CheckOutlined />
                      )}
                    </p>

                    <p>
                      Estabelecimentos...
                      {estabelecimentosMunicipio === undefined ||
                      (loadingUpdateMunicipio.loading &&
                        loadingUpdateMunicipio.municipioId === municipio.id) ? (
                        <LoadingOutlined />
                      ) : estabelecimentosMunicipio.error ? (
                        <CloseOutlined />
                      ) : (
                        <CheckOutlined />
                      )}
                    </p>

                    <p>
                      Armadilhas...
                      {!armadilasMunicipio ||
                      (loadingUpdateMunicipio.loading &&
                        loadingUpdateMunicipio.municipioId === municipio.id) ? (
                        <LoadingOutlined />
                      ) : (
                        <CheckOutlined />
                      )}
                    </p>
                  </Card>
                );
              })}
            </div>
          </>
        )}

        <Divider>
          <Typography.Title level={screens.lg ? 4 : 5}>
            Dados sincronizados automaticamente
          </Typography.Title>
        </Divider>

        <SyncAutomatico />

        <Divider>
          <Typography.Title level={screens.lg ? 4 : 5}>
            Uso do espaço em disco
          </Typography.Title>
        </Divider>
        <div className={styles["container-space-memory-used"]}>
          <Progress
            type="circle"
            percent={memorySpace}
            size="default"
            status="normal"
          />
        </div>
      </div>
    </>
  );
}
