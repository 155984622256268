import { LoadingOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { checkDevice } from "@functions/checkDevice";
import { useArmadilha } from "@hooks/useArmadilha";
import { IFormValuesFiltroArmadilha } from "@interfaces/IFormValuesFiltroArmadilha";
import { ISubmitArmadilha } from "@interfaces/ISubmitArmadilha";
import { apiService } from "@services/api";
import {
  Checkbox,
  Divider,
  Form,
  List,
  Pagination,
  Row,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { FiltroArmadilha } from "./components/FiltroArmadilha";
import { ItemListaArmadilha } from "./components/ItemListaArmadilha";
import { ItemListaToSyncArmadilha } from "./components/ItemListaToSyncArmadilha";
import styles from "./style.module.css";

const PAGE_SIZE = 20;
export function ListagemArmadilha() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [formFiltro] = Form.useForm<IFormValuesFiltroArmadilha>();
  const [pageControl, setPageControl] = useState(1);
  const [listaFiltroAtivo, setListaFiltroAtivo] = useState<string[]>([]);
  const { listaArmadilhasToSync } = useArmadilha();

  const { data: listaArmadilhas, isLoading } = useQuery({
    queryKey: [
      "LISTAGEM_ARMADILHAS",
      pageControl,
      searchParams.get("nomeMunicipio"),
      searchParams.get("codigoArmadilha"),
    ],
    queryFn: async () => {
      const nomeMunicipio = searchParams.get("nomeMunicipio");
      const codigoArmadilha = searchParams.get("codigoArmadilha");

      const result = await apiService.armadilha.getArmadilhas({
        page: pageControl - 1,
        nomeMunicipio:
          nomeMunicipio !== "undefined" && nomeMunicipio
            ? nomeMunicipio
            : undefined,
        codigo:
          codigoArmadilha !== "undefined" && codigoArmadilha
            ? codigoArmadilha
            : undefined,
      });

      return result;
    },
    enabled: navigator.onLine,
  });

  const handleChangePage = (page: number) => {
    setPageControl(page);
  };

  const handleChangeFiltroAtivo = (values: CheckboxValueType[]) => {
    if (!values.find((value) => value === "nomeMunicipio")) {
      searchParams.delete("nomeMunicipio");
      setListaFiltroAtivo((filtros) =>
        filtros.filter((filtro) => filtro !== "nomeMunicipio")
      );
    }

    if (!values.find((value) => value === "codigoArmadilha")) {
      searchParams.delete("codigoArmadilha");
      setListaFiltroAtivo((filtros) =>
        filtros.filter((filtro) => filtro !== "codigoArmadilha")
      );
    }

    if (values.length === 0) {
      openNotification({
        type: "success",
        message: "Filtro removido com sucesso!",
      });
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const itens: string[] = [];

    if (searchParams.get("nomeMunicipio") !== null) {
      itens.push("nomeMunicipio");
    }

    if (searchParams.get("codigoArmadilha") !== null) {
      itens.push("codigoArmadilha");
    }
    setListaFiltroAtivo(itens);
  }, [searchParams]);

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Lista de Armadilha(s)
      </Typography.Title>

      <Row>
        <FiltroArmadilha
          formFiltro={formFiltro}
          setPageControl={setPageControl}
        />
      </Row>

      <Divider />

      {listaFiltroAtivo.length > 0 && (
        <div className={styles["container-filtro"]}>
          <Typography>Filtro aplicado:</Typography>

          <Checkbox.Group
            value={listaFiltroAtivo}
            onChange={handleChangeFiltroAtivo}
          >
            {searchParams.get("nomeMunicipio") && (
              <Checkbox value="nomeMunicipio">Municipio</Checkbox>
            )}
            {searchParams.get("codigoArmadilha") && (
              <Checkbox value="codigoArmadilha">Código</Checkbox>
            )}
          </Checkbox.Group>
        </div>
      )}

      {listaArmadilhasToSync.length > 0 && (
        <>
          <Typography.Title level={4} style={{ textAlign: "center" }}>
            Armadilha(s) para sincronizar
          </Typography.Title>
          <List
            dataSource={listaArmadilhasToSync}
            style={{
              boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
            }}
            bordered
            renderItem={(item) => {
              return (
                <ItemListaToSyncArmadilha
                  key={item.offlineId}
                  item={item as ISubmitArmadilha}
                  offlineId={item.offlineId}
                />
              );
            }}
          />

          <Divider />
        </>
      )}

      <List
        dataSource={listaArmadilhas?.content}
        style={{
          boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
        }}
        bordered
        renderItem={(item) => {
          return <ItemListaArmadilha key={item.id} item={item} />;
        }}
        loading={{
          indicator: <LoadingOutlined spin={isLoading} />,
          spinning: isLoading,
        }}
      />

      <Pagination
        size={checkDevice() ? "small" : "default"}
        showSizeChanger={false}
        current={pageControl}
        style={{
          paddingTop: 20,
          display: "flex",
          justifyContent: "end",
        }}
        pageSize={PAGE_SIZE}
        total={Number(listaArmadilhas?.totalPages || "") * PAGE_SIZE}
        onChange={handleChangePage}
      />
    </>
  );
}
