import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { useArmadilha } from "@hooks/useArmadilha";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { useMunicipio } from "@hooks/useMunicipio";
import { usePropriedade } from "@hooks/usePropriedade";
import { IMunicipio } from "@interfaces/IMunicipio";
import { apiService } from "@services/api";
import { Button, message, Tooltip, Typography } from "antd";
import confirm from "antd/lib/modal/confirm";
import styles from "./style.module.css";

interface Props {
  municipio: IMunicipio;
  isDownloading: boolean;
  loadingUpdateMunicipio: { loading: boolean; municipioId: number };
  setLoadingUpdateMunicipio: React.Dispatch<
    React.SetStateAction<{
      loading: boolean;
      municipioId: number;
    }>
  >;
}

export function HeaderCard({
  municipio,
  isDownloading,
  loadingUpdateMunicipio,
  setLoadingUpdateMunicipio,
}: Props) {
  const {
    municipioSincronizados,
    adicionarMunicipiosSincronizados,
    removerMunicipioSincronizado,
  } = useMunicipio();
  const { removerPropriedades, atualizarPropriedades } = usePropriedade();
  const { removerEstabelecimentos, atualizarEstabelecimentos } =
    useEstabelecimento();
  const { removerSyncArmadilhas } = useArmadilha();

  const modalDeleteConfirm = (municipio: IMunicipio) => {
    confirm({
      title: `Tem certeza que deseja remover dados do municipio ${formataNome(
        municipio.nome
      )}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        removerMunicipioSincronizado(municipio.id);
        removerPropriedades(municipio.id);
        removerEstabelecimentos(municipio.id);
        removerSyncArmadilhas(municipio.id);

        openNotification({
          type: "success",
          message: "Dados do Municipio removidos com sucesso!",
        });
      },
    });
  };

  const handleChangeUpdateMunicipio = async (municipio: IMunicipio) => {
    //Pegar propriedades / estabelecimentos /servidores na API
    const errors: string[] = [];
    const dateTimeUpdate = Date.now();

    if (!navigator.onLine) {
      openNotification({
        type: "error",
        message: "Erro ao atualizar dados",
        description:
          "Sem accesso a internet para atualizar dados. Por favor, tente novamente mais tarde.",
      });
      return;
    }

    setLoadingUpdateMunicipio({
      loading: true,
      municipioId: municipio.id,
    });

    message.open({
      key: "updateMunicipio",
      type: "loading",
      content: "Atualizando dados do municipio...",
      duration: 0,
    });

    await Promise.allSettled([
      apiService.propriedades.byMunicipio(municipio.id),
      apiService.estabelecimentos.byMunicipio(municipio.id),
    ]).then((res) => {
      const error: string[] = [];

      if (res[0].status === "rejected") {
        error.push("Propriedades");
      } else {
        atualizarPropriedades(
          {
            municipioId: municipio.id,
            propriedades: res[0].value,
          },
          dateTimeUpdate
        );
      }

      if (res[1].status === "rejected") {
        error.push("Estabelecimentos");
      } else {
        atualizarEstabelecimentos(
          {
            municipioId: municipio.id,
            estabelecimentos: res[1].value,
          },
          dateTimeUpdate
        );
      }

      if (error.length > 0) {
        const msgErro = errors.join(", ");

        openNotification({
          type: "error",
          message: "Erro ao atualizar dados do municipio",
          description: `Não foi possível atualizar as seguintes informações:\n${msgErro}.`,
        });
      } else {
        openNotification({
          type: "success",
          message: "Dados do Municipio atualizados com Sucesso!",
        });
        adicionarMunicipiosSincronizados(
          municipioSincronizados,
          dateTimeUpdate
        );
      }
    });

    setLoadingUpdateMunicipio({
      loading: false,
      municipioId: 0,
    });

    message.destroy("updateMunicipio");

    return;
  };

  const formataNome = (str: string) => {
    return str
      .toLowerCase()
      .replace(/(?:^|\s)(?!da|de|do|dos|das)\S/g, (l: any) => l.toUpperCase());
  };

  return (
    <div className={styles["container-title"]}>
      <Typography>{formataNome(municipio.nome)}</Typography>

      <div className={styles["container-buttons"]}>
        <Tooltip title="Atualizar">
          <Button
            disabled={isDownloading || loadingUpdateMunicipio.loading}
            onClick={() => handleChangeUpdateMunicipio(municipio)}
          >
            <SyncOutlined
              spin={
                loadingUpdateMunicipio.loading &&
                loadingUpdateMunicipio.municipioId === municipio.id
              }
            />
          </Button>
        </Tooltip>

        <Tooltip title="Excluir">
          <Button
            disabled={isDownloading || loadingUpdateMunicipio.loading}
            danger
            onClick={() => modalDeleteConfirm(municipio)}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
