import "antd/dist/antd.css";
import ErrorBoundary from "errorBoundary";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { oidcConfig } from "./services/authConfig";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ErrorBoundary>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </ErrorBoundary>
);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    window.location.reload(); // Atualiza automaticamente quando um novo SW for ativado
  });
}

serviceWorkerRegistration.register();

reportWebVitals();
