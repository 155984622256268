import { Layout } from "layout";
import { Armadilha } from "pages/armadilha";
import { ListagemArmadilha } from "pages/armadilha/listagemArmadilha";
import { ImpressaoTID } from "pages/tid/listagemTID/impressaoTID";
import { VisualizacaoTID } from "pages/tid/listagemTID/visualizacaoTID";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page404 } from "./pages/404";
import { TifConfig } from "./pages/config/tifConfig";
import { Home } from "./pages/home";
import { ListagemLasgal } from "./pages/listagemLasgal";
import { AnaliseTesteLasgal } from "./pages/listagemLasgal/AnaliseTesteLasgal";
import { NovoRecebimentoLasgal } from "./pages/listagemLasgal/NovoRecebimentoLasgal";
import { ViewImpressaoBoletimLasgal } from "./pages/listagemLasgal/ViewImpressaoBoletimLasgal";
import { ListagemPerfis } from "./pages/listagemPerfis";
import { GerenciarPerfil } from "./pages/listagemPerfis/components/GerenciarPerfil";
import { NovoPerfil } from "./pages/listagemPerfis/components/NovoPerfil";
import { LoginOffline } from "./pages/loginOffline";
import { OfflineMode } from "./pages/offlineMode";
import { TermoApreensaoLiberacao } from "./pages/termoApreensaoLiberacao";
import { ListagemTermoApreensaoLiberacao } from "./pages/termoApreensaoLiberacao/listagemTermoApreensaoLiberacao";
import { EdicaoAssinaturaTAL } from "./pages/termoApreensaoLiberacao/listagemTermoApreensaoLiberacao/edicaoAssinaturaTAL";
import { EdicaoTAL } from "./pages/termoApreensaoLiberacao/listagemTermoApreensaoLiberacao/edicaoTAL";
import { ImpressaoTAL } from "./pages/termoApreensaoLiberacao/listagemTermoApreensaoLiberacao/impressaoTAL";
import { VisualizacaoTAL } from "./pages/termoApreensaoLiberacao/listagemTermoApreensaoLiberacao/visualizacaoTAL";
import { TermoColeta } from "./pages/termoColeta";
import { EditTermoColeta } from "./pages/termoColeta/editTermoColeta";
import { ListagemTermoColeta } from "./pages/termoColeta/listagemTermoColeta";
import { EdicaoPosEnvioTermoColeta } from "./pages/termoColeta/listagemTermoColeta/EdicaoPosEnvioTermoColeta";
import { ViewTermoColeta } from "./pages/termoColeta/listagemTermoColeta/ViewTermoColeta";
import { TermoVistoria } from "./pages/termoVistoria";
import { ListagemTermoVistoria } from "./pages/termoVistoria/listagemTermoVistoria";
import { EdicaoTV } from "./pages/termoVistoria/listagemTermoVistoria/edicaoTV";

import { EdicaoAssinaturaTV } from "pages/termoVistoria/listagemTermoVistoria/edicaoAssinaturaTV";
import { ImpressaoTV } from "pages/termoVistoria/listagemTermoVistoria/ImpressaoTV";
import { VisualizacaoTV } from "./pages/termoVistoria/listagemTermoVistoria/visualizacaoTV";
import { TID } from "./pages/tid";
import { ListagemTID } from "./pages/tid/listagemTID";
import { EdicaoAssinaturaTID } from "./pages/tid/listagemTID/edicaoAssinaturaTID";
import { EdicaoTID } from "./pages/tid/listagemTID/edicaoTID";
import { FormTif } from "./pages/tif";
import { ListagemTIF } from "./pages/tif/listagemTIF";
import { EdicaoAtividadeAssinaturaTIF } from "./pages/tif/listagemTIF/edicaoAtividadeAssinaturaTIF";
import { EdicaoTIF } from "./pages/tif/listagemTIF/edicaoTIF";
import { ImpressaoTIF } from "./pages/tif/listagemTIF/impressaoTIF";
import { VisualizacaoTIF } from "./pages/tif/listagemTIF/visualizacaoTIF";

export function Routers() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginOffline />} />
        <Route
          path="/home"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />

        <Route
          path="/TIF/novo"
          element={
            <Layout>
              <FormTif />
            </Layout>
          }
        />
        <Route
          path="/offline-mode"
          element={
            <Layout>
              <OfflineMode />
            </Layout>
          }
        />
        <Route
          path="/TIF/listagem"
          element={
            <Layout>
              <ListagemTIF />
            </Layout>
          }
        />

        <Route
          path="/TIF/listagem/editar/:numTIF"
          element={
            <Layout>
              <EdicaoTIF />
            </Layout>
          }
        />
        <Route
          path="/TIF/listagem/editar-atividades-assinatura/:termoId"
          element={
            <Layout>
              <EdicaoAtividadeAssinaturaTIF />
            </Layout>
          }
        />

        <Route
          path="/TIF/listagem/visualizar/:termoId"
          element={
            <Layout>
              <VisualizacaoTIF />
            </Layout>
          }
        />

        <Route
          path="/TIF/listagem/imprimir/:termoIdentificador"
          element={
            <Layout>
              <ImpressaoTIF />
            </Layout>
          }
        />

        <Route
          path="/config/TIF"
          element={
            <Layout>
              <TifConfig />
            </Layout>
          }
        />

        <Route
          path="/termo-coleta/novo"
          element={
            <Layout>
              <TermoColeta />
            </Layout>
          }
        />

        <Route
          path="/termo-coleta/edit/:numTermo"
          element={
            <Layout>
              <EditTermoColeta />
            </Layout>
          }
        />
        <Route
          path="/termo-coleta/listagem"
          element={
            <Layout>
              <ListagemTermoColeta />
            </Layout>
          }
        />

        <Route
          path="/termo-coleta/edit/assinatura/:id"
          element={
            <Layout>
              <EdicaoPosEnvioTermoColeta />
            </Layout>
          }
        />
        <Route
          path="/termo-coleta/listagem/visualizar/:termoId"
          element={
            <Layout>
              <ViewTermoColeta />
            </Layout>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/novo"
          element={
            <Layout>
              <TermoApreensaoLiberacao />
            </Layout>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/listagem"
          element={
            <Layout>
              <ListagemTermoApreensaoLiberacao />
            </Layout>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/listagem/editar/:numTermo"
          element={
            <Layout>
              <EdicaoTAL />
            </Layout>
          }
        />
        <Route
          path="/termo-apreensao-liberacao/listagem/visualizar/:termoId"
          element={
            <Layout>
              <VisualizacaoTAL />
            </Layout>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/listagem/imprimir/:termoIdentificador"
          element={
            <Layout>
              <ImpressaoTAL />
            </Layout>
          }
        />

        <Route
          path="/termo-apreensao-liberacao/listagem/editar-assinatura/:termoId"
          element={
            <Layout>
              <EdicaoAssinaturaTAL />
            </Layout>
          }
        />

        <Route
          path="/termo-vistoria/novo"
          element={
            <Layout>
              <TermoVistoria />
            </Layout>
          }
        />
        <Route
          path="/termo-vistoria/listagem"
          element={
            <Layout>
              <ListagemTermoVistoria />
            </Layout>
          }
        />

        <Route
          path="/termo-vistoria/listagem/imprimir/:termoIdentificador"
          element={
            <Layout>
              <ImpressaoTV />
            </Layout>
          }
        />

        <Route
          path="/termo-vistoria/listagem/visualizar/:termoId"
          element={
            <Layout>
              <VisualizacaoTV />
            </Layout>
          }
        />

        <Route
          path="/termo-vistoria/listagem/editar/:numTermo"
          element={
            <Layout>
              <EdicaoTV />
            </Layout>
          }
        />

        <Route
          path="/termo-vistoria/listagem/editar-assinatura/:termoId"
          element={
            <Layout>
              <EdicaoAssinaturaTV />
            </Layout>
          }
        />

        <Route
          path="/LASGAL/novo/:amostraId"
          element={
            <Layout>
              <NovoRecebimentoLasgal />
            </Layout>
          }
        />

        <Route
          path="/LASGAL/teste/:amostraId"
          element={
            <Layout>
              <AnaliseTesteLasgal />
            </Layout>
          }
        />

        <Route
          path="/LASGAL/impressao/:amostraId"
          element={
            <Layout>
              <ViewImpressaoBoletimLasgal />
            </Layout>
          }
        />

        <Route
          path="/LASGAL/listagem"
          element={
            <Layout>
              <ListagemLasgal />
            </Layout>
          }
        />

        <Route
          path="/TID/novo"
          element={
            <Layout>
              <TID />
            </Layout>
          }
        />

        <Route
          path="/TID/listagem"
          element={
            <Layout>
              <ListagemTID />
            </Layout>
          }
        />

        <Route
          path="/TID/listagem/editar/:numTermo"
          element={
            <Layout>
              <EdicaoTID />
            </Layout>
          }
        />

        <Route
          path="/TID/listagem/editar-assinatura/:termoId"
          element={
            <Layout>
              <EdicaoAssinaturaTID />
            </Layout>
          }
        />

        <Route
          path="/TID/listagem/imprimir/:termoIdentificador"
          element={
            <Layout>
              <ImpressaoTID />
            </Layout>
          }
        />

        <Route
          path="/TID/listagem/visualizar/:termoId"
          element={
            <Layout>
              <VisualizacaoTID />
            </Layout>
          }
        />

        <Route
          path="/perfis"
          element={
            <Layout>
              <ListagemPerfis />
            </Layout>
          }
        />

        <Route
          path="/perfis/gerenciar/:idPerfil"
          element={
            <Layout>
              <GerenciarPerfil />
            </Layout>
          }
        />

        <Route
          path="/perfis/novo"
          element={
            <Layout>
              <NovoPerfil />
            </Layout>
          }
        />

        <Route
          path="/armadilha/novo"
          element={
            <Layout>
              <Armadilha />
            </Layout>
          }
        />

        <Route
          path="/armadilha/listagem"
          element={
            <Layout>
              <ListagemArmadilha />
            </Layout>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}
