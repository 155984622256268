import { ITID } from "@interfaces/ITid";
import { iconsLogo } from "../../../../../../icons";

export function Header({
  isFirstPage,
  tid,
}: {
  isFirstPage?: boolean;
  tid: ITID;
}) {
  return (
    <>
      <thead>
        <tr>
          <td
            style={{
              borderTop: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <div
              style={{
                display: "block",

                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  padding: "10px 10px 0px 10px",
                  gap: 150,
                }}
              >
                <div>
                  <img
                    src={iconsLogo[3].image}
                    alt={iconsLogo[3].name}
                    style={{
                      height: 70,
                    }}
                  />
                </div>

                <div>
                  <img
                    src={iconsLogo[2].image}
                    alt={iconsLogo[2].name}
                    style={{ height: 70 }}
                  />
                </div>
              </div>
            </div>

            {isFirstPage && (
              <div
                style={{
                  borderBottom: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0px 20px",
                      gap: 20,
                    }}
                  >
                    <div style={{ display: "flex", gap: 2 }}>
                      <input
                        type="checkbox"
                        readOnly
                        value={"INTERDIÇÃO"}
                        checked={tid.tipoTermo === "INTERDICAO"}
                      />
                      <label>INTERDIÇÃO</label>
                    </div>

                    <div style={{ display: "flex", gap: 2 }}>
                      <input
                        type="checkbox"
                        readOnly
                        value={"DESINTERDIÇÃO"}
                        checked={tid.tipoTermo === "DESINTERDICAO"}
                      />
                      <label>DESINTERDIÇÃO</label>
                    </div>
                  </div>
                </div>

                <div>N°: {tid.numero}</div>
              </div>
            )}
          </td>
        </tr>
      </thead>
    </>
  );
}
