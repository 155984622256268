import { IRespostaAtividade } from "@interfaces/IRespostaAtividade";
import { ITif } from "@interfaces/ITif";

export function migrarTermoAntigo(termoAntigo: any): ITif {
  const respostaAtualizada: IRespostaAtividade[] =
    termoAntigo.respostasAtividades.map((resposta: any) => {
      return {
        armadilha: resposta.armadilha,
        atividade: resposta.atividade,
        pergunta: resposta.pergunta,
        descricao: resposta.descricao,
        campos: resposta.campos,
        alternativas: resposta.alternativa
          ? [
              {
                alternativa: resposta.alternativa,
              },
            ]
          : [],
      };
    });

  return { ...termoAntigo, respostasAtividades: respostaAtualizada };
}
