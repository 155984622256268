import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { IPerguntaImpressaoTIF } from "@interfaces/IPerguntaImpressaoTIF";
import moment from "moment";

export function Campo({
  pergunta,
  atv,
}: {
  pergunta: IPerguntaAtividade;
  atv: IPerguntaImpressaoTIF;
}) {
  return (
    <>
      {pergunta.pergunta.campos.map((campo, ind) => {
        return (
          <div
            key={ind}
            style={{
              borderBottom: " 1px solid rgba(0,0,0,0.5)",
              fontSize: 12,
            }}
          >
            {campo.nome}:{" "}
            {campo.formatoResposta === "DATE" && (
              <span>
                {moment(
                  atv.respostas.find(
                    (resp) => resp.pergunta.id === pergunta.pergunta.id
                  )?.campos[ind].resposta
                ).format("DD/MM/YYYY")}
              </span>
            )}
            {(campo.formatoResposta === "NUMBER" ||
              campo.formatoResposta === "TEXTO") && (
              <span>
                {
                  atv.respostas.find(
                    (resp) => resp.pergunta.id === pergunta.pergunta.id
                  )?.campos[ind].resposta
                }
              </span>
            )}
          </div>
        );
      })}
    </>
  );
}
