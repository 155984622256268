import { masks } from "@functions/mascaras";
import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import styled from "styled-components";
import { Alternativa } from "./Alternativa";
import { Campo } from "./Campo";
import { Descritiva } from "./Descritiva";
import { MultiplaEscolha } from "./MultiplaEscolha";

const ContainerCSS = styled.div`
  height: 4vh;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
`;

const TitleCSS = styled.div`
  height: 50%;
  background-color: #d9d9d9;
  padding-left: 5px;
`;

export function Body({
  termo,
  perguntas,
}: {
  termo: ITermoVistoria;
  perguntas: IPerguntaTermoVistoria[];
}) {
  const { cpfCnpjMask, coordMask, telefoneMask } = masks;

  return (
    <>
      <tbody
        style={{
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
        }}
      >
        <tr>
          <td
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: 18,
                fontWeight: "bold",
                textAlign: "center",
                maxWidth: 500,
              }}
            >
              {termo.formulario?.nome}
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div style={{ padding: "5px 20px" }}>
              <div
                style={{
                  height: "4vh",
                  border: "1px solid black",
                }}
              >
                <TitleCSS>Razão Social:</TitleCSS>
                <div style={{ height: "50%", paddingLeft: 5 }}>
                  {termo.nomeFiscalizado}
                </div>
              </div>

              <ContainerCSS>
                <TitleCSS>CNPJ:</TitleCSS>
                <div style={{ height: "50%", paddingLeft: 5 }}>
                  {cpfCnpjMask(termo.cpfCnpjFiscalizado || "")}
                </div>
              </ContainerCSS>

              <ContainerCSS style={{ display: "flex" }}>
                <div style={{ width: "25%", borderRight: "1px solid black" }}>
                  <TitleCSS>CEP:</TitleCSS>
                  <div style={{ height: "50%", paddingLeft: 5 }}>
                    {termo.cepFiscalizado}
                  </div>
                </div>

                <div style={{ width: "50%", borderRight: "1px solid black" }}>
                  <TitleCSS>Municipio:</TitleCSS>
                  <div style={{ height: "50%", paddingLeft: 5 }}>
                    {termo.municipioFiscalizado?.nome}
                  </div>
                </div>

                <div style={{ width: "25%" }}>
                  <TitleCSS>UF:</TitleCSS>
                  <div style={{ height: "50%", paddingLeft: 5 }}>
                    {termo.municipioFiscalizado?.uf.sigla}
                  </div>
                </div>
              </ContainerCSS>

              <ContainerCSS style={{ display: "flex" }}>
                <div style={{ width: "50%", borderRight: "1px solid black" }}>
                  <TitleCSS>Telefone:</TitleCSS>
                  <div style={{ height: "50%", paddingLeft: 5 }}>
                    {telefoneMask(termo.telefoneFiscalizado || "")}
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <TitleCSS>Email:</TitleCSS>
                  <div style={{ height: "50%", paddingLeft: 5 }}>
                    {termo.emailFiscalizado}
                  </div>
                </div>
              </ContainerCSS>

              <ContainerCSS>
                <TitleCSS>Endereço:</TitleCSS>
                <div style={{ height: "50%", paddingLeft: 5 }}>
                  {termo.enderecoFiscalizado}
                </div>
              </ContainerCSS>

              <ContainerCSS>
                <TitleCSS>Coordenada Geográfica:</TitleCSS>
                <div style={{ height: "50%", paddingLeft: 5 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 15,
                      paddingLeft: 5,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 5,
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>S</span>
                      <span>
                        {`${coordMask(
                          termo.latGrauFiscalizado.toString() || ""
                        )}°${coordMask(
                          termo.latMinFiscalizado.toString() || ""
                        )}'${coordMask(
                          termo.latSegFiscalizado.toString() || ""
                        )}"`}
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: 5,
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>O</span>{" "}
                      <span>
                        {`${coordMask(
                          termo.longGrauFiscalizado.toString() || ""
                        )}°${coordMask(
                          termo.longMinFiscalizado.toString() || ""
                        )}'${coordMask(
                          termo.longSegFiscalizado.toString() || ""
                        )}"`}
                      </span>
                    </div>
                  </div>
                </div>
              </ContainerCSS>
            </div>
          </td>
        </tr>

        <tr>
          <td>
            <div
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                display: "flex",
                flexDirection: "column",
                minHeight: 300,
                fontSize: 12,
              }}
            >
              {perguntas.map((pergunta, ind) => {
                return (
                  <div key={ind}>
                    <p style={{ fontWeight: "bold", marginBottom: 5 }}>
                      {pergunta.indPergunta} - {pergunta.pergunta.nome}
                    </p>

                    {pergunta.pergunta.tipoResposta === "ALTERNATIVA" && (
                      <Alternativa pergunta={pergunta} termo={termo} />
                    )}

                    {pergunta.pergunta.tipoResposta ===
                      "ALTERNATIVA_DESCRITIVA" && (
                      <>
                        <Alternativa pergunta={pergunta} termo={termo} />
                        <Descritiva pergunta={pergunta} termo={termo} />
                      </>
                    )}

                    {pergunta.pergunta.tipoResposta === "DESCRITIVA" && (
                      <Descritiva pergunta={pergunta} termo={termo} />
                    )}

                    {pergunta.pergunta.tipoResposta === "MULTIPLA_ESCOLHA" && (
                      <MultiplaEscolha pergunta={pergunta} termo={termo} />
                    )}

                    {pergunta.pergunta.tipoResposta ===
                      "MULTIPLA_ESCOLHA_DESCRITIVA" && (
                      <>
                        <MultiplaEscolha pergunta={pergunta} termo={termo} />
                        <Descritiva pergunta={pergunta} termo={termo} />
                      </>
                    )}

                    <Campo pergunta={pergunta} termo={termo} />
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}
