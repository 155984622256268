import { IMaterialColetado } from "@interfaces/IMaterialColetado";
import { apiService } from "@services/api";
import { materialColetadoAction } from "@store/actions/materialColetadoAction";
import { getMaterialColetado } from "@store/feature/materialColetadoSlice";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

export const useMateriaisColetados = () => {
  const materiaisColetadosSync = useSelector(getMaterialColetado);
  const { addMaterialColetado, removeMaterialColetado: removeRedux } =
    materialColetadoAction;
  const appDispatch = useDispatch();

  const { data: listaMateriaisColetados } = useQuery({
    queryKey: ["MATERIAL_COLETADO"],
    initialData: materiaisColetadosSync,
    queryFn: () => apiService.materialColetado(),
    onSuccess: (data) => data,
  });

  const syncMateriaisColetados = (
    materiais: IMaterialColetado[],
    persistedAt: number
  ) => {
    appDispatch(addMaterialColetado(materiais, persistedAt));
  };

  const removeMateriaisColetados = () => {
    appDispatch(removeRedux());
  };

  return {
    listaMateriaisColetados,
    syncMateriaisColetados,
    removeMateriaisColetados,
    isMaterialSync: materiaisColetadosSync.length > 0,
  };
};
