import { getStatusAssinatura } from "@functions/statusAssinatura";
import { useEspecie } from "@hooks/useEspecie";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { useMunicipio } from "@hooks/useMunicipio";
import { usePropriedade } from "@hooks/usePropriedade";
import { useServidores } from "@hooks/useServidores";
import { IAmostra } from "@interfaces/IAmostra";
import { IAssinante } from "@interfaces/IAssinante";
import { IEspecie } from "@interfaces/IEspecie";
import { IFormValuesTermoColeta } from "@interfaces/IFormValuesTermoColeta";
import { IServidor } from "@interfaces/IServidor";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { apiService } from "@services/api";
import { amostraAction } from "@store/actions/amostraAction";
import { filtroTermoColetaAction } from "@store/actions/filtroTermoColetaAction";
import { termoColetaAction } from "@store/actions/termoColetaAction";
import { getAmostras } from "@store/feature/amostraSlice";
import { getFiltroTermoColeta } from "@store/feature/filtroTermoColetaSlice";
import {
  getPersistedAtTermoColeta,
  getTermosColeta,
} from "@store/feature/termoColetaSlice";
import { getTIFs } from "@store/feature/tifSlice";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

interface IGetFormSubmitProps {
  assinaturaFiscalizado: IAssinante;
  statusAssinaturaColeta: CheckboxValueType[];
  assinaturaAmostrador: string;
  values: IFormValuesTermoColeta;
  especieSelected: IEspecie | null;
  numTermoColeta: string;
  amostrador: IServidor | null;
}

export const useTCA = () => {
  const appDispatch = useDispatch();
  const { listaServidores } = useServidores();
  const { listaEspecies } = useEspecie({ isTCA: true });
  const { buscarPropriedadePeloCpfCnpj } = usePropriedade();
  const { buscarEstabelecimentoPeloCnpj } = useEstabelecimento();
  const { getMunicipioPorId } = useMunicipio();
  const listaTifs = useSelector(getTIFs);
  const listaTCA = useSelector(getTermosColeta);
  const termoColetaPersistedAtItems = useSelector(getPersistedAtTermoColeta);
  const listaAmostras = useSelector(getAmostras);
  const listaTermos = useSelector(getTermosColeta);
  const listaFiltroTermos = useSelector(getFiltroTermoColeta);
  const { addAmostra, removeAmostra, removeAllAmostras } = amostraAction;
  const {
    addTermo,
    removeTermo,
    updateTermo,
    addTermoByFilter,
    addTimePersistedAtItems,
    removeTimePersistedAtItems,
  } = termoColetaAction;
  const { addFiltroTermoColeta, removeFiltroTermoColeta } =
    filtroTermoColetaAction;

  const getAmostrador = (matricula: string) => {
    const auxServidor = listaServidores.filter(
      (serv) => serv.matricula === matricula
    );
    if (auxServidor.length === 0) return null;

    return auxServidor[0];
  };

  const getEspecieSelected = (idEspecie?: number | "") => {
    if (!idEspecie) return null;

    return listaEspecies?.find((especie) => especie.id === idEspecie) || null;
  };

  const getTermoExistente = async (numTIF?: string) => {
    if (!numTIF) return;
    const tif = listaTifs.filter((tif) => tif.numero === numTIF);

    if (tif.length > 0) {
      return true;
    }

    if (navigator.onLine) {
      const params = new URLSearchParams();
      params.append("numero", numTIF);

      const data = await apiService.tif.listar(params.toString());

      return data.content.length > 0 ? true : false;
    } else {
      return false;
    }
  };

  const getFormSubmit = async ({
    assinaturaFiscalizado,
    statusAssinaturaColeta,
    assinaturaAmostrador,
    values,
    especieSelected,
    numTermoColeta,
    amostrador,
  }: IGetFormSubmitProps) => {
    let formValues: ITermoColetaAmostras = {
      cpfAssinante: assinaturaFiscalizado.cpfAssinante.replace(/[^\d]+/g, ""),
      funcaoAssinante: assinaturaFiscalizado.funcaoAssinante,
      nomeAssinante: assinaturaFiscalizado.nomeAssinante,
      assinaturaFiscalizado:
        statusAssinaturaColeta.length === 0
          ? assinaturaFiscalizado?.assinatura?.getTrimmedCanvas().toDataURL() ||
            ""
          : "",
      assinaturaAmostrador: assinaturaAmostrador,
      statusAssinatura: getStatusAssinatura(statusAssinaturaColeta),
      dispensadoColetaDuplicata: values.declaracoes
        ? values.declaracoes.some(
            (declaracao: string) => declaracao === "dispensado"
          )
        : false,
      recebidoColetaDuplicata: values.declaracoes
        ? values.declaracoes.some(
            (declaracao: string) => declaracao === "recebido"
          )
        : false,
      propriedade: null,
      produtor: null,
      municipioColeta: getMunicipioPorId(values.municipioColetaId || undefined),
      destino: values.destino,
      nomeLaboratorio: values.nomeLaboratorio || "",
      termoFiscalizacao: values.numeroTIF || "",
      dataColeta: moment(values.dataColeta)
        .locale("pt-br")
        .format()
        .slice(0, 10),
      especie: especieSelected,
      safra: values.safra || "",
      tipoColeta: values.tipoAmostra,
      renasemOrigemSementes: values.renasemOrigem || "",
      paisOrigem: values.paisOrigem || "",
      dadosTratamentoSementes: values.dadosAgrotoxicos || "",
      observacao: values.observacoes || "",
      termoColetaAmostra: numTermoColeta || "",
      itens: listaAmostras,
      dataImpressao: moment().locale("pt-br").format("YYYY-MM-DD[T]HH:mm:ss"),
      nomeArquivo: `Termo ${numTermoColeta}`,
      cpfCnpjRequerente: values.cpfCnpjOrigem.replace(/[^\d]+/g, ""),
      nomeRequerente: values.nomeOrigem,
      enderecoRequerente: "",
      requerente: null,
      creaAmostrador: "",
      nomeAmostrador: amostrador ? amostrador.pessoa.nome : "",
      amostrador: amostrador,
      municipio: null,
      nomeUpload: `Termo ${numTermoColeta}`,
      codigo: 0,
      dar: "",
      renasemMatriculaAmostrador: values.matriculaAmostrador || "",
      situacao: "ANALISADA",
      tipo: "FISCALIZAÇÃO",
      plantaVoluntaria: false,
    };

    if (values.propriedadeId && !values.estabelecimentoId) {
      const dadosProdutor = await buscarPropriedadePeloCpfCnpj(
        values.cpfCnpjOrigem.replace(/[^\d]+/g, "")
      );
      const propriedade = dadosProdutor.find(
        (prop) => prop.id === values.propriedadeId
      );

      formValues = {
        ...formValues,
        propriedade: propriedade || null,
        produtor: propriedade?.produtores[0],
        municipio: propriedade?.municipio || null,
      };
    } else {
      const estabelecimento = await buscarEstabelecimentoPeloCnpj(
        values.cpfCnpjOrigem.replace(/[^\d]+/g, "")
      );

      formValues = {
        ...formValues,
        produtor: estabelecimento[0],
        municipio: estabelecimento[0].endereco.municipio,
      };
    }

    return formValues;
  };

  const buscarTermoColeta = async (numTermo: string) => {
    if (navigator.onLine) {
      const params = new URLSearchParams();
      params.append("termoColetaAmostra", numTermo);
      const listaTermos = await apiService.termoColeta.listar(
        params.toString()
      );

      if (listaTermos.content.length > 0) {
        const termo = await apiService.termoColeta.byId(
          listaTermos.content[0].id
        );

        return termo;
      }
    } else {
      const termo = listaTCA.find((tca) => tca.termoColetaAmostra === numTermo);

      return termo;
    }
  };

  const adicionarPersistedAtTermoColeta = (time: number) => {
    appDispatch(addTimePersistedAtItems(time));
  };

  const removerPersistedAtTermoColeta = () => {
    appDispatch(removeTimePersistedAtItems());
  };

  const adicionarAmostra = (amostra: IAmostra) => {
    appDispatch(addAmostra(amostra));
  };

  const removerAmostra = (amostraId: number) => {
    appDispatch(removeAmostra(amostraId));
  };

  const removerTodasAmostras = () => {
    appDispatch(removeAllAmostras());
  };

  const adicionarTermoColeta = (termo: ITermoColetaAmostras) => {
    appDispatch(addTermo(termo));
  };

  const removerTermoColeta = (numTermo: string) => {
    appDispatch(removeTermo(numTermo));
  };

  const atualizarTermoColeta = (termo: ITermoColetaAmostras) => {
    appDispatch(updateTermo(termo));
  };

  const adicionarTermoColetaByFiltro = (termo: ITermoColetaAmostras) => {
    appDispatch(addTermoByFilter(termo));
  };

  const aplicarFiltro = (content: any) => {
    appDispatch(addFiltroTermoColeta(content));
  };

  const removerFiltro = () => {
    appDispatch(removeFiltroTermoColeta());
  };

  return {
    getAmostrador,
    getEspecieSelected,
    getTermoExistente,
    getFormSubmit,
    buscarTermoColeta,
    termoColetaPersistedAtItems,
    adicionarPersistedAtTermoColeta,
    removerPersistedAtTermoColeta,
    listaAmostras,
    adicionarAmostra,
    removerAmostra,
    removerTodasAmostras,
    adicionarTermoColeta,
    removerTermoColeta,
    atualizarTermoColeta,
    adicionarTermoColetaByFiltro,
    aplicarFiltro,
    removerFiltro,
    listaFiltroTermos,
    listaTermos,
  };
};
