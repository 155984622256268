import { masks } from "@functions/mascaras";
import { ITID } from "@interfaces/ITid";
import moment from "moment";
import styled from "styled-components";
import { ContainerAssinaturas } from "../../../../../tif/listagemTIF/impressaoTIF/components/Footer";

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  height: 2vh;
  align-items: center;
  display: flex;
`;

export function Footer({ tid }: { tid: ITID }) {
  const { cpfCnpjMask } = masks;

  return (
    <tfoot>
      <tr>
        <td>
          <div
            style={{
              borderRight: "1px solid black",
              borderBottom: "1px solid black",
              borderLeft: "1px solid black",
            }}
          >
            <Container>
              <div
                style={{
                  width: "60%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Municipio: {tid.municipioFiscalizacao?.nome}
              </div>

              <div
                style={{
                  width: "20%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Data: {moment(tid?.dataCadastro).format("DD/MM/YYYY")}
              </div>

              <div style={{ width: "20%", paddingLeft: 5 }}>
                Hora: {moment(tid?.dataCadastro).format("HH:mm")}
              </div>
            </Container>

            <Container>
              <div
                style={{
                  width: "60%",
                  borderRight: "1px solid black",
                  paddingLeft: 5,
                }}
              >
                Número TIF: {tid.tif?.numero}
              </div>

              <div style={{ width: "40%", paddingLeft: 5 }}>
                Data Termo:{" "}
                {moment(tid.tif?.dataFiscalizacao).format("DD/MM/YYYY")}
              </div>
            </Container>

            <ContainerAssinaturas style={{ gap: "20px" }}>
              {tid.servidores.map((serv, ind) => {
                return (
                  <div
                    key={ind}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingBottom: 10,
                      paddingTop: 5,
                      maxWidth: tid.servidores.length > 1 ? "15vh" : "none",
                      width: tid.servidores.length > 3 ? "12vh" : "auto",
                      alignItems: "center",
                      fontSize: tid.servidores.length > 3 ? 10 : 12,
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width={tid.servidores.length > 3 ? "3cm" : "4cm"}
                      height={tid.servidores.length > 3 ? "1.5cm" : "2.5cm"}
                      viewBox="0 0 645 600"
                      enableBackground="new 0 0 645 600"
                      xmlSpace="preserve"
                      style={{ borderBottom: "1px solid black" }}
                    >
                      <image
                        id="image0"
                        width="17cm"
                        height="15.875cm"
                        x="0"
                        y="0"
                        href={serv.assinatura}
                      />
                    </svg>

                    <span style={{ textAlign: "center" }}>
                      Fiscal/Agente Fiscal
                    </span>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                      }}
                    >
                      <span>Nome:{serv.servidor.nome}</span>

                      <span>Matricula:{serv.servidor.matricula}</span>
                    </div>
                  </div>
                );
              })}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: 10,
                    paddingTop: 5,
                    maxWidth: tid.servidores.length > 1 ? "15vh" : "none",
                    width: tid.servidores.length > 3 ? "12vh" : "auto",
                    alignItems: "center",
                    fontSize: tid.servidores.length > 3 ? 10 : 12,
                  }}
                >
                  {tid.statusAssinaturaFiscalizado ===
                    "ASSINADO_DIGITALMENTE" && (
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width={tid.servidores.length > 3 ? "3cm" : "4cm"}
                      height={tid.servidores.length > 3 ? "1.5cm" : "2.5cm"}
                      viewBox="0 0 645 600"
                      enableBackground="new 0 0 645 600"
                      xmlSpace="preserve"
                      style={{ borderBottom: "1px solid black" }}
                    >
                      <image
                        id="image0"
                        width="17cm"
                        height="15.875cm"
                        x="0"
                        y="0"
                        href={tid.assinaturaFiscalizado}
                      />
                    </svg>
                  )}

                  {(tid.statusAssinaturaFiscalizado === "AUSENTE" ||
                    tid.statusAssinaturaFiscalizado === "RECUSADO") && (
                    <p
                      style={{
                        borderBottom: "1px solid black",
                        width: tid.servidores.length > 3 ? "3cm" : "4cm",
                        height: tid.servidores.length > 3 ? "1.5cm" : "2.5cm",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                        marginBottom: 0,
                      }}
                    >
                      {tid.statusAssinaturaFiscalizado}
                    </p>
                  )}

                  {tid.statusAssinaturaFiscalizado ===
                    "ASSINADO_FISICAMENTE" && (
                    <div
                      style={{
                        borderBottom: "1px solid black",
                        width: tid.servidores.length > 3 ? "3cm" : "4cm",
                        height: tid.servidores.length > 3 ? "1.5cm" : "2.5cm",
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    />
                  )}

                  <span>Inspecionado/Fiscalizado</span>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                    }}
                  >
                    <span>Nome: {tid.nomeAssinanteFiscalizado}</span>
                    <span>CPF: {cpfCnpjMask(tid.cpfAssinanteFiscalizado)}</span>
                    <span>Função: {tid.funcaoAssinanteFiscalizado}</span>
                  </div>
                </div>
              </div>
            </ContainerAssinaturas>
          </div>
        </td>
      </tr>
    </tfoot>
  );
}
