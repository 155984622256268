import { IPropriedade } from "@interfaces/IPropriedade";
import { apiService } from "@services/api";
import { propriedadeAction } from "@store/actions/propriedadeAction";
import { getPropriedades } from "@store/feature/propriedadeSlice";
import { useDispatch, useSelector } from "react-redux";

type DadosPropriedade = {
  municipioId: number;
  propriedades: IPropriedade[];
};

export const usePropriedade = () => {
  const listaPropriedadeSincronizadas = useSelector(getPropriedades);
  const appDispatch = useDispatch();
  const { removePropriedades, addPropriedades, updatePropriedades } =
    propriedadeAction;

  const sincronizarPropriedades = (
    dados: DadosPropriedade,
    createdAt: number
  ) => {
    appDispatch(addPropriedades(dados, createdAt));
  };

  const atualizarPropriedades = (
    dados: DadosPropriedade,
    updatedAt: number
  ) => {
    appDispatch(updatePropriedades(dados, updatedAt));
  };

  const removerPropriedades = (municipioId: number) => {
    appDispatch(removePropriedades(municipioId));
  };

  const buscarPropriedadePeloCpfCnpj = async (cpfCnpj: string) => {
    if (navigator.onLine) {
      const result = await apiService.propriedades.byCpfCnpj(cpfCnpj);

      const propriedadesOnline: IPropriedade[] = result.map((propriedade) => ({
        ...propriedade,
        produtores: propriedade.produtores.filter(
          (produtor) => produtor.cpf === cpfCnpj
        ),
      }));

      return propriedadesOnline;
    } else {
      const propriedadesOffline: IPropriedade[] = [];
      listaPropriedadeSincronizadas.forEach((item) => {
        item.propriedades.forEach((prop) => {
          const produtor = prop.produtores.find((prod) => prod.cpf === cpfCnpj);

          if (produtor) {
            propriedadesOffline.push({
              ...prop,
              produtores: [produtor],
            });
          }
        });
      });

      return propriedadesOffline;
    }
  };

  return {
    listaPropriedadeSincronizadas,
    removerPropriedades,
    atualizarPropriedades,
    sincronizarPropriedades,
    buscarPropriedadePeloCpfCnpj,
  };
};
