import { LoadingOutlined } from "@ant-design/icons";
import { ModalAssinatura } from "@components/ModalAssinatura";
import { openNotification } from "@components/Notifications";
import { formatMessageError } from "@functions/formatMessageError";
import { masks } from "@functions/mascaras";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { IAssinante } from "@interfaces/IAssinante";
import { IDadosAssinaturaTermoVistoria } from "@interfaces/ITermoVistoria";
import { apiService } from "@services/api";
import { getTermosVistoria } from "@store/feature/termoVistoriaSlice";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  Result,
  Spin,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { Fragment, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import { useTV } from "../../hooks/useTV";

export function EdicaoAssinaturaTV() {
  const { termoId } = useParams();
  const navigate = useNavigate();
  const refFiscalizado = useRef(null);
  const { atualizarAssinaturaTermo, atualizarTermo } = useTV();

  const { cpfCnpjMask } = masks;
  const [assinaturaFisica, setAssinaturaFisica] = useState<CheckboxValueType[]>(
    []
  );
  const [motivoNaoAssinatura, setMotivoNaoAssinatura] = useState<
    CheckboxValueType[]
  >([]);
  const [dadosFiscalizado, setDadosFiscalizado] = useState<IAssinante>({
    assinatura: null,
    cpfAssinante: "",
    funcaoAssinante: "",
    nomeAssinante: "",
  });
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data: termoVistoria, status } = useQuery({
    queryKey: ["EDITAR_POS_ENVIO_TERMO_VISTORIA"],
    queryFn: () => apiService.termoVistoria.byId(parseInt(termoId || "")),
    initialData: useSelector(getTermosVistoria).filter(
      (termo) => termo.id === parseInt(termoId || "")
    )[0],
  });

  const handleBack = () => {
    navigate("/termo-vistoria/listagem");
  };

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  const handleCloseModal = async () => {
    setIsOpenModal(false);
    setDadosFiscalizado({
      ...dadosFiscalizado,
      assinatura: refFiscalizado.current,
    });
  };

  const cleanSignatureFields = (
    signature: ReactSignatureCanvas | null | undefined
  ) => {
    if (signature !== null && signature !== undefined) {
      signature.clear();
    }
  };

  const handleChangeMotivoNaoAssinaturaFiscalizado = (
    checkedValue: CheckboxValueType[]
  ) => {
    if (checkedValue.length > 0) {
      setAssinaturaFisica([]);
    }

    if (checkedValue[0] === "AUSENTE") {
      setDadosFiscalizado({
        assinatura: null,
        nomeAssinante: "",
        cpfAssinante: "",
        funcaoAssinante: "",
      });
    }

    cleanSignatureFields(refFiscalizado.current);
    setMotivoNaoAssinatura(checkedValue);
  };

  const handleSubmit = async () => {
    if (!termoVistoria || !termoVistoria.id) return;
    let dadosAtualizados: IDadosAssinaturaTermoVistoria = {
      nomeAssinanteFiscalizado: termoVistoria.nomeAssinanteFiscalizado,
      cpfAssinanteFiscalizado: termoVistoria.cpfAssinanteFiscalizado,
      funcaoAssinanteFiscalizado: termoVistoria.funcaoAssinanteFiscalizado,
      statusAssinaturaFiscalizado: termoVistoria.statusAssinaturaFiscalizado,
      statusAssinaturaServidor: termoVistoria.statusAssinaturaServidor,
      assinaturaFiscalizado: termoVistoria.assinaturaFiscalizado,
    };

    if (termoVistoria.statusAssinaturaFiscalizado !== "AUSENTE") return;
    if (
      (!dadosFiscalizado.nomeAssinante ||
        !dadosFiscalizado.cpfAssinante ||
        !dadosFiscalizado.funcaoAssinante) &&
      motivoNaoAssinatura.length === 0
    ) {
      openNotification({
        type: "error",
        message: "Erro ao salvar Termo de Vistoria.",
        description:
          "Preencha os dados do assinante Fiscalizado para salvar o Termo de Vistoria",
      });

      return;
    }

    if (
      dadosFiscalizado?.assinatura?.toData().length === 0 &&
      motivoNaoAssinatura.length === 0
    ) {
      openNotification({
        type: "error",
        message: "Erro ao salvar Termo de Vistoria.",
        description: "Preencha as assinaturas pendentes",
      });
      return;
    }

    dadosAtualizados = {
      ...dadosAtualizados,
      nomeAssinanteFiscalizado: dadosFiscalizado.nomeAssinante,
      cpfAssinanteFiscalizado: dadosFiscalizado.cpfAssinante,
      funcaoAssinanteFiscalizado: dadosFiscalizado.funcaoAssinante,
      statusAssinaturaFiscalizado:
        assinaturaFisica.length > 0
          ? getStatusAssinatura(assinaturaFisica)
          : getStatusAssinatura(motivoNaoAssinatura),
      statusAssinaturaServidor:
        assinaturaFisica.length > 0
          ? getStatusAssinatura(assinaturaFisica)
          : getStatusAssinatura(motivoNaoAssinatura),
      assinaturaFiscalizado:
        motivoNaoAssinatura.length === 0
          ? dadosFiscalizado?.assinatura?.getTrimmedCanvas().toDataURL() || ""
          : "",
    };

    if (navigator.onLine) {
      await apiService.termoVistoria
        .update(dadosAtualizados, parseInt(termoId || ""))
        .then((res) => {
          // console.log(res);
          atualizarTermo({
            ...res,
            ...dadosAtualizados,
          });
          openNotification({
            type: "success",
            message: `Termo de Vistoria atualizado com sucesso!`,
          });

          handleBack();
        })
        .catch((error) => {
          console.log(error);
          const messageError = formatMessageError(error);
          openNotification({
            type: "error",
            message: `Não foi possivel editar Termo de Vistoria`,
            description: messageError,
          });
        });
    } else {
      atualizarAssinaturaTermo(
        dadosAtualizados,
        termoVistoria.id,
        termoVistoria
      );
      openNotification({
        type: "success",
        message: `Termo de Vistoria atualizado com sucesso!`,
      });
      handleBack();
    }
  };

  if (status === "error") {
    return (
      <Fragment>
        <Result
          status="error"
          title="Erro ao Editar Termo de Vistoria"
          subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
          extra={
            <Button onClick={() => navigate("/termo-vistoria/listagem")}>
              Voltar
            </Button>
          }
        />
      </Fragment>
    );
  } else if (status === "idle" || status === "loading") {
    return (
      <Fragment>
        <div
          style={{
            minHeight: "80vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </Fragment>
    );
  } else {
    return (
      <>
        <Typography.Title level={2} style={{ textAlign: "center" }}>
          Editar Termo de Vistoria {termoVistoria.numero}
        </Typography.Title>
        <Divider />
        <div
          style={{
            display: "flex",
            padding: "50px 15px 15px 15px",
          }}
        >
          <Checkbox.Group
            style={{
              display: "flex",
              flexDirection: "row",
              width: 250,
            }}
            onChange={(checkValue) => {
              setAssinaturaFisica(checkValue);
              setMotivoNaoAssinatura([]);
            }}
            value={assinaturaFisica}
          >
            <Checkbox value="ASSINADO_FISICAMENTE">Assinatura Física</Checkbox>
          </Checkbox.Group>
        </div>

        <AssinaturaContainer>
          <div className="fiscalizado-signature-form">
            <div>
              <div className="container-img-assinatura">
                {dadosFiscalizado.assinatura && (
                  <img
                    src={dadosFiscalizado.assinatura
                      .getTrimmedCanvas()
                      .toDataURL("image/png")}
                    alt="assinatura Fiscalizado"
                    style={{
                      width: "90%",
                      height: "90%",
                    }}
                  />
                )}
              </div>

              <Typography style={{ textAlign: "center" }}>
                Assinatura Fiscalizado
              </Typography>
              <Button
                disabled={
                  motivoNaoAssinatura.length > 0 || assinaturaFisica.length > 0
                }
                style={{ width: "100%" }}
                type="primary"
                onClick={() => {
                  handleOpenModal();
                }}
              >
                Clique aqui para assinar
              </Button>

              <div style={{ display: "flex", paddingTop: 10 }}>
                <Checkbox.Group
                  onChange={(e) =>
                    handleChangeMotivoNaoAssinaturaFiscalizado(e)
                  }
                  value={motivoNaoAssinatura}
                >
                  <Checkbox value="AUSENTE">Ausente</Checkbox>
                </Checkbox.Group>

                <Checkbox.Group
                  value={motivoNaoAssinatura}
                  onChange={(e) =>
                    handleChangeMotivoNaoAssinaturaFiscalizado(e)
                  }
                >
                  <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                </Checkbox.Group>
              </div>

              <div
                className="dados-assinante-tif"
                style={{
                  display: "flex",
                  gap: 10,
                  maxWidth: 600,
                }}
              >
                <div>
                  <span>Nome:</span>
                  <Input
                    disabled={
                      motivoNaoAssinatura.length > 0 &&
                      !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                        motivoNaoAssinatura[0].toString()
                      )
                    }
                    name="nome_assinante_fiscalizado"
                    value={dadosFiscalizado?.nomeAssinante}
                    onChange={(e) =>
                      setDadosFiscalizado({
                        ...dadosFiscalizado,
                        nomeAssinante: e.target.value,
                      })
                    }
                  />
                </div>

                <div>
                  <span>CPF:</span>
                  <Input
                    disabled={
                      motivoNaoAssinatura.length > 0 &&
                      !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                        motivoNaoAssinatura[0].toString()
                      )
                    }
                    name="cpf_assinante_fiscalizado"
                    value={dadosFiscalizado?.cpfAssinante}
                    onChange={(e) => {
                      setDadosFiscalizado({
                        ...dadosFiscalizado,
                        cpfAssinante: cpfCnpjMask(e.target.value),
                      });
                    }}
                  />
                </div>

                <div>
                  <span>Função:</span>
                  <Input
                    disabled={
                      motivoNaoAssinatura.length > 0 &&
                      !["ASSINADO_FISICAMENTE", "RECUSADO"].includes(
                        motivoNaoAssinatura[0].toString()
                      )
                    }
                    name="funcao_assinante_fiscalizado"
                    value={dadosFiscalizado?.funcaoAssinante}
                    onChange={(e) =>
                      setDadosFiscalizado({
                        ...dadosFiscalizado,
                        funcaoAssinante: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </AssinaturaContainer>
        <ButtonFooterContainer style={{ marginTop: 40 }}>
          <Button onClick={handleBack}>Voltar</Button>
          <Button type="primary" onClick={handleSubmit}>
            Atualizar Termo
          </Button>
        </ButtonFooterContainer>

        <ModalAssinatura
          refAssinante={refFiscalizado}
          isOpen={isOpenModal}
          cleanSignature={cleanSignatureFields}
          handleClose={handleCloseModal}
          nomeAssinante="Fiscalizado"
        />
      </>
    );
  }
}
