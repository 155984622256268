import { IMenuOptions } from "@interfaces/IMenuOptions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IMenuSlice {
  selectedKey: string[];
  menuOptions: IMenuOptions[];
  selectedKeyAnaliseLasgal: string[];
}

const initialState: IMenuSlice = {
  selectedKey: ["1"],
  menuOptions: [],
  selectedKeyAnaliseLasgal: ["PUREZA"],
};

const menuSlice = createSlice({
  name: "MENU",
  initialState,
  reducers: {
    updateKey: (state, action) => {
      const value = action.payload;
      state.selectedKey = value;
    },
    addMenu: (state, action: PayloadAction<{ menu: IMenuOptions[] }>) => {
      const { menu } = action.payload;

      const ordemDesejada = [
        "inicio",
        "TIF",
        "TermoColeta",
        "TermoApreensaoLiberacao",
        "TermoVistoria",
        "TID",
        // "TermoDestruicao",
        "armadilha",
        "LASGAL",
        "offlineMode",
        "Perfil",
        "config",
      ];

      //ORGANIZAR ORDEM DOS ITENS NO MENU
      menu.sort((a, b) => {
        return (
          ordemDesejada.indexOf(a.codigo) - ordemDesejada.indexOf(b.codigo)
        );
      });

      //TEMPORARIO REMOVER PESSOA E TERMO DE DESTRUICAO DO MENU
      state.menuOptions = menu.filter(
        (item) => item.codigo !== "pessoa" && item.codigo !== "TermoDestruicao"
      );
    },

    updateKeyAnaliseLasgal: (state, action) => {
      const { key } = action.payload;
      state.selectedKeyAnaliseLasgal = key;
    },
  },
});

export default menuSlice.reducer;

export const getMenuSelectedKey = (state: RootState) =>
  state.menuSisdev.selectedKey;

export const getMenuOptions = (state: RootState) =>
  state.menuSisdev.menuOptions;

export const getMenuSelectAnaliseLasgal = (state: RootState) =>
  state.menuSisdev.selectedKeyAnaliseLasgal;
