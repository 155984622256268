import { openNotification } from "@components/Notifications";
import { useArmadilha } from "@hooks/useArmadilha";
import { useEspecie } from "@hooks/useEspecie";
import { apiService } from "@services/api";
import { useTAL } from "pages/termoApreensaoLiberacao/hooks/useTAL";
import { useTCA } from "pages/termoColeta/hooks/useTCA";
import { useState } from "react";
import { DownloadingDadosTermo } from "../DownloadingDadosTermo";

export function SyncDadosCadastroArmadilha() {
  const { isEspecieSync, removeEspecies, syncEspecies } = useEspecie({
    isTCA: false,
  });
  const { termoApreensaoLiberacaoPersistedAtItems } = useTAL();
  const { termoColetaPersistedAtItems } = useTCA();
  const { updatePersistedAtSyncItems, persistedAtItemsSync } = useArmadilha();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async (action: string) => {
    const dateTimeExpire = Date.now();

    if (action === "adicionar" || action === "atualizar") {
      if (!navigator.onLine) {
        openNotification({
          type: "error",
          message: "Erro ao baixar informações",
          description:
            "Sem accesso a internet para baixar dados. Tente novamente mais tarde.",
        });
        return;
      }

      setIsDownloading(true);

      await Promise.allSettled([
        apiService.especie({ isAnaliseSemente: false }),
      ]).then((res) => {
        if (res[0].status === "fulfilled") {
          syncEspecies(res[0].value, dateTimeExpire);
          updatePersistedAtSyncItems(dateTimeExpire);
          openNotification({
            type: "success",
            message: `Dados para o Cadastro de Armadilha ${
              action === "adicionar" ? "sincronizados" : "atualizados"
            } com Sucesso!`,
          });
        }
      });

      setIsDownloading(false);
    } else {
      termoApreensaoLiberacaoPersistedAtItems === 0 &&
        termoColetaPersistedAtItems === 0 &&
        removeEspecies();

      updatePersistedAtSyncItems(0);
      openNotification({
        type: "success",
        message:
          "Dados sincronizados do Cadastro de armadilha removidos com Sucesso!",
      });
    }
  };

  return (
    <DownloadingDadosTermo
      title="Dados para o cadastro de armadilha:"
      dadosTermoDownloading={isDownloading}
      handleChange={handleDownload}
      isDataDownloaded={isEspecieSync && persistedAtItemsSync > 0}
      timeExpireData={persistedAtItemsSync}
      tipoTermo="Cadastro Armadilha"
      listaDadosSync={["Espécies"]}
    />
  );
}
