import { IEmbalagem } from "@interfaces/IEmbalagem";
import { apiService } from "@services/api";
import { embalagemAction } from "@store/actions/embalagemAction";
import { getEmbalagens } from "@store/feature/embalagemSlice";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

export const useEmbalagem = () => {
  const appDispatch = useDispatch();
  const listaEmbalagensSync = useSelector(getEmbalagens);
  const { addEmbalagens, removeEmbalagens } = embalagemAction;

  const { data: listaEmbalagens } = useQuery({
    queryKey: ["EMBALAGENS"],
    initialData: listaEmbalagensSync,
    queryFn: () => apiService.embalagens(),
  });

  const adicionarEmbalagens = (
    embalagens: IEmbalagem[],
    persistedAt: number
  ) => {
    appDispatch(addEmbalagens(embalagens, persistedAt));
  };

  const removerEmbalagens = () => {
    appDispatch(removeEmbalagens());
  };

  return { adicionarEmbalagens, removerEmbalagens, listaEmbalagens };
};
