import { LoadingOutlined } from "@ant-design/icons";
import { TIPO_IMAGENS } from "@constants/TIPO_IMAGENS";
import { IAnexos } from "@interfaces/IAnexos";
import { IFormAtividades } from "@interfaces/IFormAtividades";
import { IPerguntaImpressaoTIF } from "@interfaces/IPerguntaImpressaoTIF";
import { IRespostaAtividade } from "@interfaces/IRespostaAtividade";
import { ITif } from "@interfaces/ITif";
import { apiService } from "@services/api";
import { Button, Spin, Typography } from "antd";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { desorganizarListaAtividade } from "../../functions/desorganizarListaAtividade";
import { estruturarPerguntasImpressaoTif } from "../../functions/estruturarPerguntasImpressaoTif";
import { ordenarPerguntasAtividade } from "../../functions/ordenarPerguntasAtividade";
import { useTIF } from "../../hooks/useTIF";
import { Body } from "./components/Body";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Images } from "./components/Images";
import { Questionario } from "./components/Questionario";
import styles from "./style.module.css";

export function ImpressaoTIF() {
  const { termoIdentificador } = useParams();
  const navigate = useNavigate();
  const { listaTif, getAnexo } = useTIF();
  const contentRef = useRef<any>(null);
  const [anexosImages, setAnexosImages] = useState<IAnexos[]>([]);
  const [tifSelected, setTifSelected] = useState<ITif>();
  const [perguntas, setPerguntas] = useState<IPerguntaImpressaoTIF[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleSearchTifById = async (id: number) => {
    const tif = listaTif.find((tif) => tif.id === id);

    if (tif) {
      organizarPerguntasAtv(tif);

      setTifSelected(tif);
      const anexosTypeImage =
        tif.anexosTIF.filter((anexo) =>
          TIPO_IMAGENS.includes(
            anexo.nomeArquivo.split(".")[
              anexo.nomeArquivo.split(".").length - 1
            ]
          )
        ) || [];

      setAnexosImages(anexosTypeImage);
    } else {
      const aux = await apiService.tif.byId(id);
      organizarPerguntasAtv(aux);

      if (aux.anexosTIF.length > 0) {
        const dadosAnexos = aux.anexosTIF.map((anexo) => {
          return {
            nomeUpload: anexo.nomeUpload,
            nomeArquivo: anexo.nomeArquivo,
          };
        });

        const responseAnexos = await getAnexo(dadosAnexos);

        responseAnexos.forEach((anexo) => {
          if (
            TIPO_IMAGENS.includes(
              anexo.nomeArquivo.split(".")[
                anexo.nomeArquivo.split(".").length - 1
              ]
            )
          ) {
            anexosImages.push(anexo);
            setAnexosImages(anexosImages);
          }
        });
      }

      setTifSelected(aux);
    }

    setIsLoading(false);
  };

  const handleSearchTifByNumero = (numTIF: string | undefined) => {
    const tif = listaTif.find(
      (tif) => tif.numero === numTIF?.replaceAll("_", "/")
    );

    if (!tif) return;

    try {
      organizarPerguntasAtv(tif);
      setTifSelected(tif);
      const anexosTypeImage =
        tif.anexosTIF.filter((anexo) =>
          TIPO_IMAGENS.includes(
            anexo.nomeArquivo.split(".")[
              anexo.nomeArquivo.split(".").length - 1
            ]
          )
        ) || [];

      setIsLoading(false);
      setAnexosImages(anexosTypeImage);
    } catch (error) {
      console.log(error);
    }
  };

  const organizarPerguntasAtv = (tif: ITif) => {
    const auxPerguntas: IPerguntaImpressaoTIF[] = [];

    if (!tif.respostasAtividades) return;

    const atividades = desorganizarListaAtividade(tif.atividades).filter(
      (atv) => {
        if (atv.armadilha) {
          if (
            tif.respostasAtividades.some(
              (res) => res.armadilha?.id === atv.armadilha?.id
            )
          ) {
            return atv;
          }

          return null;
        } else {
          if (
            tif.respostasAtividades.some(
              (res) => res.atividade.id === atv.atividade.id
            )
          ) {
            return atv;
          }
          return null;
        }
      }
    );

    atividades.forEach((atv) => {
      //FILTRAR PERGUNTAS RESPONDIDAS COM NAO OBSERVADO
      const perguntasFiltradas = getPerguntasSemRespostaNaoObservado(
        tif.respostasAtividades,
        atv
      );

      if (perguntasFiltradas.length === 0) return;

      const perguntasComNumercao = estruturarPerguntasImpressaoTif(
        atv,
        perguntasFiltradas
      );

      auxPerguntas.push({
        perguntas: ordenarPerguntasAtividade(perguntasComNumercao),
        respostas: perguntasFiltradas,
        atividade: {
          id: atv.atividade.id,
          nome: atv.atividade.nome,
          armadilha: atv.armadilha,
        },
      });
    });

    setPerguntas(auxPerguntas);
  };

  const getPerguntasSemRespostaNaoObservado = (
    respostas: IRespostaAtividade[],
    atividade: IFormAtividades
  ) => {
    return respostas.filter((resposta) => {
      if (atividade.armadilha) {
        if (
          resposta.alternativas[0]?.alternativa.nome !== "NÃO OBSERVADO" &&
          resposta.pergunta.visivel &&
          resposta.armadilha?.id === atividade.armadilha.id
        ) {
          return resposta;
        }

        return null;
      } else {
        if (
          resposta.alternativas[0]?.alternativa.nome !== "NÃO OBSERVADO" &&
          resposta.pergunta.visivel &&
          resposta.atividade.id === atividade.atividade.id
        ) {
          return resposta;
        }

        return null;
      }
    });
  };

  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle: `TIF ${tifSelected?.numero}`,
    pageStyle: "@page{size:A4 !important; }",
    suppressErrors: true,
    preserveAfterPrint: true,
  });

  useEffect(() => {
    document.title = "Impressão TIF | SISDEV-mobile";

    if (termoIdentificador?.match("_")) {
      handleSearchTifByNumero(termoIdentificador);
      return;
    }

    handleSearchTifById(Number(termoIdentificador));

    //eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <>
        <div
          style={{
            minHeight: "80vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </>
    );
  }

  return (
    <Fragment>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Impressão TIF
      </Typography.Title>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 20,
          paddingBottom: 20,
        }}
      >
        <Button type="primary" onClick={() => handlePrint()}>
          Imprimir
        </Button>

        <Button onClick={() => navigate("/TIF/listagem")}>Voltar</Button>
      </div>
      {tifSelected && (
        <div className={styles["container-tif-impressao"]}>
          <div ref={contentRef} className={styles.content}>
            <div className="marcadagua"></div>
            <table className={`${styles["width-tif"]} tif-impressao-font`}>
              <Header numTIF={tifSelected.numero} />
              <Body tif={tifSelected} />
              <Footer tif={tifSelected} />
            </table>

            {perguntas.length > 0 && (
              <Questionario tif={tifSelected} perguntas={perguntas} />
            )}

            {anexosImages.map((anexo, ind) => {
              return <Images key={ind} tif={tifSelected} anexo={anexo} />;
            })}
          </div>
        </div>
      )}
    </Fragment>
  );
}
