import { ITif } from "@interfaces/ITif";

export function formatToFormData(
  formValues: ITif,
  anexos?: (File | undefined)[]
) {
  const formData = new FormData();

  Object.entries(formValues).forEach(([formKey, formValue]) => {
    if (
      [
        "anexosTIF",
        "atividades",
        "estabelecimento",
        "municipioFiscalizacao",
        "municipioFiscalizado",
        "produtor",
        "propriedade",
        "respostasAtividades",
        "servidores",
        "ule",
        "versaoSistema",
      ].includes(formKey)
    )
      return;

    formData.append(formKey, formValue);
  });

  //ATIVIDADES
  formValues.atividades.forEach((atv, index) => {
    formData.append(`atividades[${index}].coleta`, atv.coleta.toString());
    formData.append(`atividades[${index}].denuncia`, atv.denuncia.toString());
    formData.append(
      `atividades[${index}].interdicao`,
      atv.interdicao.toString()
    );
    formData.append(
      `atividades[${index}].desinterdicao`,
      atv.desinterdicao.toString()
    );
    formData.append(
      `atividades[${index}].notificacao`,
      atv.notificacao.toString()
    );

    //AREA ATIVIDADE
    Object.entries(atv.areaAtividade).forEach(([areaKey, areaValue]) => {
      formData.append(
        `atividades[${index}].areaAtividade.${areaKey}`,
        areaValue?.toString() || ""
      );
    });

    //ARMADILHA
    if (atv.armadilha) {
      Object.entries(atv.armadilha).forEach(([armKey, armValue]) => {
        if (["municipio"].includes(armKey)) return;

        formData.append(
          `atividades[${index}].armadilha.${armKey}`,
          armValue?.toString() || ""
        );
      });

      //atividade ARMADILHA MUNICIPIO
      if (atv.armadilha.municipio) {
        Object.entries(atv.armadilha.municipio).forEach(
          ([municipioKey, municipioValue]) => {
            if (["uf"].includes(municipioKey)) return;

            formData.append(
              `atividades[${index}].armadilha.municipio.${municipioKey}`,
              municipioValue?.toString() || ""
            );
          }
        );

        Object.entries(atv.armadilha.municipio.uf).forEach(
          ([ufKey, ufValue]) => {
            formData.append(
              `atividades[${index}].armadilha.municipio.uf.${ufKey}`,
              ufValue?.toString() || ""
            );
          }
        );
      }
    }

    // ATIVIDADE
    formData.append(
      `atividades[${index}].atividade.id`,
      atv?.atividade?.id?.toString() || ""
    );
    formData.append(
      `atividades[${index}].atividade.nome`,
      atv?.atividade?.nome || ""
    );

    //QUESTOES
    atv.atividade.questoes.forEach((quest, indQuest) => {
      formData.append(
        `atividades[${index}].atividade.questoes[${indQuest}].order`,
        quest?.order?.toString() || ""
      );

      formData.append(
        `atividades[${index}].atividade.questoes[${indQuest}].id`,
        quest?.id?.toString() || ""
      );

      //PERGUNTA
      Object.entries(quest.pergunta).forEach(([pergKey, pergValue]) => {
        if (
          [
            "alternativas",
            "antecessora",
            "campos",
            "respostaCondicional",
          ].includes(pergKey)
        )
          return;

        formData.append(
          `atividades[${index}].atividade.questoes[${indQuest}].pergunta.${pergKey}`,
          pergValue?.toString() || ""
        );
      });

      //ALTERNATIVAS
      quest.pergunta.alternativas.forEach((alt, indAlt) => {
        formData.append(
          `atividades[${index}].atividade.questoes[${indQuest}].pergunta.alternativas[${indAlt}].alternativa.id`,
          alt.id?.toString() || ""
        );
        formData.append(
          `atividades[${index}].atividade.questoes[${indQuest}].pergunta.alternativas[${indAlt}].alternativa.ativo`,
          alt.ativo?.toString() || ""
        );
        formData.append(
          `atividades[${index}].atividade.questoes[${indQuest}].pergunta.alternativas[${indAlt}].alternativa.nome`,
          alt.nome?.toString() || ""
        );
      });

      //ANTECESSORA
      if (quest.pergunta.antecessora) {
        Object.entries(quest.pergunta.antecessora).forEach(
          ([antecessorKey, antecessorValue]) => {
            formData.append(
              `atividades[${index}].atividade.questoes[${indQuest}].pergunta.antecessora.${antecessorKey}`,
              antecessorValue?.toString() || ""
            );
          }
        );
      }

      //CAMPOS
      quest.pergunta.campos.forEach((campo, indCampo) => {
        formData.append(
          `atividades[${index}].atividade.questoes[${indQuest}].pergunta.campos[${indCampo}].id`,
          campo?.id?.toString() || ""
        );
        formData.append(
          `atividades[${index}].atividade.questoes[${indQuest}].pergunta.campos[${indCampo}].nome`,
          campo?.nome?.toString() || ""
        );
        formData.append(
          `atividades[${index}].atividade.questoes[${indQuest}].pergunta.campos[${indCampo}].formatoResposta`,
          campo?.formatoResposta?.toString() || ""
        );
      });

      //RESPOSTA CONDICIONAL
      if (quest.pergunta.respostaCondicional) {
        Object.entries(quest.pergunta.respostaCondicional).forEach(
          ([respKey, respValue]) => {
            formData.append(
              `atividades[${index}].atividade.questoes[${indQuest}].pergunta.respostaCondicional.${respKey}`,
              respValue?.toString() || ""
            );
          }
        );
      }
    });
  });

  //ESTABELECIMENTO
  if (formValues.estabelecimento) {
    Object.entries(formValues.estabelecimento).forEach(
      ([estabKey, estabValue]) => {
        if (["coordenadaGeografica", "endereco"].includes(estabKey)) return;

        formData.append(
          `estabelecimento.${estabKey}`,
          estabValue?.toString() || ""
        );
      }
    );

    //COORDENANDA GEOGRAFICA ESTABELECIMENTO
    if (formValues.estabelecimento.coordenadaGeografica) {
      Object.entries(formValues.estabelecimento.coordenadaGeografica).forEach(
        ([coordKey, coordValue]) => {
          formData.append(
            `estabelecimento.coordenadaGeografica.${coordKey}`,
            coordValue?.toString() || ""
          );
        }
      );
    }

    //ENDERECO ESTABELECIMENTO
    if (formValues.estabelecimento.endereco) {
      Object.entries(formValues.estabelecimento.endereco).forEach(
        ([enderecoKey, enderecoValue]) => {
          if (["municipio", "tipoLogradouro"].includes(enderecoKey)) return;

          formData.append(
            `estabelecimento.endereco.${enderecoKey}`,
            enderecoValue?.toString() || ""
          );
        }
      );

      //MUNICIPIO ENDERECO
      if (formValues.estabelecimento.endereco.municipio) {
        Object.entries(formValues.estabelecimento.endereco.municipio).forEach(
          ([municipioKey, municipioValue]) => {
            if (["uf"].includes(municipioKey)) return;

            formData.append(
              `estabelecimento.endereco.municipio.${municipioKey}`,
              municipioValue?.toString() || ""
            );
          }
        );

        //UF MUNICIPIO ENDERECO
        if (formValues.estabelecimento.endereco.municipio.uf) {
          Object.entries(
            formValues.estabelecimento.endereco.municipio.uf
          ).forEach(([ufKey, ufValue]) => {
            formData.append(
              `estabelecimento.endereco.municipio.uf.${ufKey}`,
              ufValue?.toString() || ""
            );
          });
        }
      }

      //TIPO LOGRADOURO ENDERECO
      if (formValues.estabelecimento.endereco.tipoLogradouro) {
        Object.entries(
          formValues.estabelecimento.endereco.tipoLogradouro
        ).forEach(([logradouroKey, logradouroValue]) => {
          formData.append(
            `estabelecimento.endereco.tipoLogradouro.${logradouroKey}`,
            logradouroValue.toString() || ""
          );
        });
      }
    }
  }

  //MUNICIPIOS
  if (formValues.municipioFiscalizacao) {
    Object.entries(formValues.municipioFiscalizacao).forEach(
      ([municipioKey, municipioValue]) => {
        if (["uf"].includes(municipioKey)) return;

        formData.append(
          `municipioFiscalizacao.${municipioKey}`,
          municipioValue?.toString() || ""
        );
      }
    );

    Object.entries(formValues.municipioFiscalizacao.uf).forEach(
      ([ufKey, ufValue]) => {
        formData.append(
          `municipioFiscalizacao.uf.${ufKey}`,
          ufValue?.toString() || ""
        );
      }
    );
  }

  if (formValues.municipioFiscalizado) {
    Object.entries(formValues.municipioFiscalizado).forEach(
      ([municipioKey, municipioValue]) => {
        if (["uf"].includes(municipioKey)) return;

        formData.append(
          `municipioFiscalizado.${municipioKey}`,
          municipioValue?.toString() || ""
        );
      }
    );

    Object.entries(formValues.municipioFiscalizado.uf).forEach(
      ([ufKey, ufValue]) => {
        formData.append(
          `municipioFiscalizado.uf.${ufKey}`,
          ufValue?.toString() || ""
        );
      }
    );
  }

  //PRODUTOR
  if (formValues.produtor) {
    Object.entries(formValues.produtor).forEach(
      ([produtorKey, produtorValue]) => {
        if (["endereco"].includes(produtorKey)) return;

        formData.append(
          `produtor.${produtorKey}`,
          produtorValue?.toString() || ""
        );
      }
    );

    //ENDERECO PRODUTOR
    if (formValues.produtor.endereco) {
      Object.entries(formValues.produtor.endereco).forEach(
        ([enderecoKey, enderecoValue]) => {
          if (["municipio", "tipoLogradouro"].includes(enderecoKey)) return;

          formData.append(
            `produtor.endereco.${enderecoKey}`,
            enderecoValue?.toString() || ""
          );
        }
      );

      //MUNICIPIO ENDERECO
      if (formValues.produtor.endereco.municipio) {
        Object.entries(formValues.produtor.endereco.municipio).forEach(
          ([municipioKey, municipioValue]) => {
            if (["uf"].includes(municipioKey)) return;

            formData.append(
              `produtor.endereco.municipio.${municipioKey}`,
              municipioValue?.toString() || ""
            );
          }
        );

        //UF MUNICIPIO ENDERECO
        if (formValues.produtor.endereco.municipio.uf) {
          Object.entries(formValues.produtor.endereco.municipio.uf).forEach(
            ([ufKey, ufValue]) => {
              formData.append(
                `produtor.endereco.municipio.uf.${ufKey}`,
                ufValue?.toString() || ""
              );
            }
          );
        }
      }

      //TIPO LOGRADOURO ENDERECO
      if (formValues.produtor.endereco.tipoLogradouro) {
        Object.entries(formValues.produtor.endereco.tipoLogradouro).forEach(
          ([logradouroKey, logradouroValue]) => {
            formData.append(
              `produtor.endereco.tipoLogradouro.${logradouroKey}`,
              logradouroValue?.toString() || ""
            );
          }
        );
      }
    }
  }

  //PROPRIEDADE
  if (formValues.propriedade) {
    Object.entries(formValues.propriedade).forEach(
      ([propriedadeKey, propriedadeValue]) => {
        if (
          ["coordenadaGeografica", "municipio", "produtores"].includes(
            propriedadeKey
          )
        )
          return;

        formData.append(
          `propriedade.${propriedadeKey}`,
          propriedadeValue?.toString() || ""
        );
      }
    );

    //COORDENANDA GEOGRAFICA PROPRIEDADE
    if (formValues.propriedade.coordenadaGeografica) {
      Object.entries(formValues.propriedade.coordenadaGeografica).forEach(
        ([coordKey, coordValue]) => {
          formData.append(
            `propriedade.coordenadaGeografica.${coordKey}`,
            coordValue?.toString() || ""
          );
        }
      );
    }

    //MUNICIPIO PROPRIEDADE
    if (formValues.propriedade.municipio) {
      Object.entries(formValues.propriedade.municipio).forEach(
        ([municipioKey, municipioValue]) => {
          if (["uf"].includes(municipioKey)) return;

          formData.append(
            `propriedade.municipio.${municipioKey}`,
            municipioValue?.toString() || ""
          );
        }
      );

      //UF MUNICIPIO PROPRIEDADE
      if (formValues.propriedade.municipio.uf) {
        Object.entries(formValues.propriedade.municipio.uf).forEach(
          ([ufKey, ufValue]) => {
            formData.append(
              `propriedade.municipio.uf.${ufKey}`,
              ufValue?.toString() || ""
            );
          }
        );
      }
    }

    //PRODUTORES PROPRIEDADE
    formValues.propriedade.produtores?.forEach((prod, index) => {
      Object.entries(prod).forEach(([produtorKey, produtorValue]) => {
        if (["endereco"].includes(produtorKey)) return;

        formData.append(
          `propriedade.produtores[${index}].${produtorKey}`,
          produtorValue?.toString() || ""
        );
      });

      //ENDERECO PRODUTOR PROPRIEDADE
      if (prod.endereco) {
        Object.entries(prod.endereco).forEach(
          ([enderecoKey, enderecoValue]) => {
            if (["municipio", "tipoLogradouro"].includes(enderecoKey)) return;

            formData.append(
              `propriedade.produtores[${index}].endereco.${enderecoKey}`,
              enderecoValue?.toString() || ""
            );
          }
        );
      }

      //MUNICIPIO ENDERECO PROPRIEDADE
      if (prod.endereco.municipio) {
        Object.entries(prod.endereco.municipio).forEach(
          ([municipioKey, municipioValue]) => {
            if (["uf"].includes(municipioKey)) return;

            formData.append(
              `propriedade.produtores[${index}].endereco.municipio.${municipioKey}`,
              municipioValue?.toString() || ""
            );
          }
        );

        //UF MUNICIPIO ENDERECO
        if (prod.endereco.municipio.uf) {
          Object.entries(prod.endereco.municipio.uf).forEach(
            ([ufKey, ufValue]) => {
              formData.append(
                `propriedade.produtores[${index}].endereco.municipio.uf.${ufKey}`,
                ufValue?.toString() || ""
              );
            }
          );
        }
      }

      //TIPO_LOGRADOURO ENDERECO PROPRIEDADE
      if (prod.endereco.tipoLogradouro) {
        Object.entries(prod.endereco.tipoLogradouro).forEach(
          ([logradouroKey, logradouroValue]) => {
            formData.append(
              `propriedade.produtores[${index}].endereco.tipoLogradouro.${logradouroKey}`,
              logradouroValue?.toString() || ""
            );
          }
        );
      }
    });
  }

  //RESPOSTAS ATIVIDADES
  formValues.respostasAtividades.forEach((resposta, index) => {
    formData.append(
      `respostasAtividades[${index}].descricao`,
      resposta.descricao || ""
    );

    if (
      ["ALTERNATIVA", "MULTIPLA_ESCOLHA"].includes(
        resposta.pergunta.tipoResposta
      ) &&
      (!resposta.alternativas || resposta.alternativas.length === 0)
    ) {
      throw new Error(
        `Erro ao formatar respostas. Favor entrar em contato com o suporte, enviando o backup do termo se possivel.`
      );
    }

    // ALTERNATIVA RESPOSTA ATIVIDADE
    resposta.alternativas.forEach((alt, indAlt) => {
      formData.append(
        `respostasAtividades[${index}].alternativas[${indAlt}].alternativa.id`,
        alt.alternativa?.id?.toString()
      );
      formData.append(
        `respostasAtividades[${index}].alternativas[${indAlt}].alternativa.nome`,
        alt.alternativa?.nome?.toString()
      );
      formData.append(
        `respostasAtividades[${index}].alternativas[${indAlt}].alternativa.ativo`,
        alt.alternativa?.ativo?.toString()
      );
    });

    //ARMADILHA
    if (resposta.armadilha) {
      Object.entries(resposta.armadilha).forEach(([armKey, armValue]) => {
        if (["municipio"].includes(armKey)) return;

        formData.append(
          `respostasAtividades[${index}].armadilha.${armKey}`,
          armValue?.toString() || ""
        );
      });

      //atividade ARMADILHA MUNICIPIO
      if (resposta.armadilha.municipio) {
        Object.entries(resposta.armadilha.municipio).forEach(
          ([municipioKey, municipioValue]) => {
            if (["uf"].includes(municipioKey)) return;

            formData.append(
              `respostasAtividades[${index}].armadilha.municipio.${municipioKey}`,
              municipioValue?.toString() || ""
            );
          }
        );

        Object.entries(resposta.armadilha.municipio.uf).forEach(
          ([ufkey, ufValue]) => {
            formData.append(
              `respostasAtividades[${index}].armadilha.municipio.uf.${ufkey}`,
              ufValue?.toString() || ""
            );
          }
        );
      }
    }

    //ATIVIDADE RESPOSTA
    if (resposta.atividade) {
      Object.entries(resposta.atividade).forEach(([atvKey, atvValue]) => {
        formData.append(
          `respostasAtividades[${index}].atividade.${atvKey}`,
          atvValue?.toString() || ""
        );
      });
    }

    //PERGUNTA RESPOSTA
    if (resposta.pergunta) {
      Object.entries(resposta.pergunta).forEach(([pergKey, pergValue]) => {
        formData.append(
          `respostasAtividades[${index}].pergunta.${pergKey}`,
          pergValue?.toString() || ""
        );
      });
    }

    //CAMPOS RESPOSTA
    resposta.campos.forEach(({ campo, id, resposta }, indCampo) => {
      formData.append(
        `respostasAtividades[${index}].campos[${indCampo}].id`,
        id?.toString() || ""
      );
      formData.append(
        `respostasAtividades[${index}].campos[${indCampo}].resposta`,
        resposta?.toString() || ""
      );

      Object.entries(campo).forEach(([campoKey, campoValue]) => {
        formData.append(
          `respostasAtividades[${index}].campos[${indCampo}].campo.${campoKey}`,
          campoValue?.toString() || ""
        );
      });
    });
  });

  //ULE
  if (formValues.ule) {
    Object.entries(formValues.ule).forEach(([uleKey, uleValue]) => {
      if (["municipio", "urs", "telefones"].includes(uleKey)) return;

      formData.append(`ule.${uleKey}`, uleValue?.toString() || "");
    });

    Object.entries(formValues.ule.municipio).forEach(
      ([municipioKey, municipioValue]) => {
        if (["uf"].includes(municipioKey)) return;

        formData.append(
          `ule.municipio.${municipioKey}`,
          municipioValue?.toString() || ""
        );
      }
    );

    Object.entries(formValues.ule.municipio.uf).forEach(([ufKey, ufValue]) => {
      formData.append(`ule.municipio.uf.${ufKey}`, ufValue?.toString() || "");
    });
  }

  //SERVIDORES
  formValues.servidores.forEach((serv, index) => {
    formData.append(`servidores[${index}].assinatura`, serv.assinatura || "");

    Object.entries(serv.servidor).forEach(([servidorKey, servidorValue]) => {
      formData.append(
        `servidores[${index}].servidor.${servidorKey}`,
        servidorValue?.toString() || ""
      );
    });
  });

  //ANEXO
  const file = new File([], "");

  if (!anexos || anexos.length === 0) {
    formData.append("anexosTIF", file);
  }

  anexos?.forEach((item) => {
    formData.append("anexosTIF", item || file);
  });

  return formData;
}
