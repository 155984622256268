import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MenuOutlined,
  MinusOutlined,
  PrinterOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";
import { openNotification } from "@components/Notifications";
import { formatMessageError } from "@functions/formatMessageError";
import { usePermission } from "@hooks/usePermission";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { apiService } from "@services/api";
import { Button, Dropdown, List, Menu, message, Modal, Tooltip } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTV } from "../../../hooks/useTV";
import styles from "../../style.module.css";

const CODIGO_TERMO = "TermoVistoria";

interface ITermoVistoriaItemsList extends ITermoVistoria {
  error?: boolean;
}

export function ItemListaTV({
  termoVistoria,
  pageControl,
}: {
  termoVistoria: ITermoVistoriaItemsList;
  pageControl: number;
}) {
  const navigate = useNavigate();
  const { atualizarTermo, removerTermo, listaTermos } = useTV();
  const { isAllowed } = usePermission();
  const [modal, contextHolder] = Modal.useModal();
  const [isCancel, setIsCancel] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //APAGAR TERMO CASO NAO TENHA ID
  const handleDeleteTermo = () => {
    setIsDelete(true);
    modal.confirm({
      title: `Tem certeza que deseja Excluir o Termo de Vistoria ${termoVistoria.numero}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      cancelText: "Não",
      onOk: () => {
        removerTermo(termoVistoria.numero);
        openNotification({
          type: "success",
          message: "Termo de Vistoria excluído com sucesso!",
        });
        setIsDelete(false);
      },
      onCancel: () => {
        setIsDelete(false);
      },
    });
  };

  //IMPRIMIR
  const handlePrintedTermo = () => {
    const redirectURL = `/termo-vistoria/listagem/imprimir/${termoVistoria.numero.replaceAll(
      "/",
      "_"
    )}`;
    localStorage.setItem("termoVistoria:pageSelect", pageControl.toString());
    navigate(redirectURL);

    return;
  };

  //VISUALIZAR
  const handleViewTermo = () => {
    if (!termoVistoria.id) return;
    navigate(`/termo-vistoria/listagem/visualizar/${termoVistoria.id}`);
  };

  //EDITAR
  const handleEditTermo = () => {
    navigate(
      `/termo-vistoria/listagem/editar/${termoVistoria.numero.replaceAll("/", "_")}`
    );
  };

  //EDITAR APOS O ENVIO
  const handleEditPosEnvio = () => {
    if (!termoVistoria.id) return;

    navigate(`/termo-vistoria/listagem/editar-assinatura/${termoVistoria.id}`);
    localStorage.setItem("termoVistoria:pageSelect", pageControl.toString());
  };

  // REMOVER DA APLICAÇÃO
  const handleRemoveTermoInApp = () => {
    if (!termoVistoria.id) return;

    const termo = listaTermos.filter(
      (auxTermo) => auxTermo.id === termoVistoria.id
    )[0];

    removerTermo(termo.numero);
    openNotification({
      type: "success",
      message: `Termo de Vistoria ${termo.numero} removido do aplicativo com sucesso!`,
    });
  };

  //CANCELAR TERMO
  const handleCancelTermo = async () => {
    if (!termoVistoria.id) return;

    setIsCancel(true);
  };

  //ENVIAR TERMO
  const handlePostTermo = async () => {
    try {
      setIsLoading(true);
      message.loading({
        content: "Enviando termo...",
        key: "loading",
      });
      const result = await apiService.termoVistoria.enviar(termoVistoria);

      atualizarTermo({ ...termoVistoria, id: result.data.id });
      openNotification({
        type: "success",
        message: "Termo salvo com sucesso!",
        description: "Termo salvo na base de dados.",
      });
      message.destroy("loading");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      message.destroy("loading");
      setIsLoading(false);
      const messageError = formatMessageError(error);
      openNotification({
        type: "error",
        message: "Não foi possivel salvar o Termo de Vistoria",
        description: messageError,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <List.Item
        className="lista-item"
        style={{
          border: isCancel || isDelete ? "1px solid #ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{
                color: isCancel || isDelete ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              Termo de Vistoria {termoVistoria.numero}
            </span>
          }
          description={
            <span className="subtitulo-card">
              ID:{termoVistoria.id ? termoVistoria.id : ""}
            </span>
          }
        />

        <div className={styles["pc-menu-lista-termos"]}>
          {(!termoVistoria.id || termoVistoria.error) && navigator.onLine && (
            <Button disabled={isLoading} onClick={() => handlePostTermo()}>
              {isLoading ? <SyncOutlined spin={isLoading} /> : "Enviar"}
            </Button>
          )}

          <Tooltip title="Imprimir" destroyTooltipOnHide>
            <Button onClick={() => handlePrintedTermo()}>
              <PrinterOutlined />
            </Button>
          </Tooltip>

          {termoVistoria.id && (
            <>
              <Tooltip title="Visualizar" destroyTooltipOnHide>
                <Button
                  disabled={
                    !navigator.onLine || !isAllowed("visualizar", CODIGO_TERMO)
                  }
                  onClick={(e) => handleViewTermo()}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Editar" destroyTooltipOnHide>
                <Button
                  disabled={
                    termoVistoria.statusAssinaturaFiscalizado !== "AUSENTE" ||
                    !isAllowed("editar", CODIGO_TERMO)
                  }
                  onClick={(e) => handleEditPosEnvio()}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Cancelar" destroyTooltipOnHide>
                <Button
                  disabled={
                    !navigator.onLine || !isAllowed("excluir", CODIGO_TERMO)
                  }
                  danger
                  onClick={() => handleCancelTermo()}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Remover do uso Offline" destroyTooltipOnHide>
                <Button danger onClick={() => handleRemoveTermoInApp()}>
                  <MinusOutlined />
                </Button>
              </Tooltip>
            </>
          )}

          {!termoVistoria.id && (
            <>
              <Tooltip title="Editar" destroyTooltipOnHide>
                <Button onClick={() => handleEditTermo()}>
                  <EditOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="Excluir" destroyTooltipOnHide>
                <Button danger onClick={() => handleDeleteTermo()}>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </>
          )}
        </div>

        <div className={styles["mobile-menu-lista-termos"]}>
          {(!termoVistoria.id || termoVistoria.error) && navigator.onLine && (
            <Button disabled={isLoading} onClick={() => handlePostTermo()}>
              {isLoading ? <SyncOutlined spin={isLoading} /> : "Enviar"}
            </Button>
          )}

          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={() => {
              return (
                <>
                  {termoVistoria.id ? (
                    <Menu
                      items={[
                        {
                          key: 0,
                          label: "Imprimir",
                          icon: <PrinterOutlined />,
                          onClick: () => handlePrintedTermo(),
                        },
                        {
                          key: 1,
                          label: "Visualizar",
                          disabled:
                            !navigator.onLine ||
                            !isAllowed("visualizar", CODIGO_TERMO),
                          icon: <EyeOutlined />,
                          onClick: (e) => handleViewTermo(),
                        },
                        {
                          key: 2,
                          label: "Editar",
                          icon: <EditOutlined />,
                          disabled:
                            termoVistoria.statusAssinaturaFiscalizado !==
                              "AUSENTE" || !isAllowed("editar", CODIGO_TERMO),
                          onClick: () => handleEditPosEnvio(),
                        },
                        {
                          key: 3,
                          label: "Cancelar",
                          disabled:
                            !navigator.onLine ||
                            !isAllowed("excluir", CODIGO_TERMO),
                          icon: <CloseOutlined />,
                          danger: true,
                          onClick: () => handleCancelTermo(),
                        },
                        {
                          key: 4,
                          label: "Remover do uso offline",
                          disabled: !navigator.onLine,
                          icon: <MinusOutlined />,
                          danger: true,
                          onClick: () => handleRemoveTermoInApp(),
                        },
                      ]}
                    />
                  ) : (
                    <Menu
                      items={[
                        {
                          key: 1,
                          label: "Imprimir",
                          icon: <PrinterOutlined />,
                          onClick: () => handlePrintedTermo(),
                        },
                        {
                          key: 2,
                          label: "Editar",
                          icon: <EditOutlined />,
                          onClick: () => handleEditTermo(),
                        },
                        {
                          key: 3,
                          label: "Excluir",
                          icon: <DeleteOutlined />,
                          danger: true,
                          onClick: () => handleDeleteTermo(),
                        },
                      ]}
                    />
                  )}
                </>
              );
            }}
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      <ModalCancelarTermo
        isOpen={isCancel}
        setIsOpen={setIsCancel}
        numTermo={termoVistoria.numero}
        apiName="termoVistoria"
        titleTermo="Termo de Vistoria"
        idTermo={termoVistoria.id}
        onRemoveTermo={removerTermo}
        reloadFilter={async () => {}}
      />
    </>
  );
}
