import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ModalInformarNumeroTermo } from "@components/ModalInformarNumeroTermo";
import { openNotification } from "@components/Notifications";
import { TabelaServidoresForm } from "@components/TabelaServidoresForm";
import { UploadFilesForm } from "@components/UploadFilesForm";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { INITIAL_VALUES_ASSINATURA } from "@constants/INITIAL_VALUES_ASSINATURA";
import { checkDevice } from "@functions/checkDevice";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { formatMessageError } from "@functions/formatMessageError";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { masks } from "@functions/mascaras";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { useForms } from "@hooks/useForms";
import { useMunicipio } from "@hooks/useMunicipio";
import { useServidores } from "@hooks/useServidores";
import { IFormValuesTAL } from "@interfaces/IFormValuesTAL";
import { IContentListagemTIF } from "@interfaces/IListagemTIF";
import { IMunicipio } from "@interfaces/IMunicipio";
import { IServidorForm } from "@interfaces/IServidorForm";
import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Typography,
  UploadFile,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import confirm from "antd/lib/modal/confirm";
import fs from "file-saver";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import ReactSignatureCanvas from "react-signature-canvas";
import { TermoApreensaoSubmited } from "../../@types/TermoApreensaoSubmited";
import { formatToFormData } from "../termoApreensaoLiberacao/functions/formatToFormData";
import { resetarDepositarioFields } from "../termoApreensaoLiberacao/functions/resetarDepositarioFields";
import { useTAL } from "../termoApreensaoLiberacao/hooks/useTAL";
import { AssinaturaTAL } from "./components/AssinaturaTAL";
import { DadosFiscalizacaoTAL } from "./components/DadosFiscalizacaoTAL";
import { FiscalizadoRegistroTAL } from "./components/FiscalizadoRegistroTAL";
import { ItensTAL } from "./components/ItensTAL";
import { PreviaTAL } from "./components/PreviaTAL";
import { RegistroDepositarioTAL } from "./components/RegistroDepositarioTAL";

export function TermoApreensaoLiberacao() {
  const { tifMask: numTermoMask } = masks;
  const [form] = Form.useForm<IFormValuesTAL>();
  const {
    removerTodosItems,
    adicionarItem,
    listaItens,
    getTermoApreensao,
    adicionarTermo,
    adicionarTermoByFiltro,
  } = useTAL();
  const { messageSubmitFailed, gerarNumeroTermo } = useForms();
  const { servidoresSelecionados, removerTodosServidoresSelecionados } =
    useServidores();
  const { municipiosBrasil, getMunicipioPorId } = useMunicipio();
  const [modal, contextHolder] = Modal.useModal();
  const [formServidores] = Form.useForm();

  const [isOpenModalInformarNumTermo, setIsOpenModalInformarNumTermo] =
    useState(false);
  const [numTermo, setNumTermo] = useState("");
  const [isLiberacao, setIsLiberacao] = useState(false);
  const [hasTermoApreensao, setHasTermoApreensao] = useState(false);
  const [isAssinadoFisicamente, setIsAssinadoFisicamente] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProprio, setIsProprio] = useState(true);
  const [isTifExisist, setIsTifExisist] = useState(false);
  const [isValidDocument, setIsValidDocument] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [tifSelected, setTifSelected] = useState<any>();
  const [termoApreensaoSelected, setTermoApreensaoSelected] =
    useState<ITermoApreensaoLiberacao | null>(null);
  const [anexo, setAnexo] = useState<UploadFile[]>([]);
  const [motivoNaoAssinaturaDepositario, setMotivoNaoAssinaturaDepositario] =
    useState<CheckboxValueType[]>([]);
  const [motivoNaoAssinaturaFiscalizado, setMotivoNaoAssinaturaFiscalizado] =
    useState<CheckboxValueType[]>([]);
  const [checkBoxAssinaturaFisica, setCheckBoxAssinaturaFisica] = useState<
    CheckboxValueType[]
  >([]);
  const [municipioDepositarioOptions, setMunicipioDepositarioOptions] =
    useState<IMunicipio[]>([]);
  const [servidores, setServidores] = useState<IServidorForm[]>([]);
  const [fiscalizadoSignature, setFiscalizadoSignature] = useState(
    INITIAL_VALUES_ASSINATURA
  );
  const [depositarioSignature, setDepositarioSignature] = useState(
    INITIAL_VALUES_ASSINATURA
  );

  /**FUNCTIONS */
  const handleChangeTipoDepositario = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setIsProprio(value === "PROPRIO");

    if (value === "PROPRIO") {
      setIsValidDocument(false);
      resetarDepositarioFields(form);
      setDepositarioSignature(INITIAL_VALUES_ASSINATURA);
    }
  };

  const handleSubmit = async (values: IFormValuesTAL) => {
    const keyMessage = "termo-apreensao-liberacao";

    if (!tifSelected) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Adicione um TIF válido ao Termo.",
      });
      return;
    }

    if (listaItens.length === 0) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Adicione ao menos um item para finalizar o Termo.",
      });
      return;
    }

    if (servidoresSelecionados.length === 0) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Adicione ao menos um servidor",
      });
      return;
    }

    if (
      (!fiscalizadoSignature.nomeAssinante ||
        !fiscalizadoSignature.cpfAssinante ||
        !fiscalizadoSignature.funcaoAssinante) &&
      [ASSINATURA_FISICA, "RECUSADO"].includes(
        motivoNaoAssinaturaFiscalizado[0].toString()
      )
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Preencha os dados do assinante Fiscalizado",
      });

      return;
    }

    if (
      (!depositarioSignature.nomeAssinante ||
        !depositarioSignature.cpfAssinante ||
        !depositarioSignature.funcaoAssinante) &&
      motivoNaoAssinaturaDepositario.length === 0 &&
      !isProprio
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Preencha os dados do assinante Depositario",
      });

      return;
    }

    if (
      !hasServidorAssinatura() ||
      !hasAssinatura(
        motivoNaoAssinaturaFiscalizado[0],
        fiscalizadoSignature.assinatura
      ) ||
      (!isProprio &&
        !hasAssinatura(
          motivoNaoAssinaturaDepositario[0],
          depositarioSignature.assinatura
        ))
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Preencha as assinaturas pendentes",
      });
      return;
    }

    const numTermoApreensaoLiberacao =
      numTermo || gerarNumeroTermo(values.municipioFiscalizacaoId);

    const tifSubmited: IContentListagemTIF = {
      ativo: tifSelected.ativo,
      dataFiscalizacao:
        moment(tifSelected.dataFiscalizacao).locale("pt-br").format("L") ===
        "Data inválida"
          ? tifSelected.dataFiscalizacao
          : moment(tifSelected.dataFiscalizacao).locale("pt-br").format("L"),
      id: tifSelected.id,
      municipioFiscalizacao: tifSelected.municipioFiscalizacao,
      nomeFiscalizado: tifSelected.nomeFiscalizado,
      nomePropriedade: tifSelected.nomePropriedade,
      numero: tifSelected.numero,
      statusAssinatura: tifSelected.statusAssinatura,
      enderecoPropriedade: tifSelected.enderecoPropriedade,
    };

    const termoApreensaoSubmited: TermoApreensaoSubmited =
      termoApreensaoSelected
        ? {
            amparoLegal: termoApreensaoSelected.amparoLegal,
            dataFiscalizacao:
              moment(termoApreensaoSelected.dataFiscalizacao).format(
                "DD/MM/YYYY"
              ) === "Data inválida"
                ? termoApreensaoSelected.dataFiscalizacao
                : moment(termoApreensaoSelected.dataFiscalizacao).format(
                    "DD/MM/YYYY"
                  ),
            id: termoApreensaoSelected.id,
            municipioFiscalizacao: termoApreensaoSelected.municipioFiscalizacao,
            nomeFiscalizado: termoApreensaoSelected.nomeFiscalizado,
            produto: termoApreensaoSelected.produto,
            numero: termoApreensaoSelected.numero,
            providencias: termoApreensaoSelected.providencias,
            tipoTermo: termoApreensaoSelected.tipoTermo,
          }
        : null;

    const servidorSelected = servidores.map((serv) => {
      return {
        assinatura:
          getStatusAssinatura(motivoNaoAssinaturaFiscalizado) ===
          ASSINATURA_FISICA
            ? ""
            : serv.assinatura,
        servidor: {
          cpfCnpj: serv.servidor.cpfCnpj,
          id: serv.servidor.id,
          inscricaoEstadual: serv.servidor.inscricaoEstadual,
          matricula: serv.servidor.matricula,
          nome: serv.servidor.nome,
        },
      };
    });

    const formatItensToTermoApreensaoLiberacao = listaItens.map((item) => {
      return {
        ...item,
        dataValidade:
          moment(item.dataValidade).format("DD/MM/YYYY") === "Data inválida"
            ? ""
            : moment(item.dataValidade).format("DD/MM/YYYY"),
      };
    });

    let termo: ITermoApreensaoLiberacao = {
      produto: "",
      descricao: "",
      itens: formatItensToTermoApreensaoLiberacao,
      numero: numTermoApreensaoLiberacao,
      termoApreensao: termoApreensaoSubmited,
      tipoTermo: values.termo,
      nomeDepositario: values.nomeDepositario || "",
      nomeFiscalizado: values.nomeFiscalizado || "",
      cpfCnpjDepositario: values.cpfCnpjDepositario
        ? values.cpfCnpjDepositario.replace(/[^\d]+/g, "")
        : "",
      cpfCnpjFiscalizado: values.cpfCnpjFiscalizado
        ? values.cpfCnpjFiscalizado.replace(/[^\d]+/g, "")
        : "",
      emailDepositario: values.emailDepositario || "",
      emailFiscalizado: values.emailFiscalizado || "",
      telefoneDepositario: values.telefoneDepositario
        ? values.telefoneDepositario.replace(/[^\d]+/g, "")
        : "",
      telefoneFiscalizado: values.telefoneFiscalizado
        ? values.telefoneFiscalizado?.replace(/[^\d]+/g, "")
        : "",
      cepDepositario: values.cepDepositario || "",
      cepFiscalizado: values.cepFiscalizado || "",
      inscricaoEstadualDepositario: values.rgDepositario || "",
      inscricaoEstadualFiscalizado: values.rgFiscalizado || "",
      renasemDepositario: values.renasemDepositario || "",
      renasemFiscalizado: values.renasemFiscalizado || "",
      municipioDepositario: getMunicipioPorId(values.municipioDepositarioId),
      municipioFiscalizado: getMunicipioPorId(values.municipioFiscalizacaoId),
      municipioFiscalizacao: getMunicipioPorId(values.municipioFiscalizacaoId),
      assinaturaDepositario:
        motivoNaoAssinaturaDepositario.length === 0
          ? depositarioSignature.assinatura?.getTrimmedCanvas().toDataURL() ||
            ""
          : "",
      assinaturaFiscalizado:
        motivoNaoAssinaturaFiscalizado.length === 0
          ? fiscalizadoSignature?.assinatura?.getTrimmedCanvas().toDataURL() ||
            ""
          : "",
      nomeAssinanteDepositario: depositarioSignature.nomeAssinante,
      nomeAssinanteFiscalizado: fiscalizadoSignature.nomeAssinante,
      cpfAssinanteDepositario: depositarioSignature.cpfAssinante?.replace(
        /[^\d]+/g,
        ""
      ),
      cpfAssinanteFiscalizado: fiscalizadoSignature.cpfAssinante.replace(
        /[^\d]+/g,
        ""
      ),
      funcaoAssinanteDepositario: depositarioSignature.funcaoAssinante,
      funcaoAssinanteFiscalizado: fiscalizadoSignature.funcaoAssinante,
      tif: tifSubmited,
      amparoLegal: values.amparoLegal || "",
      providencias: values.providencias || "",
      servidores: servidorSelected,
      statusAssinaturaDepositario: isProprio
        ? "ASSINADO_DIGITALMENTE"
        : getStatusAssinatura(motivoNaoAssinaturaDepositario),
      statusAssinaturaFiscalizado: getStatusAssinatura(
        motivoNaoAssinaturaFiscalizado
      ),
      enderecoDepositario: values.enderecoDepositario || "",
      enderecoFiscalizado: values.enderecoFiscalizado || "",
      dataFiscalizacao: moment().format("DD/MM/YYYY"),
      localDeposito: values.enderecoDepositario || "",
      anexos: [],
    };

    let anexosSubmitToTermo: (File | undefined)[] = [];

    if (anexo.length > 0) {
      await getAnexosFormatadosBase64(anexo).then((res) => {
        termo = { ...termo, anexos: res };

        const aux = res.map((anexo) => {
          return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
        });

        anexosSubmitToTermo = aux.filter((anexo) => anexo !== undefined);
      });
    }

    const formData = formatToFormData(termo, anexosSubmitToTermo);
    const tipoTermoFormatado =
      termo.tipoTermo === "APREENSAO" ? "Apreensão" : "Liberação";

    try {
      if (navigator.onLine) {
        setIsSaving(true);
        message.loading({
          key: keyMessage,
          duration: 0,
          content: "Salvando Termo...",
        });
        await apiService.termoApreensaoLiberacao
          .enviar(formData)
          .then((res) => {
            adicionarTermoByFiltro({ ...termo, id: res.data.id });
            setIsSaving(false);
            message.destroy(keyMessage);
            openNotification({
              type: "success",
              message: `Termo de ${tipoTermoFormatado} salvo com sucesso!`,
            });
          });
      } else {
        adicionarTermo(termo);
        // MODAL DE CONFIRMAÇÃO SE O USUARIO QUISER BAIXAR UM BACKUP
        modal.info({
          title: `Gostaria de baixar um backup do Termo de ${tipoTermoFormatado}?`,
          icon: <ExclamationCircleOutlined />,
          okText: "Sim",
          onOk: () => {
            let blob = new Blob([JSON.stringify(termo)], {
              type: "text/plain;charset=utf-8",
            });
            fs.saveAs(
              blob,
              `Termo de ${tipoTermoFormatado} ${termo.numero}.txt`,
              {
                autoBom: true,
              }
            );
            openNotification({
              type: "success",
              message: `Termo de ${tipoTermoFormatado} criado com sucesso!`,
              description: `Salvamento do Termo de ${tipoTermoFormatado} pendente, para quando aplicativo houver conexão com a internet.`,
            });
          },
        });
      }
      handleResetTermo();
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      message.destroy(keyMessage);
      const messageError = formatMessageError(error);

      openNotification({
        type: "error",
        message: `Não foi possivel salvar Termo de ${tipoTermoFormatado}`,
        description: messageError,
      });
    }
  };

  const handleResetTermo = () => {
    form.resetFields();
    formServidores.resetFields();
    setFiscalizadoSignature(INITIAL_VALUES_ASSINATURA);
    setDepositarioSignature(INITIAL_VALUES_ASSINATURA);
    setMotivoNaoAssinaturaDepositario([]);
    setMotivoNaoAssinaturaFiscalizado([]);
    setCheckBoxAssinaturaFisica([]);
    setIsAssinadoFisicamente(false);
    setIsTifExisist(false);
    setIsLiberacao(false);
    setIsProprio(true);
    setTifSelected(null);
    setIsValidDocument(false);
    setHasTermoApreensao(false);
    setAnexo([]);

    removerTodosServidoresSelecionados();
    removerTodosItems();
  };

  const handleChangeTermo = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setIsTifExisist(false);
    setHasTermoApreensao(false);
    setIsProprio(true);
    setIsValidDocument(false);
    removerTodosItems();
    setIsLiberacao(value === "LIBERACAO");

    form.resetFields();
    form.setFieldValue("termo", value);
  };

  const hasAssinatura = (
    motivoNaoAssinatura: CheckboxValueType,
    assinatura: ReactSignatureCanvas | null
  ) => {
    if (motivoNaoAssinatura === ASSINATURA_FISICA) {
      return true;
    }

    if (
      (assinatura?.toData().length === 0 || !assinatura) &&
      !motivoNaoAssinatura
    ) {
      return false;
    }

    return true;
  };

  const hasServidorAssinatura = () => {
    if (motivoNaoAssinaturaFiscalizado[0] === ASSINATURA_FISICA) return true;

    const isSignatureServEmpty = servidores.some((serv) => !serv.assinatura);

    return !isSignatureServEmpty;
  };

  const handleSearchTermoApreensao = async (numTermoApreensao: string) => {
    if (!form.getFieldValue("termo")) return;

    removerTodosItems();

    message.loading({
      content: "Buscando Termo de Apreensão...",
      key: "loading",
    });
    setIsLoading(true);

    const { termo: termoApreensao, message: messageResponse } =
      await getTermoApreensao(numTermoApreensao.trim());

    setIsLoading(false);
    message.destroy("loading");

    if (!termoApreensao) {
      openNotification({
        type: "info",
        message: "Não foi possivel buscar Termo de Apreensão",
        description: messageResponse,
      });
      setHasTermoApreensao(false);
      setIsProprio(true);
      return;
    }

    termoApreensao.itens.forEach((item, ind) =>
      adicionarItem({
        ...item,
        key: ind,
        dataValidade: item.dataValidade?.includes("/")
          ? item.dataValidade
          : moment(item.dataValidade).format("DD/MM/YYYY"),
      })
    );

    setTermoApreensaoSelected(termoApreensao);
    form.setFieldsValue({
      numTIFfromApreensao: termoApreensao.tif?.numero,
    });

    if (termoApreensao.cpfCnpjDepositario) {
      setIsValidDocument(true);
    }

    setMunicipioDepositarioOptions(
      municipiosBrasil.filter(
        (municipio) =>
          municipio.uf.id === termoApreensao.municipioDepositario?.uf.id
      )
    );

    setIsProprio(!termoApreensao.cpfCnpjDepositario);

    setHasTermoApreensao(true);

    setFiscalizadoSignature({
      assinatura: null,
      nomeAssinante: termoApreensao.nomeAssinanteFiscalizado,
      funcaoAssinante: termoApreensao.funcaoAssinanteFiscalizado,
      cpfAssinante: termoApreensao.cpfAssinanteFiscalizado,
    });

    setDepositarioSignature({
      assinatura: null,
      nomeAssinante: termoApreensao.nomeAssinanteDepositario,
      funcaoAssinante: termoApreensao.funcaoAssinanteDepositario,
      cpfAssinante: termoApreensao.cpfAssinanteDepositario,
    });
  };

  const handleChangeNumTermoApreensao = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;

    form.resetFields([
      "numTIF",
      "nomeFiscalizado",
      "cpfCnpjFiscalizado",
      "ufFiscalizadoId",
      "municipioFiscalizadoId",
      "rgFiscalizado",
      "renasemFiscalizado",
      "enderecoFiscalizado",
      "cepFiscalizado",
      "telefoneFiscalizado",
      "emailFiscalizado",
      "municipioFiscalizacaoId",
      "depositario",
      "cpfCnpjDepositario",
      "nomeDepositario",
      "ufDepositarioId",
      "municipioDepositarioId",
      "rgDepositario",
      "renasemDepositario",
      "enderecoDepositario",
      "cepDepositario",
      "telefoneDepositario",
      "emailDepositario",
      "atuadoDepositario",
    ]);

    form.setFieldsValue({
      dataTIF: moment(),
      numTermoApreensao: numTermoMask(value),
    });

    setIsProprio(true);
    setHasTermoApreensao(false);
    setIsTifExisist(false);
    // removerTodosItems();
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (e.key === "Enter") {
      document.getElementById(id)?.focus();
    }
  };

  const modalConfirm = () => {
    confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        handleResetTermo();
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  useEffect(() => {
    document.title = "Novo Termo de Apreensão/Liberação | SISDEV-mobile";
    removerTodosServidoresSelecionados();
    removerTodosItems();
    //eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {contextHolder}
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Termo de Apreensão/ Liberação
      </Typography.Title>
      <Divider />

      <Form
        initialValues={{ dataTIF: moment(), termo: "APREENSAO" }}
        id="form"
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "200px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        form={form}
        onFinish={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onFinishFailed={messageSubmitFailed}
      >
        <Form.Item label="Termo" name="termo" rules={[{ required: true }]}>
          <Radio.Group onChange={handleChangeTermo}>
            <Radio value="APREENSAO">Apreensão</Radio>
            <Radio value="LIBERACAO">Liberação</Radio>
          </Radio.Group>
        </Form.Item>

        {isLiberacao && (
          <>
            <Form.Item
              label="N° Termo de Apreensao"
              name="numTermoApreensao"
              rules={[{ required: isLiberacao }]}
            >
              <Input.Search
                id="numTermoApreensao"
                enterButton="Buscar"
                placeholder="Digite o N° do Termo de Apreensão"
                maxLength={15}
                onSearch={(numTermo) =>
                  handleSearchTermoApreensao(numTermo.trim())
                }
                loading={isLoading}
                onChange={handleChangeNumTermoApreensao}
                onKeyDown={(e) => handleKeyPress(e, "numTermo")}
              />
            </Form.Item>

            <Form.Item label="N° do TIF" name={"numTIFfromApreensao"}>
              <Input disabled />
            </Form.Item>
          </>
        )}

        <FiscalizadoRegistroTAL
          form={form}
          isTifExisist={isTifExisist}
          setFiscalizadoSignature={setFiscalizadoSignature}
          setIsTifExisist={setIsTifExisist}
          setTifSelected={setTifSelected}
          isLiberacaoFound={isLiberacao && !hasTermoApreensao}
        />

        <Divider />

        <Typography.Title level={3}>Depositário</Typography.Title>
        <Form.Item label="Tipo" name="depositario" rules={[{ required: true }]}>
          <Radio.Group onChange={handleChangeTipoDepositario}>
            <Radio value="PROPRIO">Próprio</Radio>
            <Radio value="TERCEIRO">Terceiro</Radio>
          </Radio.Group>
        </Form.Item>

        {!isProprio && (
          <RegistroDepositarioTAL
            isTifExisist={isTifExisist}
            isProprio={isProprio}
            formApreensao={form}
            depositarioSignature={depositarioSignature}
            setDepositarioSignature={setDepositarioSignature}
            isValidDocument={isValidDocument}
            setIsValidDocument={setIsValidDocument}
            municipioDepositarioOptions={municipioDepositarioOptions}
            setMunicipioDepositarioOptions={setMunicipioDepositarioOptions}
          />
        )}

        <Divider />

        <DadosFiscalizacaoTAL isTifExisting={isTifExisist} />

        <Divider />

        <UploadFilesForm anexos={anexo} setAnexos={setAnexo} />
        <Divider />

        <Form.Item label="Amparo Legal" name="amparoLegal">
          <Input.TextArea maxLength={5000} showCount rows={4} />
        </Form.Item>

        <Form.Item label="Providências" name="providencias">
          <Input.TextArea maxLength={5000} showCount rows={4} />
        </Form.Item>
      </Form>

      <ItensTAL isLiberacao={isLiberacao} />

      <Divider />

      <TabelaServidoresForm form={formServidores} />

      <AssinaturaTAL
        ativarModalInformarNumTermo={() => setIsOpenModalInformarNumTermo(true)}
        formApreensao={form}
        servidores={servidores}
        setServidores={setServidores}
        fiscalizadoSignature={fiscalizadoSignature}
        setFiscalizadoSignature={setFiscalizadoSignature}
        motivoNaoAssinaturaFiscalizado={motivoNaoAssinaturaFiscalizado}
        setMotivoNaoAssinaturaFiscalizado={setMotivoNaoAssinaturaFiscalizado}
        depositarioSignature={depositarioSignature}
        setDepositarioSignature={setDepositarioSignature}
        motivoNaoAssinaturaDepositario={motivoNaoAssinaturaDepositario}
        setMotivoNaoAssinaturaDepositario={setMotivoNaoAssinaturaDepositario}
        isProprio={isProprio}
        checkBoxAssinaturaFisica={checkBoxAssinaturaFisica}
        setCheckBoxAssinaturaFisica={setCheckBoxAssinaturaFisica}
        isAssinadoFisicamente={isAssinadoFisicamente}
        setIsAssinadoFisicamente={setIsAssinadoFisicamente}
      />

      <ButtonFooterContainer>
        <Button form="form" onClick={modalConfirm} disabled={isSaving}>
          Cancelar
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          form="form"
          disabled={isSaving}
        >
          Salvar
        </Button>

        {!checkDevice() && (
          <PreviaTAL
            formApreensao={form}
            isTifExisist={isTifExisist}
            tifSelected={tifSelected}
            fiscalizadoSignature={fiscalizadoSignature}
            depositarioSignature={depositarioSignature}
            anexos={anexo}
            termoApreensaoSelected={termoApreensaoSelected}
          />
        )}
      </ButtonFooterContainer>

      <ModalInformarNumeroTermo
        isOpen={isOpenModalInformarNumTermo}
        onClose={() => setIsOpenModalInformarNumTermo(false)}
        numTermo={numTermo}
        setNumTermo={setNumTermo}
      />
    </Fragment>
  );
}
