import { ITermoVistoria } from "@interfaces/ITermoVistoria";

export function migrarTermoAntigo(termoAntigo: any): ITermoVistoria {
  const respostaAtualizada = termoAntigo.respostas.map((resposta: any) => {
    return {
      order: resposta.order,
      pergunta: resposta.pergunta,
      descricao: resposta.descricao,
      alternativas: resposta.alternativa
        ? [
            {
              alternativa: resposta.alternativa,
            },
          ]
        : [],
      campos: resposta.campos,
    };
  });

  return { ...termoAntigo, respostas: respostaAtualizada };
}
