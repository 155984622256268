import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { DatePicker, Form, FormInstance, Input } from "antd";
import styles from "../../style.module.css";

export function Campo({
  question,
  form,
}: {
  question: IPerguntaAtividade;
  form: FormInstance;
}) {
  return (
    <>
      {question.pergunta.campos.map((campo, ind) => {
        const name = `listaPerguntas.${question.pergunta.id}.campos.${ind}.resposta`;
        return (
          <div
            key={ind}
            style={{
              display: "flex",
              gap: 5,
              flexDirection: "column",
            }}
          >
            <label className={styles["label-campo"]}>{campo.nome}</label>
            {campo.formatoResposta === "DATE" && (
              <Form.Item
                name={name}
                rules={[
                  { required: true, message: "Por favor, insira uma resposta" },
                ]}
                noStyle={false}
              >
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
            )}

            {campo.formatoResposta === "NUMBER" && (
              <Form.Item
                name={name}
                rules={[
                  { required: true, message: "Por favor, insira uma resposta" },
                ]}
                noStyle={false}
              >
                <Input
                  style={{ maxWidth: 400 }}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos, exceto vírgula
                    inputValue = inputValue.replace(/[^0-9,]/g, "");

                    // Garante que tenha no máximo uma vírgula
                    const parts = inputValue.split(",");
                    if (parts.length > 2) {
                      inputValue = parts[0] + "," + parts.slice(1).join("");
                    }

                    form.setFieldValue(name, inputValue);
                  }}
                  maxLength={255}
                />
              </Form.Item>
            )}

            {(!campo.formatoResposta || campo.formatoResposta === "TEXTO") && (
              <Form.Item
                name={name}
                rules={[
                  {
                    required: true,
                    message: "Por favor, insira uma resposta",
                  },
                ]}
                noStyle={false}
              >
                <Input style={{ maxWidth: 400 }} maxLength={255} />
              </Form.Item>
            )}
          </div>
        );
      })}
    </>
  );
}
