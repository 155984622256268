import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { Checkbox, Form } from "antd";

export function MultiplaEscolha({
  question,
  onAnswerQuestion,
}: {
  question: IPerguntaTermoVistoria;
  onAnswerQuestion: (
    respostas: number[],
    question: IPerguntaTermoVistoria
  ) => void;
}) {
  return (
    <Form.Item
      label="Multipla escolha"
      name={`listaPerguntas.${question.pergunta.id}.respostaMultiplaEscolha`}
      rules={[{ required: true }]}
    >
      <Checkbox.Group
        onChange={(values) => {
          const respostas = values as number[];
          onAnswerQuestion(respostas, question);
        }}
      >
        {question.pergunta.alternativas.map((option) => {
          if (option.ativo) {
            return (
              <Checkbox value={option.id} key={option.id}>
                {option.nome}
              </Checkbox>
            );
          }

          return null;
        })}
      </Checkbox.Group>
    </Form.Item>
  );
}
