import { DeleteOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { formatMessageError } from "@functions/formatMessageError";
import { useArmadilha } from "@hooks/useArmadilha";
import { ISubmitArmadilha } from "@interfaces/ISubmitArmadilha";
import { apiService } from "@services/api";
import { Button, List, message, Modal, Tooltip } from "antd";
import { useState } from "react";

export function ItemListaToSyncArmadilha({
  item,
  offlineId,
}: {
  item: ISubmitArmadilha;
  offlineId: number;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { removerArmadilhaToSync } = useArmadilha();
  const [modal, contextHolderModal] = Modal.useModal();

  const handleSubmitArmadilha = async () => {
    try {
      setIsLoading(true);
      message.loading({ content: "Enviando armadilha...", key: "loading" });
      await apiService.armadilha.enviar(item);

      removerArmadilhaToSync(offlineId);
      message.destroy("loading");
      setIsLoading(false);
      openNotification({
        type: "success",
        message: "Armadilha enviada com sucesso!",
      });
    } catch (error) {
      message.destroy("loading");
      setIsLoading(false);
      console.log(error);
      const messageError = formatMessageError(error);

      openNotification({
        type: "error",
        message: "Não foi possivel enviar armadilha",
        description: messageError,
      });
    }
  };

  const handleRemoveArmadilha = () => {
    modal.confirm({
      content: "Tem certeza que deseja remover a armadilha?",
      onOk: () => {
        removerArmadilhaToSync(offlineId);
      },
    });
  };

  return (
    <List.Item>
      {contextHolderModal}
      <List.Item.Meta
        title={<span className="titulo-card">Armadilha {item.codigo}</span>}
        description={
          <span className="subtitulo-card">{item.municipio?.nome}</span>
        }
      />

      <div style={{ display: "flex", gap: 10 }}>
        <Button
          disabled={!navigator.onLine || isLoading}
          onClick={handleSubmitArmadilha}
        >
          {isLoading ? "Enviando..." : "Enviar"}
        </Button>

        <Tooltip title="Remover">
          <Button onClick={handleRemoveArmadilha} danger>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </div>
    </List.Item>
  );
}
