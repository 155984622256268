import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { Form, Radio } from "antd";

export function Alternativa({
  question,
  onAnswerQuestion,
}: {
  question: IPerguntaTermoVistoria;
  onAnswerQuestion: (
    respostas: number[],
    question: IPerguntaTermoVistoria
  ) => void;
}) {
  return (
    <Form.Item
      label="Alternativa"
      name={`listaPerguntas.${question.pergunta.id}.respostaAlternativa`}
      rules={[{ required: true }]}
    >
      <Radio.Group
        onChange={(e) => {
          const { value } = e.target;
          onAnswerQuestion([Number(value)], question);
        }}
      >
        {question.pergunta.alternativas.map((alt) => {
          if (alt.ativo) {
            return (
              <Radio value={alt.id} key={alt.id}>
                {alt.nome}
              </Radio>
            );
          }

          return null;
        })}
      </Radio.Group>
    </Form.Item>
  );
}
