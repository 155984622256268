import { openNotification } from "@components/Notifications";
import { useArmadilha } from "@hooks/useArmadilha";
import { useCategoriaSemente } from "@hooks/useCategoriaSemente";
import { useEspecie } from "@hooks/useEspecie";
import { useEstadioFenologico } from "@hooks/useEstadioFenologico";
import { useMateriaisColetados } from "@hooks/useMateriaisColetados";
import { apiService } from "@services/api";
import { useTAL } from "pages/termoApreensaoLiberacao/hooks/useTAL";
import { useTCA } from "pages/termoColeta/hooks/useTCA";
import { useState } from "react";
import { DownloadingDadosTermo } from "../DownloadingDadosTermo";

export function SyncDadosTCA() {
  const { syncCategorias, removeCategorias } = useCategoriaSemente();
  const { syncEstadios, removeEstadios } = useEstadioFenologico();
  const { syncMateriaisColetados, removeMateriaisColetados, isMaterialSync } =
    useMateriaisColetados();
  const { removeEspecies, syncEspecies } = useEspecie({
    isTCA: true,
  });
  const {
    termoColetaPersistedAtItems,
    adicionarPersistedAtTermoColeta,
    removerPersistedAtTermoColeta,
  } = useTCA();
  const { persistedAtItemsSync } = useArmadilha();
  const { termoApreensaoLiberacaoPersistedAtItems } = useTAL();
  const [isDownloading, setIsDownloading] = useState(false);

  const termoColetaDownloadDados = async (action: string) => {
    const dateTimeExpire = Date.now();

    if (action === "adicionar" || action === "atualizar") {
      if (!navigator.onLine) {
        openNotification({
          type: "error",
          message: "Erro ao baixar informações",
          description:
            "Sem accesso a internet para baixar dados. Tente novamente mais tarde.",
        });
        return;
      }

      setIsDownloading(true);

      await Promise.allSettled([
        apiService.estadiosFenologicos(),
        apiService.materialColetado(),
        apiService.especie({ isAnaliseSemente: true }),
        apiService.categorias(),
      ]).then((res) => {
        const requestRejected = res.filter(
          (data) => data.status === "rejected"
        );

        if (requestRejected.length > 0) {
          openNotification({
            type: "error",
            message: `Erro ao ${
              action === "adicionar" ? "baixar" : "atualizar"
            } informações`,
            description:
              "Tente novamente, se o erro persistir entre em contato com o suporte",
          });
          return;
        }

        if (res[0].status === "fulfilled") {
          syncEstadios(res[0].value, dateTimeExpire);
        }

        if (res[1].status === "fulfilled") {
          syncMateriaisColetados(res[1].value, dateTimeExpire);
        }

        if (res[2].status === "fulfilled") {
          syncEspecies(res[2].value, dateTimeExpire);
        }

        if (res[3].status === "fulfilled") {
          syncCategorias(res[3].value, dateTimeExpire);
        }

        adicionarPersistedAtTermoColeta(dateTimeExpire);
        openNotification({
          type: "success",
          message: `Dados para o Termo de Coleta ${
            action === "adicionar" ? "sincronizados" : "atualizados"
          } com Sucesso!`,
        });
      });

      setIsDownloading(false);
    } else {
      removeEstadios();
      removeMateriaisColetados();
      termoApreensaoLiberacaoPersistedAtItems === 0 &&
        persistedAtItemsSync &&
        removeEspecies();
      removeCategorias();
      removerPersistedAtTermoColeta();

      openNotification({
        type: "success",
        message:
          "Dados sincronizados do Termo de Coleta removidos com Sucesso!",
      });
    }
  };

  return (
    <DownloadingDadosTermo
      title="Dados para o TCA:"
      dadosTermoDownloading={isDownloading}
      handleChange={termoColetaDownloadDados}
      isDataDownloaded={termoColetaPersistedAtItems > 0}
      timeExpireData={termoColetaPersistedAtItems}
      tipoTermo="TCA"
      listaDadosSync={[
        "Materiais coletados",
        "Estadios Fenologicos",
        "Espécies",
        "Categorias",
      ]}
    />
  );
}
