import { IArmadilha } from "@interfaces/IArmadilha";
import { ISubmitArmadilha } from "@interfaces/ISubmitArmadilha";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IArmadilhaToSync extends ISubmitArmadilha {
  offlineId: number;
}

interface IArmadilhaSlice {
  items: {
    municipioId: number;
    armadilhas: IArmadilha[];
  }[];
  armadilhasToSync: IArmadilhaToSync[];
  persistedAt: number;
  persistedAtItemsSync: number; //armazena o timestamp da última sincronização dos itens necessarios para o cadastro de armadilha offline
}

const initialState: IArmadilhaSlice = {
  items: [],
  persistedAt: 0,
  armadilhasToSync: [],
  persistedAtItemsSync: 0,
};

const armadilhaSlice = createSlice({
  name: "ARMADILHA",
  initialState,
  reducers: {
    syncArmadilhaByMunicipio: (
      state,
      action: PayloadAction<{
        armadilhas: IArmadilha[];
        municipioId: number;
        dateTimeCreate: number;
      }>
    ) => {
      const { armadilhas, municipioId, dateTimeCreate } = action.payload;

      state.items.push({
        municipioId,
        armadilhas,
      });

      state.persistedAt = dateTimeCreate;
    },

    removeSyncArmadilhaByMunicipio: (
      state,
      action: PayloadAction<{ municipioId: number }>
    ) => {
      const { municipioId } = action.payload;

      state.items = state.items.filter(
        (item) => item.municipioId !== municipioId
      );

      if (state.items.length === 0) {
        state.persistedAt = 0;
      }
    },

    addArmadilhaToSync: (
      state,
      action: PayloadAction<{ armadilha: ISubmitArmadilha }>
    ) => {
      const { armadilha } = action.payload;
      state.armadilhasToSync.push({ ...armadilha, offlineId: Date.now() });
    },

    removeArmadilhaToSync: (
      state,
      action: PayloadAction<{ offlineId: number }>
    ) => {
      const { offlineId } = action.payload;

      state.armadilhasToSync = state.armadilhasToSync.filter(
        (armadilha) => armadilha.offlineId !== offlineId
      );
    },

    updatePersistedAtItemsSync: (state, action) => {
      const { persistedAtItemsSync } = action.payload;
      state.persistedAtItemsSync = persistedAtItemsSync;
    },
  },
});

export default armadilhaSlice.reducer;

export const getArmadilha = (state: RootState) => state.armadilha.items;

export const getArmadilhasToSync = (state: RootState) =>
  state.armadilha.armadilhasToSync || []; //Adicionado pois estado do reducer nao esta atualizando automaticamente

export const getPersistedAtItemsToArmadilha = (state: RootState) =>
  state.armadilha.persistedAtItemsSync;
