import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { masks } from "@functions/mascaras";
import { IAmostra } from "@interfaces/IAmostra";
import { apiService } from "@services/api";
import { AssinaturaContainer } from "@styles/AssinaturaContainer";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  Form,
  Input,
  Radio,
  Result,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { DECLARACOES, FITOSSANITARIA, OFICIAL } from "../../constants";

interface IColumsFitossanitaria {
  latitude: string;
  longitude: string;
  plantaVoluntaria: string;
  plantioExcepcional: string;
  dataPlantio: string;
  cultivar: string;
  materialColetado: string;
  idadeEstadioFenologico: string;
  qtdColetada: number;
}

export function ViewTermoColeta() {
  const navigate = useNavigate();
  const { termoId } = useParams();
  const { cpfCnpjMask, coordMask } = masks;
  const [oficialAmostraInGrid, setOficialAmostraInGrid] = useState<any[]>([]);
  const [fitoAmostraInGrid, setFitoAmostraInGrid] = useState<
    IColumsFitossanitaria[]
  >([]);

  const [declaracoesArray, setDeclaracoesArray] = useState<string[]>([]);

  const { data, status, remove } = useQuery({
    queryFn: () => apiService.termoColeta.byId(parseInt(termoId || "")),
    onSuccess: (data) => {
      const aux: string[] = [];
      if (data.dispensadoColetaDuplicata) {
        aux.push("dispensado");
      }

      if (data.recebidoColetaDuplicata) {
        aux.push("recebido");
      }

      setDeclaracoesArray(aux);
      if (data.tipoColeta === FITOSSANITARIA) {
        const auxFito = data.itens.map((amostra: IAmostra) => {
          return {
            key: amostra.identificacao,
            latitude: `${coordMask(amostra.latGrau.toString())}°${coordMask(
              amostra.latMin.toString()
            )}'${coordMask(amostra.latSeg.toString())}"`,
            longitude: `${coordMask(amostra.longGrau.toString())}°${coordMask(
              amostra.longMin.toString()
            )}'${coordMask(amostra.longSeg.toString())}"`,
            plantaVoluntaria: amostra.plantaVoluntaria ? "SIM" : "NÃO",
            plantioExcepcional: amostra.plantioExcepcional ? "SIM" : "NÃO",
            dataPlantio: amostra.dataPlantio,
            cultivar: amostra.cultivar ? amostra.cultivar.nome : "",
            materialColetado: amostra.partePlanta
              ? amostra.partePlanta.nome
              : "",
            idadeEstadioFenologico: amostra.estadio ? amostra.estadio.nome : "",
            qtdColetada: amostra.qtdEmbalagens,
          };
        });

        setFitoAmostraInGrid(auxFito);
      } else if (data.tipoColeta === OFICIAL) {
        const auxOficial = data.itens.map((amostra: IAmostra) => {
          return {
            key: amostra.identificacao,
            cultivar: amostra.cultivar ? amostra.cultivar.nome : "",
            categoria: amostra.categoria ? amostra.categoria.nome : "",
            revestimento: amostra.revestimento,
            lote: amostra.lote,
            qtdColetada: amostra.qtdEmbalagens,
            peso: amostra.pesoEmbalagem,
            valor: amostra.valorEmbalagem,
            validadeTeste: amostra.validadeTesteGerminacao,
            tratamento: amostra.tratamento,
            pureza: amostra.pureza,
            germinacao: amostra.germinacao,
            tetrazolio: amostra.tetrazolio === 0 ? "" : amostra.tetrazolio,
            sementesDuras: amostra.sementesDura,
            valorOutrosFatores: amostra.valorOutrosFatores,
          };
        });

        setOficialAmostraInGrid(auxOficial);
      } else {
        setFitoAmostraInGrid([]);
        setOficialAmostraInGrid([]);
      }
    },
  });

  const columnsFitossanitaria: ColumnsType<IColumsFitossanitaria> = [
    {
      title: "Latitude",
      dataIndex: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
    },
    {
      title: "Planta Voluntaria",
      dataIndex: "plantaVoluntaria",
    },
    {
      title: "Plantio Excepcional",
      dataIndex: "plantioExcepcional",
    },
    {
      title: "Data Plantio",
      dataIndex: "dataPlantio",
      // render: (dataPlantio: string) => <p>{dataPlantio}</p>,
    },
    {
      title: "Cultivar",
      dataIndex: "cultivar",
    },
    {
      title: "Material Coletado",
      dataIndex: "materialColetado",
    },
    {
      title: "Idade/Estadio Fenológico",
      dataIndex: "idadeEstadioFenologico",
    },
    {
      title: "Qtd. Coletada",
      dataIndex: "qtdColetada",
    },
    {
      title: "Ações",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button danger disabled>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const colunmsOficial: ColumnsType<any> = [
    {
      title: "Cultivar",
      dataIndex: "cultivar",
    },
    {
      title: "Categoria",
      dataIndex: "categoria",
    },
    {
      title: "Revestimento",
      dataIndex: "revestimento",
    },
    {
      title: "Lote",
      dataIndex: "lote",
    },
    {
      title: "Representatividade do lote",
      children: [
        {
          title: "N° Embalagens",
          dataIndex: "qtdColetada",
        },
        {
          title: "Peso Liquido(Kg)",
          dataIndex: "peso",
        },
      ],
    },
    {
      title: "Valor Unitário",
      dataIndex: "valor",
    },
    {
      title: "Val. Teste Germ.",
      dataIndex: "validadeTeste",
    },
    {
      title: "Tratamento",
      dataIndex: "tratamento",
    },
    {
      title: "Garantias expressas na embalagem",
      children: [
        {
          title: "Pureza(%)",
          dataIndex: "pureza",
        },
        {
          title: "Germinação(%)",
          dataIndex: "germinacao",
        },
        {
          title: "Tetrazólio(%)",
          dataIndex: "tetrazolio",
        },
        {
          title: "Sementes Duras(%)",
          dataIndex: "sementesDuras",
        },
        {
          title: "Outros Fatores",
          dataIndex: "valorOutrosFatores",
        },
      ],
    },

    {
      title: "Ações",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button danger disabled>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handleBack = () => {
    remove();
    navigate("/termo-coleta/listagem");
  };

  useEffect(() => {
    document.title = "Visualização Termo de Coleta | SISDEV-mobile";
  }, []);

  if (status === "error") {
    return (
      <Fragment>
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              minHeight: "20vh",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Result
              status="error"
              title="Erro ao visualizar Termo de Coleta"
              subTitle="Por favor, verifique a conexão com a internet, se o erro persistir entre em contato com o suporte."
              extra={
                <Button onClick={() => navigate("/termo-coleta/listagem")}>
                  Voltar
                </Button>
              }
            />
          </div>
        </div>
      </Fragment>
    );
  } else if (status === "idle" || status === "loading") {
    return (
      <Fragment>
        <div
          style={{
            minHeight: "80vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Typography.Title level={2} style={{ textAlign: "center" }}>
          Termo de Coleta de Amostras
        </Typography.Title>
        <Divider />
        <Form
          labelWrap
          labelAlign="left"
          labelCol={{ flex: "200px", span: 20 }}
          wrapperCol={{ flex: 1, span: 14 }}
        >
          <Form.Item label="N° Termo de Coleta">
            <Input value={data.termoColetaAmostra} />
          </Form.Item>

          <Typography.Title level={3}>Amostrador</Typography.Title>

          <Form.Item label="Matrícula">
            <Input value={data.renasemMatriculaAmostrador} />
          </Form.Item>

          <Form.Item label="Nome">
            <Input value={data.nomeAmostrador} />
          </Form.Item>

          <Divider />

          <Typography.Title level={3}>Origem</Typography.Title>
          <Form.Item label="Origem">
            <Radio.Group
              value={data.propriedade ? "PROPRIEDADE" : "ESTABELECIMENTO"}
            >
              <Radio value="PROPRIEDADE">Propriedade</Radio>
              <Radio value="ESTABELECIMENTO">Estabelecimento</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="CPF / CNPJ">
            <Input value={cpfCnpjMask(data.produtor?.cpfCnpj || "")} />
          </Form.Item>

          <Form.Item label="Nome">
            <Input value={data.produtor?.nome} />
          </Form.Item>

          <Form.Item
            label={data.propriedade ? "Propriedade" : "Estabelecimento"}
          >
            <Input
              value={
                data.propriedade
                  ? data.propriedade.nome
                  : data.produtor
                    ? data.produtor.nome
                    : ""
              }
            />
          </Form.Item>

          <Typography.Title level={3}>Local</Typography.Title>
          <Form.Item label="Municipio de Coleta">
            <Input
              value={`${data.municipioColeta?.nome}-${data.municipioColeta?.uf.sigla}`}
            />
          </Form.Item>
          <Divider />

          <Typography.Title level={3}>Dados Gerais</Typography.Title>

          <Form.Item label="Destino">
            <Input value={data.destino} />
          </Form.Item>
          {data.destino === "EXTERNO" && (
            <Form.Item label="Nome do Laboratório">
              <Input style={{ minWidth: "190px" }} />
            </Form.Item>
          )}

          <Form.Item label="N° do Termo de Fiscalização">
            <Input
              maxLength={15}
              style={{ minWidth: "190px" }}
              value={data.termoFiscalizacao}
            />
          </Form.Item>

          <Form.Item label="Data da Coleta">
            <Input value={data.dataColeta} />
          </Form.Item>

          <Form.Item label="Especie">
            <Input value={data.especie ? data.especie.nomeComum : ""} />
          </Form.Item>

          <Form.Item label="Safra">
            <Input value={data.safra} />
          </Form.Item>
          <Form.Item label="Renasem Origem Sementes">
            <Input value={data.renasemOrigemSementes} />
          </Form.Item>
          <Form.Item label="Pais de origem">
            <Input value={data.paisOrigem} />
          </Form.Item>
          <Form.Item label="Tipo de amostras">
            <Input value={data.tipoColeta} />
          </Form.Item>

          {data.tipoColeta !== FITOSSANITARIA && (
            <Form.Item label="Declarações">
              <Checkbox.Group options={DECLARACOES} value={declaracoesArray} />
            </Form.Item>
          )}
          <Form.Item
            label={
              data.tipoColeta === "OFICIAL"
                ? "Dados do Tratamento das Sementes"
                : "Dados de aplicação de Agrotoxicos (Produtos e número de aplicações)"
            }
          >
            <Input.TextArea
              rows={4}
              showCount
              maxLength={10000}
              value={data.dadosTratamentoSementes}
            />
          </Form.Item>
          <Form.Item label="Observações">
            <Input.TextArea
              rows={4}
              showCount
              maxLength={10000}
              value={data.observacao}
            />
          </Form.Item>

          <Typography.Title level={4}>Amostra(s)</Typography.Title>
          {data.tipoColeta === FITOSSANITARIA ? (
            <Table
              bordered
              locale={{
                emptyText: (
                  <Empty
                    description={"Sem dados"}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
              }}
              dataSource={fitoAmostraInGrid}
              columns={columnsFitossanitaria}
              style={{
                overflowX: "auto",
              }}
            />
          ) : (
            <Table
              bordered
              locale={{
                emptyText: (
                  <Empty
                    description={"Sem dados"}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                ),
              }}
              dataSource={oficialAmostraInGrid}
              columns={colunmsOficial}
              style={{
                overflowX: "auto",
              }}
            />
          )}

          <div
            style={{
              display: "flex",
              padding: 15,
            }}
          >
            <Checkbox.Group
              style={{
                display: "flex",
                flexDirection: "row",
                width: 250,
              }}
              value={[data.statusAssinatura]}
            >
              <Checkbox value="ASSINADO_FISICAMENTE">
                Assinatura Física
              </Checkbox>
            </Checkbox.Group>
          </div>

          <AssinaturaContainer>
            <div className="servidor-signature-form">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="container-img-assinatura">
                  {data.assinaturaAmostrador && (
                    <img
                      src={data.assinaturaAmostrador}
                      alt={`assinatura Amostrador`}
                      style={{
                        width: "90%",
                        height: "90%",
                      }}
                    />
                  )}
                </div>

                <Typography style={{ textAlign: "center" }}>
                  Assinatura Amostrador
                </Typography>
              </div>
            </div>

            <div className="servidor-signature-form">
              <div>
                <div className="container-img-assinatura">
                  {data.statusAssinatura === "ASSINADO_DIGITALMENTE" && (
                    <img
                      style={{ width: "90%", height: "90%" }}
                      src={data.assinaturaFiscalizado}
                      alt="Assinatura fiscalizado"
                    />
                  )}
                </div>

                <Typography style={{ textAlign: "center" }}>
                  Assinatura Fiscalizado
                </Typography>
                <div
                  style={{
                    paddingTop: 5,
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <Checkbox.Group
                    value={[data.statusAssinatura]}
                    style={{
                      display: "flex",
                    }}
                  >
                    <Checkbox value="AUSENTE">Ausente</Checkbox>
                    <br />
                    <Checkbox value="RECUSADO">Recusou Assinar</Checkbox>
                  </Checkbox.Group>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    maxWidth: 600,
                  }}
                >
                  <div>
                    <span>Nome:</span>
                    <Input value={data.nomeAssinante} />
                  </div>

                  <div>
                    <span>CPF:</span>
                    <Input value={cpfCnpjMask(data.cpfAssinante || "")} />
                  </div>

                  <div>
                    <span>Função:</span>
                    <Input value={data.funcaoAssinante} />
                  </div>
                </div>
              </div>
            </div>
          </AssinaturaContainer>
        </Form>

        <ButtonFooterContainer>
          <Button onClick={handleBack}>Voltar</Button>
        </ButtonFooterContainer>
      </Fragment>
    );
  }
}
