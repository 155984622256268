import { IContentTermoVistoria } from "@interfaces/IListagemTV";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IFiltroTermoVistoriaSliceProps {
  items: {
    filtros: string[];
    params: string;
    termos: IContentTermoVistoria[];
    numberPages: number;
  }[];
}

const initialState: IFiltroTermoVistoriaSliceProps = {
  items: [],
};

const filtroTermoVistoriaSlice = createSlice({
  name: "FILTRO_TERMO_VISTORIA",
  initialState,
  reducers: {
    addFiltroTermoVistoria: (state, action) => {
      const { termos } = action.payload;

      state.items = termos;
    },
    removeFiltroTermoVistoria: (state, action) => {
      state.items = [];
    },
  },
});

export default filtroTermoVistoriaSlice.reducer;

export const getFiltroTermoVistoria = (state: RootState) =>
  state.filtroTermoVistoria.items;
