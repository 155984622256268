import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useAtividadeInterditada } from "@hooks/useAtividadeInterditada";
import { IFormAtividades } from "@interfaces/IFormAtividades";
import { IPergunta } from "@interfaces/IPergunta";
import { IPerguntaAtividade } from "@interfaces/IPerguntaAtividade";
import { IRespostaAtividade } from "@interfaces/IRespostaAtividade";
import { ITabelaAtividadeTIF } from "@interfaces/ITabelaAtividadeTIF";
import { Button, Empty, Form, Modal, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useTIF } from "pages/tif/hooks/useTIF";
import { useState } from "react";
import { ordenarPerguntasAtividade } from "../../../../functions/ordenarPerguntasAtividade";
import { ModalEditarAtividade } from "../ModalEditarAtividade";
import { ModalPerguntaAtividade } from "../ModalPerguntaAtividade";

export function TabelaAtividadeTIF({ isEdit }: { isEdit?: boolean }) {
  const [formEditarAtividade] = Form.useForm();
  const [formPerguntas] = Form.useForm();
  const [modal, contextholderModal] = Modal.useModal();
  const { atividadesTif, removerAtividade, respostasAtividades } = useTIF();
  const { listaAtividadesInterditadas } = useAtividadeInterditada();
  const [atividadeSelected, setAtividadeSelected] = useState<IFormAtividades>();
  const [isNotificationEdit, setIsNotificationEdit] = useState(false);
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false);
  const [isOpenModalQuestion, setIsOpenModalQuestion] = useState(false);
  const [perguntasNumeradas, setPerguntasNumeradas] = useState<
    IPerguntaAtividade[]
  >([]);

  //ESTRUTURA DE COLUNAS DA TABELA DE ATIVIDADES
  const columns: ColumnsType<ITabelaAtividadeTIF> = [
    {
      title: "Programa",
      dataIndex: "programa",
      render: (programa: string) => <p>{programa}</p>,
    },
    {
      title: "Atividade",
      dataIndex: "atividade",
      render: (atv: string) => <p>{atv}</p>,
    },
    {
      title: "Especificação",
      dataIndex: "especificacoes",
      render: (especificacoes: string) => <p>{especificacoes.toUpperCase()}</p>,
    },
    {
      title: "Prazo",
      dataIndex: "prazo",
      render: (prazo: string) => (
        <p>{prazo ? moment(prazo).locale("pt-br").format("L") : ""}</p>
      ),
    },
    {
      title: "Ações",
      key: "action",
      align: "center",
      render: (_, record) => {
        const atividadeSelected = atividadesTif.find(
          (atv) => atv.key === record.key
        );

        const isInterditada = listaAtividadesInterditadas.find(
          (atv) =>
            atv.atividade.id === atividadeSelected?.atividade.id &&
            atv.areaAtividade.id === atividadeSelected?.programa.id
        );

        return (
          <Space size="middle">
            <Tooltip title="Responder Questionário">
              <Button
                disabled={
                  (record.perguntas && record.perguntas.length === 0) ||
                  (isEdit && record.perguntas && record.perguntas.length > 0) ||
                  (isEdit && !!isInterditada)
                }
                onClick={(e) => handleOpenModalQuestion(record)}
              >
                <QuestionCircleOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Editar">
              <Button
                onClick={(e) => handleOpenModalEdit(record)}
                disabled={
                  Boolean(record.armadilha) ||
                  (record.perguntas && record.perguntas.length > 0 && isEdit) ||
                  (isEdit && !!isInterditada)
                }
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Excluir">
              <Button
                danger
                onClick={(e) => handleOpenModalDelete(record)}
                disabled={
                  (isEdit && record.perguntas && record.perguntas.length > 0) ||
                  (isEdit && !!isInterditada)
                }
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const handleOpenModalEdit = ({ key }: ITabelaAtividadeTIF) => {
    const atividade = atividadesTif.find((atv) => atv.key === key);

    if (!atividade) return;

    setIsOpenModalEdit(true);
    const especificacoes = atividade.especificacoes
      .split(",")
      .map((value) => value.trim());

    setAtividadeSelected(atividade);

    //PREENCHENDO CAMPOS AUTOMATICAMENTE
    formEditarAtividade.setFieldValue("especificacoes", especificacoes);

    if (atividade.prazo) {
      setIsNotificationEdit(true);
      formEditarAtividade.setFieldValue("prazo", moment(atividade.prazo));
    }
  };

  const handleCloseModalEdit = () => {
    setIsNotificationEdit(false);
    setIsOpenModalEdit(false);
    setAtividadeSelected(undefined);
    formEditarAtividade.resetFields();
  };

  const handleOpenModalQuestion = ({ key }: ITabelaAtividadeTIF) => {
    const atividade = atividadesTif.find((atv) => atv.key === key);

    if (!atividade) return;

    setIsOpenModalQuestion(true);

    const respostas = respostasAtividades.filter((res) => {
      if (atividade.armadilha) {
        if (res.armadilha?.id === atividade.armadilha.id) {
          return res;
        }
        return null;
      } else {
        if (res.atividade.id === atividade.atividade.id) {
          return res;
        }

        return null;
      }
    });

    let perguntasAtv = separarEPreencherArrayPerguntas([], atividade);

    formPerguntas.resetFields();
    //VERIFICANDO SE JA EXISTE RESPOSTAS PARA ESSE QUESTIONARIO
    respostas.forEach((res) => {
      res.campos.forEach((campo, ind) => {
        if (campo.campo.formatoResposta === "DATE") {
          formPerguntas.setFieldValue(
            `listaPerguntas.${res.pergunta.id}.campos.${ind}.resposta`,
            moment(campo.resposta)
          );
          return;
        }

        formPerguntas.setFieldValue(
          `listaPerguntas.${res.pergunta.id}.campos.${ind}.resposta`,
          campo.resposta
        );
      });

      if (res.pergunta.tipoResposta.includes("DESCRITIVA")) {
        formPerguntas.setFieldValue(
          `listaPerguntas.${res.pergunta.id}.descricao`,
          res.pergunta.formatoResposta === "DATE"
            ? moment(res.descricao)
            : res.descricao
        );
      }

      if (res.pergunta.tipoResposta.includes("ALTERNATIVA")) {
        formPerguntas.setFieldValue(
          `listaPerguntas.${res.pergunta.id}.respostaAlternativa`,
          res.alternativas[0]?.alternativa.id
        );
      }

      if (res.pergunta.tipoResposta.includes("MULTIPLA_ESCOLHA")) {
        //ADICIONAR RESPOSTAS DO MULTIPLA ESCOLHA
        formPerguntas.setFieldValue(
          `listaPerguntas.${res.pergunta.id}.respostaMultiplaEscolha`,
          res.alternativas.map((alt) => alt.alternativa.id)
        );
      }
    });

    //VERIFICANDO SE SO POSSUI PERGUNTAS INDEPENDENTES NA LISTAGEM
    if (respostas.length === perguntasAtv.length || respostas.length === 0) {
      setAtividadeSelected(atividade);
      setPerguntasNumeradas(perguntasAtv);
      return;
    }

    let arrayPerg = separarEPreencherArrayPerguntas(perguntasAtv, atividade);
    perguntasAtv = perguntasAtv.concat(arrayPerg);
    let arraySemDuplicados = filtrarPerguntasParaArray(perguntasAtv, []);
    for (let i = arraySemDuplicados.length; i < respostas.length; i++) {
      //VERIFICANDO SE FOI LISTADAS TODAS AS PERGUNTAS
      if (respostas.length === arraySemDuplicados.length) break;
      arrayPerg = separarEPreencherArrayPerguntas(perguntasAtv, atividade);
      perguntasAtv = perguntasAtv.concat(arrayPerg);
      arraySemDuplicados = filtrarPerguntasParaArray(
        perguntasAtv,
        arraySemDuplicados
      );
    }

    setPerguntasNumeradas(arraySemDuplicados);
    setAtividadeSelected(atividade);
  };

  const handleCloseModalQuestion = () => {
    setIsOpenModalQuestion(false);
    setAtividadeSelected(undefined);
  };

  const handleOpenModalDelete = ({
    key,
    atividade: tituloAtividade,
  }: ITabelaAtividadeTIF) => {
    modal.confirm({
      title: `TEM CERTEZA QUE DESEJA REMOVER A ATIVIDADE: ${tituloAtividade} ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        const atividade = atividadesTif.find((atv) => atv.key === key);
        if (!atividade) return;
        removerAtividade(key, atividade);
      },
    });
  };

  const filtrarPerguntasParaArray = (
    perguntasSelected: IPerguntaAtividade[],
    arraySemDuplicados: IPerguntaAtividade[]
  ) => {
    perguntasSelected.forEach((perg) => {
      if (!arraySemDuplicados.some((aux) => aux.id === perg.id)) {
        arraySemDuplicados.push(perg);
      }
    });

    return ordenarPerguntasAtividade(arraySemDuplicados);
  };

  const separarEPreencherArrayPerguntas = (
    perguntasPais: IPerguntaAtividade[],
    form: IFormAtividades
  ) => {
    let auxPerguntas: IPerguntaAtividade[] = [];
    //SOMENTE PERGUNTA INDEPENDENTE
    if (perguntasPais.length === 0) {
      form.atividade.questoes.forEach((perg) => {
        if (perg.pergunta.antecessora === null) {
          auxPerguntas.push({
            ...perg,
            indPergunta: `${auxPerguntas.length + 1}`,
          });
        }
      });

      return auxPerguntas;
    }

    //PERGUNTAS FILHAS
    perguntasPais.forEach((perg) => {
      const resposta = respostasAtividades.find(
        (res) => res.pergunta.id === perg.pergunta.id
      );

      if (!resposta) return;
      auxPerguntas = auxPerguntas.concat(
        listarArrayPerguntas(perg, resposta, form.atividade.questoes)
      );
    });

    return auxPerguntas;
  };

  const listarArrayPerguntas = (
    perguntaPai: IPerguntaAtividade,
    resposta: IRespostaAtividade,
    todasPerguntas: IPergunta[]
  ) => {
    let perguntasDependentes = todasPerguntas.filter(
      (perg) =>
        perg.pergunta.antecessora?.id === resposta.pergunta.id &&
        perg.pergunta.respostaCondicional?.id ===
          resposta?.alternativas[0]?.alternativa.id
    );

    perguntasDependentes = perguntasDependentes.map((perg, ind) => {
      return {
        ...perg,
        indPergunta: `${perguntaPai.indPergunta}.${ind + 1}`,
      };
    });

    return perguntasDependentes;
  };

  return (
    <>
      {contextholderModal}
      <Table
        bordered
        locale={{
          emptyText: (
            <Empty
              description={"Sem dados"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        dataSource={atividadesTif.map((atv) => {
          return {
            key: atv.key,
            programa: atv.programa.label,
            atividade: atv.atividade.nome,
            especificacoes: atv.especificacoes,
            prazo: atv.prazo,
            perguntas: atv.atividade.questoes,
            armadilha: atv.armadilha,
          };
        })}
        columns={columns}
        style={{
          overflowX: "auto",
        }}
      />

      {isOpenModalQuestion && atividadeSelected && (
        <ModalPerguntaAtividade
          isOpen={isOpenModalQuestion}
          atividadeSelected={atividadeSelected.atividade}
          armadilhaSelected={atividadeSelected.armadilha}
          handleClose={handleCloseModalQuestion}
          perguntas={perguntasNumeradas}
          setPerguntas={setPerguntasNumeradas}
          formPerguntas={formPerguntas}
        />
      )}

      {isOpenModalEdit && atividadeSelected && (
        <ModalEditarAtividade
          isOpen={isOpenModalEdit}
          handleClose={handleCloseModalEdit}
          form={formEditarAtividade}
          atividadeSelected={atividadeSelected}
          isSelectNotification={isNotificationEdit}
          setIsSelectNotification={setIsNotificationEdit}
        />
      )}
    </>
  );
}
