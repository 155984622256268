import { IArmadilha } from "@interfaces/IArmadilha";
import { ISubmitArmadilha } from "@interfaces/ISubmitArmadilha";
import { apiService } from "@services/api";
import { armadilhaAction } from "@store/actions/armadilhaAction";
import {
  getArmadilha,
  getArmadilhasToSync,
  getPersistedAtItemsToArmadilha,
} from "@store/feature/armadilhaSlice";
import { useDispatch, useSelector } from "react-redux";

export const useArmadilha = () => {
  const appDispatch = useDispatch();
  const listaArmadilhasSincronizadas = useSelector(getArmadilha);
  const listaArmadilhasToSync = useSelector(getArmadilhasToSync);
  const persistedAtItemsSync = useSelector(getPersistedAtItemsToArmadilha);
  const {
    syncArmadilhaByMunicipio,
    removeSyncArmadilhaByMunicipio,
    removeArmadilhaToSync,
    addArmadilhaToSync,
    updatePersistedAtItemsSync,
  } = armadilhaAction;

  const getArmadilhaByMunicipio = async (municipioId?: number) => {
    if (!municipioId) return [];

    if (navigator.onLine) {
      try {
        const arms = await apiService.armadilha.byMunicipio(municipioId);

        return arms;
      } catch (error) {
        console.log(error);
        return [];
      }
    } else {
      const armadilhas = listaArmadilhasSincronizadas.find(
        (item) => item.municipioId === municipioId
      );

      return armadilhas?.armadilhas ?? [];
    }
  };

  const sincronizarArmadilhas = (
    armadilhas: IArmadilha[],
    municipioId: number,
    dateTimeCreate: number
  ) => {
    appDispatch(
      syncArmadilhaByMunicipio(armadilhas, municipioId, dateTimeCreate)
    );
  };

  const removerSyncArmadilhas = (municipioId: number) => {
    appDispatch(removeSyncArmadilhaByMunicipio(municipioId));
  };

  const adicionarArmadilhaToSync = (armadilha: ISubmitArmadilha) => {
    appDispatch(addArmadilhaToSync(armadilha));
  };

  const removerArmadilhaToSync = (offlineId: number) => {
    appDispatch(removeArmadilhaToSync(offlineId));
  };

  const updatePersistedAtSyncItems = (dateTimeSyncItems: number) => {
    appDispatch(updatePersistedAtItemsSync(dateTimeSyncItems));
  };

  return {
    listaArmadilhasSincronizadas,
    getArmadilhaByMunicipio,
    sincronizarArmadilhas,
    removerSyncArmadilhas,
    adicionarArmadilhaToSync,
    removerArmadilhaToSync,
    listaArmadilhasToSync,
    updatePersistedAtSyncItems,
    persistedAtItemsSync,
  };
};
