import { CACHE_VERSION } from "@constants/CACHE_VERSION";
import { Button, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { iconsLogo } from "../../icons";
import styles from "../login/style.module.css";

export function LoginOffline() {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles["login-panel"]}>
        <div className={styles["login-form"]}>
          <Typography className={styles["titulo-sisdev"]}>
            SISDEV-mobile
          </Typography>
          <img
            src={iconsLogo[4].image}
            alt={iconsLogo[4].name}
            className={styles["login-logo"]}
          />

          <Button type="primary" onClick={() => navigate("/home")}>
            Entrar
          </Button>
        </div>
      </div>
      <div className={styles["container-cache-version"]}>
        Versão {CACHE_VERSION}
      </div>
    </>
  );
}
