import { openNotification } from "@components/Notifications";
import { transformStringToNumber } from "@functions/transformStringToNumber";
import { Button, Form, Input, Popconfirm, Row, Table, Typography } from "antd";
import moment from "moment";
import { useTAL } from "pages/termoApreensaoLiberacao/hooks/useTAL";
import React, { useEffect, useState } from "react";
import styles from "./style.module.css";

interface Item {
  key: number;
  tipoProduto: string;
  nomeProduto: string;
  fabricante: string;
  tipoEmbalagem: string;
  validade: string;
  lote: string;
  volume: string;
  unidadeMedida: string;
  quantidade: string;
  total: string;
  descricao: string;
  cultivar: string;
  renasem: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true }]}
        >
          {dataIndex === "descricao" ? (
            <Input.TextArea rows={3} maxLength={200} showCount />
          ) : (
            <Input />
          )}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export function ItemLiberacao() {
  const [form] = Form.useForm();
  const { listaItens, atualizarItem } = useTAL();
  const [dataTable, setDataTable] = useState<Item[]>([]);
  const [editingKey, setEditingKey] = useState(-1);
  const [initialState, setInitialState] = useState<Item[]>([]);

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ name: "", age: "", address: "", ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey(-1);
  };

  const save = async (key: React.Key) => {
    try {
      const itemSelected = listaItens.filter((item) => item.key === key)[0];
      const row = (await form.validateFields()) as Item;
      const quantidade =
        typeof row.quantidade === "number"
          ? row.quantidade
          : transformStringToNumber(row.quantidade);

      const filterInitialState = initialState.filter(
        (item) => item.key === key
      )[0];

      if (quantidade > parseFloat(filterInitialState.quantidade)) {
        openNotification({
          type: "error",
          message: "Não foi possivel salvar alteração",
          description:
            "Quantidade inserida é superior a quantidade registrada no termo de apreensão. Por favor, insira uma quantidade igual ou menor.",
        });
        return;
      }

      atualizarItem({
        ...itemSelected,
        quantidade: quantidade,
        total: quantidade * itemSelected.volume,
      });

      setEditingKey(-1);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns: any = [
    {
      title: "Ações",
      dataIndex: "operation",
      width: "5%",
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Row align="middle" gutter={24} justify="center">
              <Button onClick={() => save(record.key)} type="link">
                Salvar
              </Button>
            </Row>

            <Row align="middle" gutter={24} justify="center">
              <Popconfirm
                title="Tem certeza que deseja cancelar?"
                okText="Sim"
                cancelText="Não"
                onConfirm={cancel}
              >
                <Button type="link">Cancelar</Button>
              </Popconfirm>
            </Row>
          </div>
        ) : (
          <Typography.Link
            disabled={editingKey !== -1}
            onClick={() => edit(record)}
          >
            Editar
          </Typography.Link>
        );
      },
    },
    {
      title: "Quantidade",
      dataIndex: "quantidade",
      editable: true,
      width: "5%",
      render: (qntd: number) => (
        <span>{qntd.toString().replace(".", ",")}</span>
      ),
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "total",
      width: "5%",
      align: "center",
    },
    {
      title: "Und. Medida",
      dataIndex: "unidadeMedida",
      width: "5%",
      align: "center",
    },
    {
      title: "Identificação",
      dataIndex: "identificacao",
      width: "15%",
      align: "center",
    },
    {
      title: "Tipo Embalagem",
      dataIndex: "tipoEmbalagem",
      align: "center",
      width: "10%",
    },
    {
      title: "Fabricante ou RENASEM",
      dataIndex: "fabricanteRenasem",
      align: "center",
      width: "10%",
    },
    {
      title: "Lote",
      dataIndex: "lote",
      align: "center",
      width: "5%",
    },
    {
      title: "Validade",
      dataIndex: "validade",
      align: "center",
      width: "10%",
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      width: "15%",
    },
    {
      title: "Tipo Produto",
      dataIndex: "tipoProduto",
      align: "center",
      width: "10%",
    },
  ];

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    const itens: Item[] = listaItens.map((item) => {
      return {
        key: item.key,
        tipoProduto: item.tipoArea,
        quantidade: item.quantidade.toString(),
        unidadeMedida: item.unidadeMedida.nome,
        descricao: item.descricao,
        cultivar: item.nomeCultivar,
        fabricante: item.nomeFabricante,
        lote: item.lote,
        nomeProduto: item.nomeProduto,
        renasem: item.renasem,
        tipoEmbalagem: item.embalagem?.nome || "",
        total: item.total.toString().replace(".", ","),
        validade: item.dataValidade
          ? moment(item.dataValidade, "DD/MM/YYYY").format("DD/MM/YYYY")
          : "",
        volume: item.volume.toString().replace(".", ","),
      };
    });
    setDataTable(itens);

    if (initialState.length === 0) {
      setInitialState(itens);
    }
  }, [listaItens, initialState]);

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={dataTable}
        columns={mergedColumns}
        rowClassName={styles["editable-row"]}
        pagination={false}
        style={{
          overflowX: "auto",
          paddingBottom: "20px",
        }}
      />
    </Form>
  );
}
