import { UploadOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { ITif } from "@interfaces/ITif";
import { apiService } from "@services/api";
import { Button, message, Modal, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useTIF } from "../../../hooks/useTIF";
import styles from "./style.module.css";

interface Props {
  isOpenModal: boolean;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  tifSelected: ITif | null;
  spaceUsed: number;
  setSpaceUsed: React.Dispatch<React.SetStateAction<number>>;
}

export function SubmitAnexosTIF({
  tifSelected,
  setIsOpenModal,
  isOpenModal,
  spaceUsed,
  setSpaceUsed,
}: Props) {
  const { getAnexo, atualizarTif } = useTIF();
  const [anexos, setAnexos] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tamArquivos, setTamArquivos] = useState("0 Bytes");

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleChangeUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    const tamanhoArquivos =
      info.fileList.reduce(
        (accumulator, currentValue: any) => accumulator + currentValue.size,
        0
      ) + spaceUsed;

    if (info === null) return;

    // console.log(tamanhoArquivos);
    // console.log(formatBytes(tamanhoArquivos));
    // console.log(formatBytes(10 * 1048576));

    // 10 mb em binario
    const tenMb = 10 * 1048576;

    if (tamanhoArquivos > tenMb) {
      openNotification({
        type: "error",
        message: "Arquivo excede o tamanho limite",
        description: "Capacidade para anexos é de 10MB",
      });
      return;
    }

    setAnexos(info.fileList);
    setTamArquivos(formatBytes(tamanhoArquivos));
  };

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleSubmit = async () => {
    let formData = new FormData();

    if (anexos.length === 0 || !tifSelected?.id) return;

    anexos.forEach((anexo) => {
      formData.append("arquivos", anexo?.originFileObj || new File([], ""));
    });

    try {
      message.loading({
        key: "submit-anexos",
        duration: 0,
        content: "Enviando anexo(s)...",
      });
      setIsLoading(true);

      await apiService.tif.enviarAnexos(tifSelected.id, formData);

      const responseTif = await apiService.tif.byId(tifSelected.id);

      const dadosAnexos = responseTif.anexosTIF.map((anexo) => {
        return {
          nomeUpload: anexo.nomeUpload,
          nomeArquivo: anexo.nomeArquivo,
        };
      });

      if (dadosAnexos.length > 0) {
        const anexos = await getAnexo(dadosAnexos);
        // console.log(anexos);

        atualizarTif({
          ...tifSelected,
          anexosTIF: anexos,
        });

        openNotification({
          type: "success",
          message: "Anexo(s) enviado(s) com sucesso!",
        });
      }

      message.destroy("submit-anexos");
      setIsLoading(false);
      handleReset();
    } catch (error) {
      console.log(error);
      message.destroy("submit-anexos");
      setIsLoading(false);

      if (error instanceof AxiosError) {
        openNotification({
          type: "error",
          message: "Não foi possivel enviar anexo(s)",
          description: `Erro: ${error.response?.status}: ${error.response?.data.title}.`,
        });
        return;
      }

      openNotification({
        type: "error",
        message: "Não foi possivel enviar anexo(s)",
        description: `Erro desconhecido.`,
      });
    }
  };

  const handleReset = () => {
    setIsOpenModal(false);
    setAnexos([]);
    setSpaceUsed(0);
    setTamArquivos("0 Bytes");
  };

  useEffect(() => {
    setTamArquivos(formatBytes(spaceUsed));
    //eslint-disable-next-line
  }, [spaceUsed]);

  return (
    <>
      <Modal
        title={
          <h1 style={{ textAlign: "center", fontSize: 18, marginBottom: 0 }}>
            Enviar Anexos
          </h1>
        }
        open={isOpenModal}
        onCancel={handleReset}
        okButtonProps={{
          disabled: anexos.length === 0 || isLoading,
        }}
        cancelButtonProps={{
          disabled: isLoading,
        }}
        okText="Enviar"
        cancelText="Cancelar"
        onOk={handleSubmit}
      >
        <div className={styles.container}>
          <span className={styles["text-label"]}>
            TIF {tifSelected?.numero}
          </span>
          <span className={styles["text-label"]}>
            Espaço ocupado: {tamArquivos}
            /10 MB
          </span>
        </div>

        <div
          style={{
            paddingTop: 10,
          }}
        >
          <Upload
            customRequest={dummyRequest}
            onChange={handleChangeUpload}
            fileList={anexos}
          >
            <Button icon={<UploadOutlined />}>Anexo</Button>
          </Upload>
        </div>
      </Modal>
    </>
  );
}
