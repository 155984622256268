export const OPTIONS_FILTRO = [
  {
    label: "Municipio",
    value: "nomeMunicipio",
  },
  {
    label: "Código armadilha",
    value: "codigoArmadilha",
  },
];
