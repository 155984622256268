export const OPTIONS_FILTRO = [
  {
    label: "Número TIF",
    value: "numeroTIF",
  },
  {
    label: "Número",
    value: "numero",
  },
  {
    label: "Municipio da Fiscalização",
    value: "municipioFiscalizacao",
  },
  {
    label: "Nome do Fiscalizado",
    value: "nomeFiscalizado",
  },
  {
    label: "CPF/CNPJ do Fiscalizado",
    value: "cpfCnpjFiscalizado",
  },
];
