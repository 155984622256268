import {
  DeleteOutlined,
  QuestionOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { ModalDetalhesSync } from "../ModalDetalhesSync";
import styles from "./style.module.css";

interface IDownloadingDadosTermoProps {
  handleChange: (action: string) => void;
  isDataDownloaded: boolean;
  dadosTermoDownloading: boolean;
  timeExpireData: number;
  title: string;
  tipoTermo: "TIF" | "TCA" | "TAL" | "TV" | "Cadastro Armadilha";
  listaDadosSync: string[];
}

export function DownloadingDadosTermo({
  handleChange,
  isDataDownloaded,
  dadosTermoDownloading,
  timeExpireData,
  title,
  tipoTermo,
  listaDadosSync,
}: IDownloadingDadosTermoProps) {
  const [isOpenModalDetalhes, setIsOpenModalDetalhes] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <Typography style={{ display: "flex", flexDirection: "column" }}>
          {title}
          <br />
          {isDataDownloaded && (
            <span style={{ opacity: 0.7, fontSize: 13 }}>
              <strong>Atualizado em:</strong>{" "}
              {moment(timeExpireData).format("llll")}
            </span>
          )}
        </Typography>

        {!isDataDownloaded && (
          <div className={styles["container-button"]}>
            <Button
              onClick={() => handleChange("adicionar")}
              style={{
                width: "100%",
                boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
              }}
              disabled={dadosTermoDownloading}
            >
              {dadosTermoDownloading ? (
                <SyncOutlined spin={dadosTermoDownloading} />
              ) : (
                <span>Baixar</span>
              )}
            </Button>
          </div>
        )}

        {isDataDownloaded && (
          <div className={styles["container-button"]}>
            <Tooltip title="Mais Detalhes">
              <Button
                style={{
                  boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                  width: 50,
                }}
                onClick={() => setIsOpenModalDetalhes(true)}
              >
                <QuestionOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Atualizar">
              <Button
                style={{
                  boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                  width: 50,
                }}
                onClick={() => handleChange("atualizar")}
                disabled={dadosTermoDownloading}
              >
                <SyncOutlined spin={dadosTermoDownloading} />
              </Button>
            </Tooltip>

            <Tooltip title="Remover">
              <Button
                style={{
                  boxShadow: "5px 5px 5px rgba(0,0,0,0.2)",
                  width: 50,
                }}
                danger
                onClick={() => handleChange("remover")}
                disabled={dadosTermoDownloading}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>

      <ModalDetalhesSync
        listaDadosSync={listaDadosSync}
        tipoTermo={tipoTermo}
        open={isOpenModalDetalhes}
        onCancel={() => setIsOpenModalDetalhes(false)}
      />
    </>
  );
}
