import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MenuOutlined,
  MinusOutlined,
  PaperClipOutlined,
  PrinterOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ModalCancelarTermo } from "@components/ModalCancelarTermo";
import { ModalVisualizarAnexos } from "@components/ModalVisualizarAnexos";
import { openNotification } from "@components/Notifications";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { formatMessageError } from "@functions/formatMessageError";
import { usePermission } from "@hooks/usePermission";
import { ITID } from "@interfaces/ITid";
import { apiService } from "@services/api";
import { Button, Dropdown, List, Menu, Modal, Tooltip } from "antd";
import moment from "moment";
import { useTID } from "pages/tid/hooks/useTID";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatToFormData } from "../../../functions/formatToFormData";
import styles from "../../style.module.css";

const CODIGO_TERMO = "TID";

export function ItemListaTID({
  termo,
  pageControl,
}: {
  termo: ITID;
  pageControl: number;
}) {
  const tipoTermoFormatado =
    termo.tipoTermo === "INTERDICAO" ? "Interdição" : "Desinterdição";
  const navigate = useNavigate();
  const { isAllowed } = usePermission();
  const { removerTermo, listaTIDs, atualizarTermo } = useTID();
  const [modal, contextHolder] = Modal.useModal();
  const [isCancel, setIsCancel] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModalAnexos, setIsOpenModalAnexos] = useState(false);

  const handleShowAnexos = () => {
    setIsOpenModalAnexos(true);
  };

  const handlePostTermo = async () => {
    setIsLoading(true);

    const anexos = termo.anexos.map((anexo) => {
      return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
    });

    let termoSubmit = termo;

    if (termo.tif && !termo.tif?.id) {
      const params = new URLSearchParams();
      params.append("numero", termo.tif?.numero || "");
      try {
        const { content } = await apiService.tif.listar(params.toString());

        if (content.length > 0) {
          termoSubmit = {
            ...termoSubmit,
            tif: { ...termo.tif, id: content[0].id },
          };
        } else {
          openNotification({
            type: "info",
            message: `Não foi possivel enviar Termo de ${tipoTermoFormatado}.`,
            description:
              "Favor sincronizar o TIF antes de salvar esse termo. Caso já tenha sincronizado, procure o administrador do sistema.",
          });
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        const messageError = formatMessageError(error);

        openNotification({
          type: "error",
          message: " Não foi possivel salvar o termo.",
          description: messageError,
        });
      }
    }

    if (termoSubmit.tif && !termoSubmit.tif.id) {
      setIsLoading(false);
      return;
    }

    if (termo.termoInterdicao && !termo.termoInterdicao.id) {
      const params = new URLSearchParams();
      params.append("numero", termo.termoInterdicao.numero);
      try {
        const { content } = await apiService.tid.listar(params.toString());

        if (content.length > 0) {
          termoSubmit = {
            ...termoSubmit,
            termoInterdicao: { ...termo.termoInterdicao, id: content[0].id },
          };
        } else {
          openNotification({
            type: "info",
            message: `Não foi possivel enviar Termo de ${tipoTermoFormatado}.`,
            description:
              "Favor sincronizar o Termo de Interdição antes de salvar esse termo. Caso já tenha sincronizado, procure o administrador do sistema.",
          });
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        const messageError = formatMessageError(error);
        openNotification({
          type: "error",
          message: "Não foi possivel salvar o termo",
          description: messageError,
        });
      }
    }

    if (termoSubmit.termoInterdicao && !termoSubmit.termoInterdicao.id) {
      setIsLoading(false);
      return;
    }

    await apiService.tid
      .enviar(formatToFormData(termoSubmit, anexos))
      .then((data) => {
        atualizarTermo({
          ...termoSubmit,
          id: data.id,
          anexos: termoSubmit.anexos,
        });
        openNotification({
          type: "success",
          message: "Termo salvo com sucesso!",
          description: "Termo salvo na base de dados.",
        });
      })
      .catch((erro) => {
        console.log(erro);
        const messageError = formatMessageError(erro);

        openNotification({
          type: "error",
          message: "Não foi possivel salvar o termo.",
          description: messageError,
        });
      });

    setIsLoading(false);
  };

  const handlePrintedTermo = () => {
    const redirectURL = `/TID/listagem/imprimir/${termo.numero.replaceAll(
      "/",
      "_"
    )}`;
    localStorage.setItem("TID:pageSelect", pageControl.toString());
    navigate(redirectURL);

    return;
  };

  const handleEditBeforeSubmit = () => {
    const redirectURL = `/TID/listagem/editar/${termo.numero.replaceAll(
      "/",
      "_"
    )}`;
    localStorage.setItem("TID:pageSelect", pageControl.toString());
    navigate(redirectURL);
  };

  const handleEditAfterSubmit = () => {
    if (!termo.id) return;
    localStorage.setItem("TID:pageSelect", pageControl.toString());
    navigate(`/TID/listagem/editar-assinatura/${termo.id}`);
  };

  const handleCancel = () => {
    setIsCancel(true);
  };

  const handleDeleteTermo = () => {
    setIsDelete(true);
    modal.confirm({
      title: `Tem certeza que deseja Excluir o Termo de ${tipoTermoFormatado} ${termo.numero}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sim",
      cancelText: "Não",
      onOk: () => {
        removerTermo(termo.numero);
        openNotification({
          type: "success",
          message: `Termo de ${tipoTermoFormatado} excluído com sucesso!`,
        });
        setIsDelete(false);
      },
      onCancel: () => {
        setIsDelete(false);
      },
    });
  };

  // REMOVER DA APLICAÇÃO
  const handleRemoveTermoInApp = () => {
    if (!termo.id) return;

    const tid = listaTIDs.find((auxTermo) => auxTermo.id === termo.id);

    if (!tid) return;

    removerTermo(tid.numero);
    openNotification({
      type: "success",
      message: `Termo de ${tipoTermoFormatado} ${tid.numero} removido  com sucesso!`,
    });
  };

  const handleViewTermo = () => {
    if (!termo.id) return;
    navigate(`/TID/listagem/visualizar/${termo.id}`);
    localStorage.setItem("TID:pageSelect", pageControl.toString());
  };

  return (
    <>
      {contextHolder}
      <List.Item
        className="lista-item"
        style={{
          border: isCancel || isDelete ? "1px solid #ff4d4f" : "",
        }}
      >
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{
                color: isCancel || isDelete ? "#ff4d4f" : "#0D0D0D",
              }}
            >
              Termo de {tipoTermoFormatado} {termo.numero}
            </span>
          }
          description={
            <span className="subtitulo-card">
              Data Fiscalização:
              {termo.dataFiscalizacao.includes("/")
                ? termo.dataFiscalizacao
                : moment(termo.dataFiscalizacao).format("DD/MM/YYYY")}{" "}
              ID:
              {termo.id ? termo.id : ""}
            </span>
          }
        />

        <div className={styles["pc-menu-lista-termos"]}>
          {!termo.id && navigator.onLine && (
            <Button disabled={isLoading} onClick={() => handlePostTermo()}>
              {isLoading ? <SyncOutlined spin={isLoading} /> : "Enviar"}
            </Button>
          )}

          {termo.anexos && termo.anexos.length > 0 && (
            <Tooltip title="Anexos" destroyTooltipOnHide>
              <Button
                onClick={(e) => {
                  handleShowAnexos();
                }}
              >
                <PaperClipOutlined />
              </Button>
            </Tooltip>
          )}

          <Tooltip title="Imprimir" destroyTooltipOnHide>
            <Button onClick={() => handlePrintedTermo()}>
              <PrinterOutlined />
            </Button>
          </Tooltip>

          {termo.id && (
            <Tooltip title="Visualizar" destroyTooltipOnHide>
              <Button
                disabled={
                  !navigator.onLine ||
                  !isAllowed("visualizar", CODIGO_TERMO) ||
                  !termo.statusAssinaturaFiscalizado
                }
                onClick={() => handleViewTermo()}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
          )}

          {termo.id ? (
            <Tooltip title="Editar" destroyTooltipOnHide>
              <Button
                onClick={handleEditAfterSubmit}
                disabled={termo.statusAssinaturaFiscalizado !== "AUSENTE"}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Editar" destroyTooltipOnHide>
              <Button onClick={() => handleEditBeforeSubmit()}>
                <EditOutlined />
              </Button>
            </Tooltip>
          )}

          {termo.id ? (
            <Tooltip title="Cancelar" destroyTooltipOnHide>
              <Button
                disabled={
                  !navigator.onLine ||
                  !isAllowed("excluir", CODIGO_TERMO) ||
                  !termo.statusAssinaturaFiscalizado
                }
                danger
                onClick={handleCancel}
              >
                <CloseOutlined />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Excluir" destroyTooltipOnHide>
              <Button danger onClick={() => handleDeleteTermo()}>
                <DeleteOutlined />
              </Button>
            </Tooltip>
          )}

          {termo.id && (
            <Tooltip title="Remover do uso offline" destroyTooltipOnHide>
              <Button danger onClick={() => handleRemoveTermoInApp()}>
                <MinusOutlined />
              </Button>
            </Tooltip>
          )}
        </div>

        <div className={styles["mobile-menu-lista-termos"]}>
          {!termo.id && navigator.onLine && (
            <Button disabled={isLoading} onClick={() => handlePostTermo()}>
              {isLoading ? <SyncOutlined spin={isLoading} /> : "Enviar"}
            </Button>
          )}

          <Dropdown
            placement="bottom"
            trigger={["click"]}
            overlay={() => {
              if (termo.id) {
                return (
                  <Menu
                    items={[
                      {
                        key: 0,
                        label: "Anexos",
                        icon: <PaperClipOutlined />,
                        disabled: !(termo.anexos && termo.anexos.length > 0),
                        onClick: () => {
                          handleShowAnexos();
                        },
                      },
                      {
                        key: 1,
                        label: "Imprimir",
                        icon: <PrinterOutlined />,
                        onClick: (e) => handlePrintedTermo(),
                      },
                      {
                        key: 2,
                        label: "Visualizar",
                        icon: <EyeOutlined />,
                        disabled:
                          !navigator.onLine ||
                          !isAllowed("visualizar", CODIGO_TERMO),
                        onClick: (e) => handleViewTermo(),
                      },
                      {
                        key: 3,
                        label: "Editar",
                        icon: <EditOutlined />,
                        onClick: (e) => handleEditAfterSubmit(),
                        disabled:
                          termo.statusAssinaturaFiscalizado !== "AUSENTE" ||
                          !isAllowed("editar", CODIGO_TERMO),
                      },
                      {
                        key: 4,
                        label: "Cancelar",
                        danger: true,
                        icon: <CloseOutlined />,
                        disabled:
                          !navigator.onLine ||
                          !isAllowed("excluir", CODIGO_TERMO),
                        onClick: () => {
                          handleCancel();
                        },
                      },
                      {
                        key: 5,
                        label: "Remover do uso Offline",
                        danger: true,
                        icon: <MinusOutlined />,
                        disabled: !termo.id,
                        onClick: (e) => handleRemoveTermoInApp(),
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Menu
                    items={[
                      {
                        key: 0,
                        label: "Anexos",
                        icon: <PaperClipOutlined />,
                        disabled: !(termo.anexos && termo.anexos.length > 0),
                        onClick: () => {
                          handleShowAnexos();
                        },
                      },
                      {
                        key: 1,
                        label: "Imprimir",
                        icon: <PrinterOutlined />,
                        onClick: (e) => handlePrintedTermo(),
                      },
                      {
                        key: 2,
                        label: "Editar",
                        icon: <EditOutlined />,
                        onClick: (e) => handleEditBeforeSubmit(),
                      },
                      {
                        key: 3,
                        label: "Excluir",
                        danger: true,
                        icon: <DeleteOutlined />,
                        onClick: () => handleDeleteTermo(),
                      },
                    ]}
                  />
                );
              }
            }}
          >
            <Tooltip title="Opções" destroyTooltipOnHide>
              <Button>
                <MenuOutlined />
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      </List.Item>

      <ModalCancelarTermo
        isOpen={isCancel}
        setIsOpen={setIsCancel}
        apiName="tid"
        titleTermo={`Termo de ${tipoTermoFormatado}`}
        idTermo={termo.id}
        numTermo={termo.numero}
        onRemoveTermo={removerTermo}
        reloadFilter={async () => {}}
      />

      <ModalVisualizarAnexos
        anexos={termo.anexos}
        isOpenModal={isOpenModalAnexos}
        setIsOpenModal={setIsOpenModalAnexos}
      />
    </>
  );
}
