import { openNotification } from "@components/Notifications";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { IAssinante } from "@interfaces/IAssinante";
import { IListagemFormularioPerguntas } from "@interfaces/IListagemFormularioPerguntas";
import {
  IDadosAssinaturaTermoVistoria,
  ITermoVistoria,
} from "@interfaces/ITermoVistoria";
import { filtroTermoVistoriaAction } from "@store/actions/filtroTermoVistoriaAction";
import { formularioPerguntasVistoriaAction } from "@store/actions/formularioPerguntasVistoriaAction";
import { termoVistoriaAction } from "@store/actions/termoVistoriaAction";
import { getFiltroTermoVistoria } from "@store/feature/filtroTermoVistoriaSlice";
import { getFormularioPerguntasVistoria } from "@store/feature/formularioPerguntasVistoriaSlice";
import {
  getPersistedAtTermoVistoria,
  getTermosVistoria,
} from "@store/feature/termoVistoriaSlice";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useDispatch, useSelector } from "react-redux";

interface IValidateTermoProps {
  statusAssinatura: CheckboxValueType[];
  dadosFiscalizado: IAssinante;
  assinaturaServidor: string;
}

export const useTV = () => {
  const appDispatch = useDispatch();
  const listaTermos = useSelector(getTermosVistoria);
  const listaFiltroTermos = useSelector(getFiltroTermoVistoria);
  const listaFormsPerguntaTermoVistoria = useSelector(
    getFormularioPerguntasVistoria
  );
  const termoVistoriaPersistedAtItems = useSelector(
    getPersistedAtTermoVistoria
  );
  const { addFiltroTermoVistoria, removeFiltroTermoVistoria } =
    filtroTermoVistoriaAction;
  const { addFormsPerguntaVistoria, removeFormsPerguntaVistoria } =
    formularioPerguntasVistoriaAction;
  const {
    addTermo,
    removeTermo,
    updateTermo,
    updateAssinatura,
    addByFilter,
    addTimePersistedAtItems,
    removeTimePersistedAtItems,
  } = termoVistoriaAction;

  const validateTermo = ({
    dadosFiscalizado,
    statusAssinatura,
    assinaturaServidor,
  }: IValidateTermoProps) => {
    if (
      getStatusAssinatura(statusAssinatura) === "ASSINADO_DIGITALMENTE" &&
      (!assinaturaServidor || !dadosFiscalizado.assinatura)
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Vistoria.",
        description: "Preencha as assinaturas pendentes.",
      });

      return;
    }

    if (
      (getStatusAssinatura(statusAssinatura) === "AUSENTE" ||
        getStatusAssinatura(statusAssinatura) === "RECUSADO") &&
      !assinaturaServidor
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Vistoria.",
        description: "Preencha a assinatura do servidor.",
      });

      return;
    }

    return true;
  };

  const submitTermo = (termo: ITermoVistoria) => {
    if (!termo.formulario) return;
    appDispatch(addTermo(termo));
  };

  const atualizarTermo = (termo: ITermoVistoria) => {
    appDispatch(updateTermo(termo));
  };

  const removerTermo = (numTermo: string) => {
    appDispatch(removeTermo(numTermo));
  };

  const atualizarAssinaturaTermo = (
    dadosAssinatura: IDadosAssinaturaTermoVistoria,
    termoId: number,
    termo: ITermoVistoria
  ) => {
    appDispatch(updateAssinatura(dadosAssinatura, termoId, termo));
  };

  const adicionarTermoByFiltro = (termo: ITermoVistoria) => {
    appDispatch(addByFilter(termo));
  };

  const removerFiltro = () => {
    appDispatch(removeFiltroTermoVistoria());
  };

  const aplicarFiltro = (content: any) => {
    appDispatch(addFiltroTermoVistoria(content));
  };

  const adicionarFormsPerguntasTermoVistoria = (
    forms: IListagemFormularioPerguntas[],
    persistedAt: number
  ) => {
    appDispatch(addFormsPerguntaVistoria(forms, persistedAt));
  };

  const removerFormsPerguntasTermoVistoria = () => {
    appDispatch(removeFormsPerguntaVistoria());
  };

  const adicionarPersistedAtTermoVistoria = (time: number) => {
    appDispatch(addTimePersistedAtItems(time));
  };

  const removerPersistedAtTermoVistoria = () => {
    appDispatch(removeTimePersistedAtItems());
  };

  return {
    submitTermo,
    listaTermos,
    validateTermo,
    atualizarTermo,
    removerTermo,
    atualizarAssinaturaTermo,
    adicionarTermoByFiltro,
    removerFiltro,
    aplicarFiltro,
    listaFiltroTermos,
    removerFormsPerguntasTermoVistoria,
    adicionarFormsPerguntasTermoVistoria,
    listaFormsPerguntaTermoVistoria,
    termoVistoriaPersistedAtItems,
    adicionarPersistedAtTermoVistoria,
    removerPersistedAtTermoVistoria,
  };
};
