import { IPerguntaImpressaoTIF } from "@interfaces/IPerguntaImpressaoTIF";
import { ITif } from "@interfaces/ITif";
import styled from "styled-components";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { Alternativa } from "./components/Alternativa";
import { Campo } from "./components/Campo";
import { Descritiva } from "./components/Descritiva";
import { MultiplaEscolha } from "./components/MultiplaEscolha";
import styles from "./style.module.css";

const TitleCSS = styled.div`
  font-weight: bold;
  background-color: #d9d9d9;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

const TituloAtividade = styled.div`
  text-align: center;
  font-size: 14px;
  margin-bottom: 5px;
  text-decoration-line: underline;
`;

export function Questionario({
  perguntas,
  tif,
}: {
  perguntas: IPerguntaImpressaoTIF[];
  tif: ITif;
}) {
  return (
    <>
      <table
        className={`${styles["container-questionario-impressao"]} tif-impressao-font`}
      >
        <Header numTIF={tif.numero} />

        <tbody
          style={{
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <tr>
            <td>
              <TitleCSS>QUESTIONÁRIO DE ATIVIDADES</TitleCSS>
            </td>
          </tr>
          <tr>
            <td style={{ display: "block" }}>
              {perguntas.map((atv, ind) => {
                // console.log(atv);
                return (
                  <div key={ind} style={{ padding: 10 }}>
                    <TituloAtividade>
                      {atv.atividade.nome}
                      {atv.atividade.armadilha
                        ? `-${atv.atividade.armadilha.codigo}`
                        : ""}
                    </TituloAtividade>

                    <div>
                      {atv.perguntas.map((perg, indice) => {
                        const identacaoEspaco =
                          perg.indPergunta?.split(".").length || 0;

                        return (
                          <div
                            key={indice}
                            style={{
                              paddingBottom: 2,
                              paddingLeft:
                                identacaoEspaco === 1
                                  ? 0
                                  : identacaoEspaco * 10,
                            }}
                          >
                            <div style={{ marginBottom: 0, fontSize: 12 }}>
                              {perg.indPergunta}. {perg.pergunta.nome}
                            </div>

                            {perg.pergunta.tipoResposta === "ALTERNATIVA" && (
                              <Alternativa atv={atv} pergunta={perg} />
                            )}

                            {perg.pergunta.tipoResposta ===
                              "ALTERNATIVA_DESCRITIVA" && (
                              <>
                                <Alternativa atv={atv} pergunta={perg} />
                                <Descritiva pergunta={perg} atv={atv} />
                              </>
                            )}

                            {perg.pergunta.tipoResposta === "DESCRITIVA" && (
                              <Descritiva pergunta={perg} atv={atv} />
                            )}

                            {perg.pergunta.tipoResposta ===
                              "MULTIPLA_ESCOLHA" && (
                              <MultiplaEscolha pergunta={perg} atv={atv} />
                            )}

                            {perg.pergunta.tipoResposta ===
                              "MULTIPLA_ESCOLHA_DESCRITIVA" && (
                              <>
                                <MultiplaEscolha pergunta={perg} atv={atv} />
                                <Descritiva atv={atv} pergunta={perg} />
                              </>
                            )}

                            <Campo atv={atv} pergunta={perg} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </td>
          </tr>
        </tbody>

        <Footer tif={tif} />
      </table>
    </>
  );
}
