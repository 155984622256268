import { openNotification } from "@components/Notifications";
import { masks } from "@functions/mascaras";
import { useMunicipio } from "@hooks/useMunicipio";
import { IAssinante } from "@interfaces/IAssinante";
import { IFormValuesTID } from "@interfaces/IFormValuesTID";
import { ITID } from "@interfaces/ITid";
import { DatePicker, Form, FormInstance, Input, Radio } from "antd";
import { AxiosError } from "axios";
import moment from "moment";
import { useTID } from "pages/tid/hooks/useTID";
import { useTIF } from "pages/tif/hooks/useTIF";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface IRegistroFiscalizadoTIDProps {
  form: FormInstance<IFormValuesTID>;
  setFiscalizadoSignature: React.Dispatch<React.SetStateAction<IAssinante>>;
  setTifSelected: React.Dispatch<any>;
  tidEdit?: ITID;
}

export function RegistroFiscalizadoTID({
  form,
  setFiscalizadoSignature,
  setTifSelected,
  tidEdit,
}: IRegistroFiscalizadoTIDProps) {
  const { state } = useLocation();
  const { municipiosBrasil, listaUfs } = useMunicipio();
  const { getTifByNumero } = useTIF();
  const { buscarTermoInterdicao } = useTID();
  const { cepMask, cpfCnpjMask, telefoneMask } = masks;
  const [isLoading, setIsLoading] = useState(false);
  const [isPropriedade, setIsPropriedade] = useState(false);
  const [isTidAfterTif, setIsTidAfterTif] = useState(false);

  const handleSearchTIF = async (numTIF: string) => {
    const { numTermoInterdicao, tipoTermo } = form.getFieldsValue();

    const termoInterdicaoSelected =
      await buscarTermoInterdicao(numTermoInterdicao);

    if (!numTIF) {
      openNotification({
        type: "info",
        message: "Não foi possivel Buscar TIF",
        description: "Digite um número válido do TIF",
      });

      return;
    }

    if (tipoTermo === "DESINTERDICAO" && !termoInterdicaoSelected) {
      openNotification({
        type: "info",
        message: "Não foi possivel Buscar Termo de Interdição",
        description: "Digite um número válido do Termo",
      });

      return;
    }

    if (!form.getFieldValue("tipoTermo")) {
      openNotification({
        type: "info",
        message: "Não foi possivel Buscar TIF",
        description:
          "Preencha se o termo é de Interdição ou Desinterdição para prosseguir.",
      });

      return;
    }

    try {
      setIsLoading(true);
      const tif = await getTifByNumero(numTIF.trim());
      setIsLoading(false);

      if (!tif) {
        openNotification({
          type: "info",
          message: "Não foi possivel encontrar TIF",
          description:
            "TIF não foi encontrado na base de dados, favor verificar se o número foi preenchido corretamente.",
        });
        return;
      }

      if (tif && !tif.ativo && navigator.onLine) {
        openNotification({
          type: "info",
          message: "TIF não está ativo",
        });
        return;
      }

      if (
        ["PROFISSIONAL", "TRANSITO"].includes(tif.caracteristicaFiscalizado)
      ) {
        openNotification({
          type: "info",
          message: "Não foi possivel Buscar TIF",
          description: "Autuado do TIF incompatível com interdição",
        });
        return;
      }

      const funcaoOpcionalFiscalizado = tif.estabelecimento
        ? "Estabelecimento"
        : "Produtor";

      // console.log(tif);
      setTifSelected(tif);

      setFiscalizadoSignature({
        assinatura: null,
        cpfAssinante: cpfCnpjMask(tif.cpfCnpjFiscalizado),
        funcaoAssinante: tif.funcaoAssinante || funcaoOpcionalFiscalizado,
        nomeAssinante: tif.nomeFiscalizado,
      });

      form.setFieldsValue({
        nomeFiscalizado: tif.nomeFiscalizado,
        cpfCnpjFiscalizado: cpfCnpjMask(tif.cpfCnpjFiscalizado),
        dataTIF: moment(tif.dataFiscalizacao),
        ufFiscalizadoId: tif.municipioFiscalizado?.uf.id,
        municipioFiscalizadoId: tif.municipioFiscalizado?.id,
        enderecoFiscalizado: tif.enderecoFiscalizado,
        emailFiscalizado: tif.emailFiscalizado,
        cepFiscalizado: cepMask(tif.cepFiscalizado),
        telefoneFiscalizado: telefoneMask(tif.telefoneFiscalizado),
        municipioFiscalizacaoId: tif.municipioFiscalizacao?.id,
        tipoRepresentante:
          tif.tipoRepresentante !== "TRANSPORTADOR"
            ? tif.tipoRepresentante
            : "",
        localizacao: tif.caracteristicaFiscalizado,
      });

      if (tif.propriedade) {
        setIsPropriedade(true);
        form.setFieldsValue({
          nomePropriedade: tif.nomePropriedade,
          enderecoPropriedade: tif.enderecoPropriedade,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);

      if (error instanceof AxiosError) {
        openNotification({
          type: "error",
          message: "Não foi possivel buscar TIF",
          description: `Erro: ${error.response?.status}: ${error.response?.data.title}.`,
        });
        return;
      }

      openNotification({
        type: "error",
        message: "Não foi possivel buscar TIF",
        description: "Erro desconhecido.",
      });
    }
  };

  const handleChangeNumTIF = () => {
    setIsPropriedade(false);
    setTifSelected(null);
    form.resetFields([
      "nomeFiscalizado",
      "cpfCnpjFiscalizado",
      "ufFiscalizadoId",
      "municipioFiscalizadoId",
      "municipioFiscalizacaoId",
      "enderecoFiscalizado",
      "emailFiscalizado",
      "cepFiscalizado",
      "telefoneFiscalizado",
      "tipoRepresentante",
      "localizacao",
      "nomePropriedade",
      "enderecoPropriedade",
      "atividadesId",
      "amparoLegal",
    ]);

    form.setFieldsValue({
      dataTIF: moment(),
    });

    setFiscalizadoSignature({
      assinatura: null,
      cpfAssinante: "",
      funcaoAssinante: "",
      nomeAssinante: "",
    });
  };

  useEffect(() => {
    if (tidEdit) {
      setIsPropriedade(
        tidEdit.tif?.caracteristicaFiscalizado === "PROPRIEDADE"
      );
    }

    if (state) {
      form.setFieldValue("numTIF", state.formValues.numero);
      if (state.tipo === "INTERDICAO") {
        handleSearchTIF(state.formValues.numero);
        setIsTidAfterTif(true);
      }
    } else {
      setIsTidAfterTif(false);
    }

    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Form.Item label="N° do TIF" name="numTIF" rules={[{ required: true }]}>
        <Input.Search
          enterButton="Buscar"
          placeholder="Digite o N° do TIF"
          onSearch={handleSearchTIF}
          loading={isLoading}
          onChange={handleChangeNumTIF}
          disabled={isTidAfterTif}
        />
      </Form.Item>

      <Form.Item label="Localização">
        <Radio.Group disabled value={form.getFieldValue("localizacao") || ""}>
          <Radio value="PROPRIEDADE">Propriedade</Radio>
          <Radio value="ESTABELECIMENTO">Estabelecimento</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Relação">
        <Radio.Group
          disabled
          value={form.getFieldValue("tipoRepresentante") || ""}
        >
          <Radio value="PROPRIETARIO">Proprietário</Radio>
          <Radio value="ARRENDATARIO">Arrendatário</Radio>
          <Radio value="OCUPANTE">Ocupante</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Data do termo">
        <DatePicker
          disabled
          format={"DD/MM/YYYY"}
          value={form.getFieldValue("dataTIF") || ""}
        />
      </Form.Item>

      <Form.Item label="Nome do Fiscalizado">
        <Input disabled value={form.getFieldValue("nomeFiscalizado") || ""} />
      </Form.Item>

      <Form.Item label="CPF/CNPJ do Fiscalizado">
        <Input
          disabled
          value={form.getFieldValue("cpfCnpjFiscalizado") || ""}
        />
      </Form.Item>

      <Form.Item label="UF do Fiscalizado">
        <Input
          disabled
          value={
            listaUfs.find(
              (uf) => uf.id === form.getFieldValue("ufFiscalizadoId")
            )?.sigla || ""
          }
        />
      </Form.Item>

      <Form.Item label="Municipio do Fiscalizado">
        <Input
          disabled
          value={`${
            municipiosBrasil.find(
              (municipio) =>
                municipio.id === form.getFieldValue("municipioFiscalizadoId")
            )?.nome || ""
          }-${
            municipiosBrasil.find(
              (municipio) =>
                municipio.id === form.getFieldValue("municipioFiscalizadoId")
            )?.uf.sigla || ""
          }`}
        />
      </Form.Item>

      <Form.Item label="RG do Fiscalizado">
        <Input disabled value={form.getFieldValue("rgFiscalizado") || ""} />
      </Form.Item>

      <Form.Item label="RENASEM do Fiscalizado">
        <Input
          disabled
          value={form.getFieldValue("renasemFiscalizado") || ""}
        />
      </Form.Item>

      <Form.Item label="Endereço do Fiscalizado">
        <Input
          disabled
          value={form.getFieldValue("enderecoFiscalizado") || ""}
        />
      </Form.Item>

      <Form.Item label="CEP do Fiscalizado">
        <Input disabled value={form.getFieldValue("cepFiscalizado") || ""} />
      </Form.Item>

      <Form.Item label="Telefone do Fiscalizado">
        <Input
          disabled
          value={form.getFieldValue("telefoneFiscalizado") || ""}
        />
      </Form.Item>

      <Form.Item label="Email do Fiscalizado">
        <Input disabled value={form.getFieldValue("emailFiscalizado") || ""} />
      </Form.Item>

      {isPropriedade && (
        <>
          <Form.Item label="Propriedade">
            <Input
              disabled
              value={form.getFieldValue("nomePropriedade") || ""}
            />
          </Form.Item>

          <Form.Item label="Endereço Propriedade">
            <Input
              disabled
              value={form.getFieldValue("enderecoPropriedade") || ""}
            />
          </Form.Item>
        </>
      )}

      <Form.Item label="Municipio da Fiscalização">
        <Input
          disabled
          value={`${
            municipiosBrasil.find(
              (municipio) =>
                municipio.id === form.getFieldValue("municipioFiscalizacaoId")
            )?.nome || ""
          }-${
            municipiosBrasil.find(
              (municipio) =>
                municipio.id === form.getFieldValue("municipioFiscalizacaoId")
            )?.uf.sigla || ""
          }`}
        />
      </Form.Item>
    </>
  );
}
