import {
  DisconnectOutlined,
  DownloadOutlined,
  FileAddFilled,
  FileOutlined,
  FilePdfOutlined,
  FormOutlined,
  HomeOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useMenu } from "@hooks/useMenu";
import { usePermission } from "@hooks/usePermission";
import { IMenuOptions } from "@interfaces/IMenuOptions";
import { Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ADMIN_ID = 1;

export function MenuSystem({ isSidebar }: { isSidebar?: boolean }) {
  const navigate = useNavigate();
  const { permissoes, userController } = usePermission();
  const { atualizarMenuSelected, menuSelected, listaMenus } = useMenu();
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<any>(null);

  const getChildren = (item: IMenuOptions, listaMenus: string[]) => {
    if (!permissoes) return;

    const permissao = permissoes.find((perm) => perm.menu.id === item.id);

    const result = item.filhos.map((child) => {
      const menuData = {
        key: child.id,
        label: child.nome,
        onClick: () => navigate(child.url),
      };

      if (
        listaMenus.includes(child.codigo) &&
        child.codigo.includes("Listagem")
      ) {
        return {
          ...menuData,
          icon: <UnorderedListOutlined />,
        };
      }

      if (listaMenus.includes(child.codigo)) {
        return {
          ...menuData,
          icon: <FileAddFilled />,
          disabled: !permissao?.adicionar,
        };
      }

      return menuData;
    });

    return result;
  };

  const menuOptions = listaMenus.map((item) => {
    const infoGeral = {
      key: item?.id,
      label: item?.nome,
    };

    if (!item) return null;

    if (item.codigo === "inicio") {
      return {
        ...infoGeral,
        icon: <HomeOutlined />,
        onClick: () => navigate(item.url),
      };
    }

    if (item.codigo === "offlineMode") {
      return {
        ...infoGeral,
        icon: <DisconnectOutlined />,
        onClick: () => navigate(item.url),
      };
    }

    if (
      item.codigo === "Perfil" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        icon: <UsergroupAddOutlined />,
        onClick: () => navigate(item.url),
      };
    }

    if (
      item.codigo === "config" &&
      userController?.perfisId.includes(ADMIN_ID)
    ) {
      const children = item.filhos.map((filho) => {
        const auxChildren = {
          key: filho.id,
          label: filho.nome,
          onClick: () => navigate(filho.url),
        };

        if (filho.codigo === "tifConfig") {
          return {
            ...auxChildren,
            icon: <FormOutlined />,
          };
        }

        return auxChildren;
      });
      return {
        ...infoGeral,
        icon: <SettingOutlined />,
        children: children,
      };
    }

    if (
      item.codigo === "TIF" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: getChildren(item, ["TIFNovo", "TIFListagem"]),
      };
    }

    if (
      item.codigo === "TermoColeta" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: getChildren(item, ["TermoColetaNovo", "TermoColetaListagem"]),
      };
    }

    if (
      item.codigo === "TermoApreensaoLiberacao" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: getChildren(item, [
          "TermoApreensaoLiberacaoNovo",
          "TermoApreensaoLiberacaoListagem",
        ]),
      };
    }
    if (
      item.codigo === "TermoVistoria" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: getChildren(item, [
          "TermoVistoriaNovo",
          "TermoVistoriaListagem",
        ]),
      };
    }

    if (
      item.codigo === "TID" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: getChildren(item, ["TIDNovo", "TIDListagem"]),
      };
    }

    // if (
    //   item.codigo === "pessoa" &&
    //   permissoes?.some((perm) => perm.menu.id === item.id)
    // ) {
    //   return {
    //     ...infoGeral,
    //     icon: <FileOutlined />,
    //     children: getChildren(item, ["pessoaNovo", "pessoaListagem"]),
    //   };
    // }

    // if (
    //   item.codigo === "TermoDestruicao" &&
    //   permissoes?.some((perm) => perm.menu.id === item.id)
    // ) {
    //   return {
    //     ...infoGeral,
    //     icon: <FileOutlined />,
    //     children: getChildren(item, [
    //       "TermoDestruicaoNovo",
    //       "TermoDestruicaoListagem",
    //     ]),
    //   };
    // }

    if (
      item.codigo === "armadilha" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        icon: <FileOutlined />,
        children: getChildren(item, ["armadilhaNovo", "armadilhaListagem"]),
      };
    }

    if (
      item.codigo === "LASGAL" &&
      permissoes?.some((perm) => perm.menu.id === item.id)
    ) {
      return {
        ...infoGeral,
        key: 100,
        icon: <FileOutlined />,
        children: [
          {
            key: item.id,
            label: item.titulo,
            icon: <UnorderedListOutlined />,
            onClick: () => navigate(item.url),
          },
        ],
      };
    }

    return null;
  });

  const itemsMenu: ItemType[] = [
    ...menuOptions,
    {
      key: 50,
      icon: <FilePdfOutlined />,
      label: "Manual de Uso",
      onClick: () => {
        window.open(
          "https://drive.google.com/file/d/1a0Q4iahmt9_9nnnIdFvdEbyDMnsiEvRW/view?usp=drive_link"
        );
      },
    },
  ];

  const handleChangeInstallPwa = () => {
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      //console.log("da pra instalar");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler, false);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const handleChangeMenuSelect = ({ _, key }: any) => {
    atualizarMenuSelected([key]);
  };

  useEffect(() => {
    if (localStorage.getItem("push-notification")) {
      window.Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const notify = new Notification("Sincronizar dados", {
            body: "Gostaria de sincronizar dados para uso no modo Offline?",
            dir: "auto",
            tag: "SINCRONIZAR-DADOS",
          });

          notify.onclick = (e) => {
            e.preventDefault();
            atualizarMenuSelected(["2"]);
            window.location.href =
              process.env.REACT_APP_REDIRECT_URI + "/offline-mode";
          };

          notify.close();
        }
      });
      setTimeout(() => {
        localStorage.removeItem("push-notification");
      }, 1000);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Menu
      theme={isSidebar ? "dark" : "light"}
      mode={isSidebar ? "inline" : "vertical"}
      defaultSelectedKeys={["5"]}
      selectedKeys={menuSelected}
      onClick={handleChangeMenuSelect}
      items={
        isSidebar
          ? [
              ...itemsMenu,
              {
                key: 100,
                icon: <DownloadOutlined />,
                label: "Instalação",
                onClick: () => handleChangeInstallPwa(),
                disabled: !supportsPWA,
              },
            ]
          : itemsMenu
      }
    />
  );
}
