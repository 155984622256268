import { FilterOutlined } from "@ant-design/icons";
import { ButtonAplicarFiltro } from "@components/ButtonAplicarFiltro";
import { openNotification } from "@components/Notifications";
import { IFormValuesFiltroArmadilha } from "@interfaces/IFormValuesFiltroArmadilha";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  FormInstance,
  Input,
  Row,
  Space,
  Tooltip,
} from "antd";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OPTIONS_FILTRO } from "../../constants/OPTIONS_FILTRO";

export function FiltroArmadilha({
  formFiltro,
  setPageControl,
}: {
  formFiltro: FormInstance<IFormValuesFiltroArmadilha>;
  setPageControl: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filtroSelect, setFiltroSelect] = useState<string[]>([]);

  const showDrawer = () => {
    const itens: string[] = [];
    setIsModalOpen(true);

    if (searchParams.get("nomeMunicipio") !== null) {
      formFiltro.setFieldsValue({
        nomeMunicipio: searchParams.get("nomeMunicipio") || "",
      });
      itens.push("nomeMunicipio");
    }
    if (searchParams.get("codigoArmadilha")) {
      formFiltro.setFieldsValue({
        codigoArmadilha: searchParams.get("codigoArmadilha") || "",
      });
      itens.push("codigoArmadilha");
    }

    setFiltroSelect(itens);
  };

  const onClose = () => {
    setIsModalOpen(false);
    // setFilterSelect("");
    setFiltroSelect([]);
  };

  const handleChangeFiltro = (values: any[]) => {
    setFiltroSelect(values);
  };

  const handleSubmit = async (values: IFormValuesFiltroArmadilha) => {
    if (values.nomeMunicipio) {
      searchParams.set(
        "nomeMunicipio",
        values.nomeMunicipio.toUpperCase().trim()
      );
    } else {
      searchParams.delete("nomeMunicipio");
    }

    if (values.codigoArmadilha) {
      searchParams.set("codigoArmadilha", values.codigoArmadilha);
    } else {
      searchParams.delete("codigoArmadilha");
    }

    setSearchParams(searchParams);

    onClose();
    setPageControl(1);
  };

  return (
    <div>
      <Tooltip title="Filtro">
        <Button
          onClick={showDrawer}
          disabled={!navigator.onLine}
          style={{ boxShadow: "5px 5px 5px rgba(0,0,0,0.2)" }}
        >
          <FilterOutlined />
        </Button>
      </Tooltip>
      <Form
        id="form-filtro"
        layout="vertical"
        labelWrap
        form={formFiltro}
        onFinish={handleSubmit}
        onFinishFailed={(e) => {
          openNotification({
            type: "error",
            message: "Campos em branco",
            description: "Termine de preencher todos os campos obrigatórios",
          });
        }}
      >
        <Drawer
          title="Filtro de Armadilha(s)"
          placement="right"
          closable={false}
          onClose={onClose}
          open={isModalOpen}
          width={350}
          key={"right"}
          extra={
            <Space>
              <Button onClick={onClose}>Fechar</Button>
            </Space>
          }
        >
          <Checkbox.Group onChange={handleChangeFiltro} value={filtroSelect}>
            <Space direction="vertical">
              {OPTIONS_FILTRO.map((filtro) => {
                return (
                  <Checkbox key={filtro.value} value={filtro.value}>
                    {filtro.label}
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>

          <Divider />

          {filtroSelect.includes("nomeMunicipio") && (
            <Form.Item
              label="Digite o Nome do Municipio"
              name="nomeMunicipio"
              rules={[
                {
                  required: filtroSelect.includes("nomeMunicipio"),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}

          {filtroSelect.includes("codigoArmadilha") && (
            <Form.Item
              label="Digite o Código da armadilha"
              name="codigoArmadilha"
              rules={[
                {
                  required: filtroSelect.includes("codigoArmadilha"),
                  message: "Campo vazio",
                },
              ]}
            >
              <Input
                onChange={(e) =>
                  formFiltro.setFieldValue(
                    "codigoArmadilha",
                    e.target.value.trim()
                  )
                }
              />
            </Form.Item>
          )}

          <Row justify="center">
            <Space direction="vertical" size={10}>
              <ButtonAplicarFiltro
                isDisabled={filtroSelect.length === 0 || !navigator.onLine}
                isLoading={false}
              />
              <Col />
            </Space>
          </Row>
        </Drawer>
      </Form>
    </div>
  );
}
