import { IItemTermoApreensao } from "@interfaces/IItemTermoApreensao";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

interface IItensTALSlice {
  items: IItemTermoApreensao[];
}

const initialState: IItensTALSlice = {
  items: [],
};

const itensTALSlice = createSlice({
  name: "ITENS_TERMO_APREENSAO_LIBERACAO",
  initialState,
  reducers: {
    addItemTermoApreensao: (state, action) => {
      const { item } = action.payload;

      state.items.push(item);
    },
    updateItemTermoApreensao: (state, action) => {
      const { item } = action.payload;
      state.items = state.items.map((produto) => {
        if (produto.key === item.key) return item;

        return produto;
      });
    },
    removeItemTermoApreensao: (state, action) => {
      const { id } = action.payload;

      const itemsFilter = state.items.filter((item) => item.key !== id);
      state.items = itemsFilter;
    },
    removeAllItensTermoApreensao: (state, action) => {
      state.items = [];
    },
  },
});

export default itensTALSlice.reducer;

export const getItensTAL = (state: RootState) => state.itensTAL.items;
