import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { Button, Col, Empty, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTAL } from "pages/termoApreensaoLiberacao/hooks/useTAL";
import { ITabelaItens } from "../..";

export function TabelaItens({ dataTable }: { dataTable: ITabelaItens[] }) {
  const [modal, contextHolder] = Modal.useModal();
  const { removerItem } = useTAL();

  const columnItens: ColumnsType<ITabelaItens> = [
    {
      title: "Ações",
      key: "action",
      width: "5%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            danger
            onClick={(e) => {
              modal.confirm({
                title: "Tem certeza que deseja remover Item?",
                icon: <ExclamationCircleOutlined />,
                okText: "Sim",
                cancelText: "Não",
                onOk: () => {
                  removerItem(record.key);
                  openNotification({
                    type: "success",
                    message: "Item removido com sucesso!",
                  });
                },
              });
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
    {
      title: "Vol.",
      dataIndex: "volume",
      align: "center",
      width: "5%",
    },
    {
      title: "Quant. Apreendida",
      dataIndex: "quantidade",
      width: "5%",
      render: (qntd: number) => (
        <span>{qntd.toString().replace(".", ",")}</span>
      ),
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "total",
      width: "5%",
      align: "center",
    },
    {
      title: "Und. Medida",
      dataIndex: "unidadeMedida",
      width: "5%",
      align: "center",
    },
    {
      title: "Identificação",
      dataIndex: "identificacao",
      width: "15%",
      align: "center",
    },
    {
      title: "Tipo Embalagem",
      dataIndex: "tipoEmbalagem",
      align: "center",
      width: "10%",
    },
    {
      title: "Fabricante ou RENASEM",
      dataIndex: "fabricanteRenasem",
      align: "center",
      width: "10%",
    },
    {
      title: "Lote",
      dataIndex: "lote",
      align: "center",
      width: "5%",
    },
    {
      title: "Validade",
      dataIndex: "validade",
      align: "center",
      width: "10%",
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      width: "15%",
    },
    {
      title: "Tipo Produto",
      dataIndex: "tipoProduto",
      align: "center",
      width: "10%",
    },
  ];

  return (
    <>
      {contextHolder}
      <Col span={32}>
        <Table
          pagination={false}
          bordered
          columns={columnItens}
          dataSource={dataTable}
          locale={{
            emptyText: (
              <Empty
                description={"Sem dados"}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            ),
          }}
          style={{
            overflowX: "auto",
            paddingBottom: "20px",
          }}
        />
      </Col>
    </>
  );
}
