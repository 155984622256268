import { openNotification } from "@components/Notifications";
import { useArmadilha } from "@hooks/useArmadilha";
import { useEmbalagem } from "@hooks/useEmbalagem";
import { useEmpresasAgrotoxico } from "@hooks/useEmpresasAgrotoxico";
import { useEspecie } from "@hooks/useEspecie";
import { useProdutosAgrotoxicos } from "@hooks/useProdutosAgrotoxicos";
import { useUnidadeMedida } from "@hooks/useUnidadeMedida";
import { apiService } from "@services/api";
import { useTAL } from "pages/termoApreensaoLiberacao/hooks/useTAL";
import { useTCA } from "pages/termoColeta/hooks/useTCA";
import { useState } from "react";
import { DownloadingDadosTermo } from "../DownloadingDadosTermo";

export function SyncDadosTAL() {
  const { adicionarProdutos, removerProdutos } = useProdutosAgrotoxicos();
  const { adicionarUnidadesMedidas, removerUnidadesMedida } =
    useUnidadeMedida();
  const { adicionarEmbalagens, removerEmbalagens } = useEmbalagem();
  const { adicionarEmpresasAgrotoxico, removerEmpresasAgrotoxico } =
    useEmpresasAgrotoxico();
  const { removeEspecies, syncEspecies } = useEspecie({ isTCA: false });
  const {
    adicionarPersistedAtTermoApreensaoLiberacao,
    removerPersistedAtTermoApreensaoLiberacao,
    termoApreensaoLiberacaoPersistedAtItems,
  } = useTAL();
  const { termoColetaPersistedAtItems } = useTCA();
  const { persistedAtItemsSync } = useArmadilha();
  const [isDownloading, setIsDownloading] = useState(false);

  const termoApreensaoDownloadDados = async (action: string) => {
    const dateTimeExpire = Date.now();

    if (action === "adicionar" || action === "atualizar") {
      if (!navigator.onLine) {
        openNotification({
          type: "error",
          message: "Erro ao baixar informações",
          description:
            "Sem accesso a internet para baixar dados. Tente novamente mais tarde.",
        });
        return;
      }

      setIsDownloading(true);

      await Promise.allSettled([
        apiService.unidadeMedida(),
        apiService.produtosAgrotoxico(),
        apiService.embalagens(),
        apiService.empresasAgrotoxicos(),
        apiService.especie({ isAnaliseSemente: false }),
      ]).then((res) => {
        const requestRejected = res.filter(
          (data) => data.status === "rejected"
        );

        if (requestRejected.length > 0) {
          openNotification({
            type: "error",
            message: "Erro ao baixar informações",
            description:
              "Tente novamente, se o erro persistir entre em contato com o suporte",
          });
          return;
        }

        if (res[0].status === "fulfilled") {
          adicionarUnidadesMedidas(res[0].value, dateTimeExpire);
        }

        if (res[1].status === "fulfilled") {
          adicionarProdutos(res[1].value, dateTimeExpire);
        }

        if (res[2].status === "fulfilled") {
          adicionarEmbalagens(res[2].value, dateTimeExpire);
        }

        if (res[3].status === "fulfilled") {
          adicionarEmpresasAgrotoxico(res[3].value, dateTimeExpire);
        }

        if (res[4].status === "fulfilled") {
          syncEspecies(res[4].value, dateTimeExpire);
        }
        adicionarPersistedAtTermoApreensaoLiberacao(dateTimeExpire);

        openNotification({
          type: "success",
          message: `Dados para o Termo de Apreensão/Liberação ${
            action === "adicionar" ? "sincronizados" : "atualizados"
          } com Sucesso!`,
        });
      });

      setIsDownloading(false);
    } else {
      removerUnidadesMedida();
      removerProdutos();
      removerEmbalagens();
      removerEmpresasAgrotoxico();
      termoColetaPersistedAtItems === 0 &&
        persistedAtItemsSync === 0 &&
        removeEspecies();
      removerPersistedAtTermoApreensaoLiberacao();

      openNotification({
        type: "success",
        message:
          "Dados sincronizados do Termo de Apreensão/Liberação removidos com Sucesso!",
      });
    }
  };

  return (
    <DownloadingDadosTermo
      title="Dados para o TAL:"
      dadosTermoDownloading={isDownloading}
      handleChange={termoApreensaoDownloadDados}
      isDataDownloaded={termoApreensaoLiberacaoPersistedAtItems > 0}
      timeExpireData={termoApreensaoLiberacaoPersistedAtItems}
      tipoTermo="TAL"
      listaDadosSync={[
        "Espécies",
        "Unidades de Medida",
        "Produtos",
        "Embalagens",
      ]}
    />
  );
}
