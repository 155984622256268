import { IEspecie } from "@interfaces/IEspecie";
import { apiService } from "@services/api";
import { especieAction } from "@store/actions/especieAction";
import { getEspecies } from "@store/feature/especieSlice";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

export const useEspecie = ({ isTCA }: { isTCA: boolean }) => {
  const appDispatch = useDispatch();
  const listaEspeciesSync = useSelector(getEspecies);
  const { addEspecies, removeEspecies: removeRedux } = especieAction;

  const { data: listaEspecies } = useQuery({
    queryKey: ["ESPECIE"],
    initialData: listaEspeciesSync,
    queryFn: () => apiService.especie({ isAnaliseSemente: isTCA }),
    enabled: navigator.onLine,
  });

  const syncEspecies = (especies: IEspecie[], expireTime: number) => {
    appDispatch(addEspecies(especies, expireTime));
  };

  const removeEspecies = () => {
    appDispatch(removeRedux());
  };

  return {
    listaEspecies,
    syncEspecies,
    removeEspecies,
    isEspecieSync: listaEspeciesSync.length > 0,
  };
};
