import { CheckOutlined } from "@ant-design/icons";
import { List, Modal, ModalProps } from "antd";

interface Props extends ModalProps {
  listaDadosSync: String[];
  tipoTermo: "TIF" | "TCA" | "TAL" | "TV" | "Cadastro Armadilha";
}

export function ModalDetalhesSync({
  listaDadosSync,
  tipoTermo,
  ...props
}: Props) {
  return (
    <>
      <Modal
        {...props}
        title={`Dados sincronizados ${tipoTermo}`}
        footer={null}
      >
        <List
          bordered
          dataSource={listaDadosSync}
          renderItem={(item, ind) => (
            <List.Item key={ind}>
              <List.Item.Meta title={item} />

              <CheckOutlined />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
}
