import { EnvironmentOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { formatMessageError } from "@functions/formatMessageError";
import { masks } from "@functions/mascaras";
import { transformStringToNumber } from "@functions/transformStringToNumber";
import { useArmadilha } from "@hooks/useArmadilha";
import { useEspecie } from "@hooks/useEspecie";
import { useMunicipio } from "@hooks/useMunicipio";
import { apiService } from "@services/api";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Select,
  Tooltip,
  Typography,
} from "antd";
import moment, { Moment } from "moment";
import { useState } from "react";

interface IFormvaluesArmadilha {
  codigo: string;
  dataFixacao: Moment;
  hospedeiroId: number;
  municipioId: number;
  tipoArmadilha: "JAKSON" | "MCPHAIL";
  localizacao: string;
  tipoImovel: "URBANO" | "RURAL";
  latGrau: string;
  latMin: string;
  latSeg: string;
  longGrau: string;
  longMin: string;
  longSeg: string;
  isAtivo: boolean;
  dataRemocao: Moment;
  motivoRemocao: string;
}

export function Armadilha() {
  const [form] = Form.useForm<IFormvaluesArmadilha>();
  const { municipiosMt } = useMunicipio();
  const { adicionarArmadilhaToSync } = useArmadilha();
  const { listaEspecies } = useEspecie({ isTCA: false });
  const { coordMask } = masks;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: IFormvaluesArmadilha) => {
    const armadilha = {
      codigo: values.codigo,
      dataFixacao: moment(values.dataFixacao).format("YYYY-MM-DD"),
      grauLat: transformStringToNumber(values.latGrau),
      grauLong: transformStringToNumber(values.longGrau),
      minLat: transformStringToNumber(values.latMin),
      minLong: transformStringToNumber(values.longMin),
      segLat: transformStringToNumber(values.latSeg),
      segLong: transformStringToNumber(values.longSeg),
      localidade: values.localizacao,
      tipoImovel: values.tipoImovel,
      tipoArmadilha: values.tipoArmadilha,
      especie:
        listaEspecies?.find((especie) => especie.id === values.hospedeiroId) ||
        null,
      municipio:
        municipiosMt?.find(
          (municipio) => municipio.id === values.municipioId
        ) || null,
    };

    try {
      if (navigator.onLine) {
        message.loading({
          content: "Cadastrando armadilha...",
          key: "armadilha",
        });
        setIsLoading(true);
        await apiService.armadilha.enviar(armadilha);
        setIsLoading(false);
        message.destroy("armadilha");

        openNotification({
          type: "success",
          message: "Armadilha cadastrada com sucesso!",
        });

        form.resetFields();

        return;
      }

      adicionarArmadilhaToSync(armadilha);

      openNotification({
        type: "success",
        message: "Armadilha criada com sucesso!",
        description:
          "Salvamento da armadilha pendente, para quando aplicativo houver conexão com a internet.",
      });

      form.resetFields();
    } catch (error) {
      console.log(error);
      message.destroy("armadilha");
      setIsLoading(false);
      const messageError = formatMessageError(error);
      openNotification({
        type: "error",
        message: "Não foi possivel cadastrar Armadilha",
        description: messageError,
      });
    }
  };

  const handleCompleteGeoLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition((location) => {
        let latDeg, latMin, latSeg, longDeg, longMin, longSeg;

        let valLat = Math.abs(location.coords.latitude);
        let valLong = Math.abs(location.coords.longitude);

        latDeg = Math.floor(valLat);
        latMin = Math.floor((valLat - latDeg) * 60);
        latSeg =
          Math.round((valLat - latDeg - latMin / 60) * 3600 * 1000) / 1000;

        longDeg = Math.floor(valLong);
        longMin = Math.floor((valLong - longDeg) * 60);
        longSeg =
          Math.round((valLong - longDeg - longMin / 60) * 3600 * 1000) / 1000;

        form.setFieldsValue({
          latGrau: coordMask(latDeg.toString() || ""),
          latMin: coordMask(latMin.toString() || ""),
          latSeg: coordMask(latSeg.toString() || ""),
          longGrau: coordMask(longDeg.toString() || ""),
          longMin: coordMask(longMin.toString() || ""),
          longSeg: coordMask(longSeg.toString() || ""),
        });
      });
    } catch (error) {
      openNotification({
        type: "error",
        message: "Não foi possivel utilizar localização atual",
        description:
          "Verifique se o navegador permitiu a utilização da localização.",
      });
    }
  };

  return (
    <>
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Armadilha
      </Typography.Title>

      <Divider />
      <Form
        form={form}
        onFinish={handleSubmit}
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "150px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
      >
        <Form.Item name="codigo" label="Código" rules={[{ required: true }]}>
          <Input placeholder="informe o código" />
        </Form.Item>
        <Form.Item
          name="dataFixacao"
          label="Data Fixação"
          rules={[{ required: true }]}
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            disabledDate={(date) => {
              if (date.valueOf() > Date.now()) {
                return true;
              }
              return false;
            }}
          />
        </Form.Item>
        <Form.Item
          name="tipoArmadilha"
          label="Tipo de Armadilha"
          rules={[{ required: true }]}
        >
          <Select placeholder="Selecione um tipo de armadilha">
            <Select.Option value="JAKSON">JAKSON</Select.Option>
            <Select.Option value="MCPHAIL">McPhail</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="hospedeiroId"
          label="Hospedeiro"
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input) ||
              (option!.children as unknown as string).includes(
                input.toUpperCase()
              )
            }
            placeholder="Selecione um hospedeiro"
          >
            {listaEspecies?.map((especie) => (
              <Select.Option key={especie.id} value={especie.id}>
                {especie.nomeComum}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="localizacao"
          label="Localização"
          rules={[{ required: true }]}
        >
          <Input placeholder="informe a localização" />
        </Form.Item>
        <Form.Item
          name="municipioId"
          label="Municipio"
          rules={[{ required: true }]}
        >
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input) ||
              (option!.children as unknown as string).includes(
                input.toUpperCase()
              )
            }
            placeholder="Selecione um municipio"
          >
            {municipiosMt.map((municipio) => (
              <Select.Option key={municipio.id} value={municipio.id}>
                {municipio.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="tipoImovel"
          label="Tipo de Imóvel"
          rules={[{ required: true }]}
        >
          <Select placeholder="Selecione um tipo de imovel">
            <Select.Option value="URBANO">Urbano</Select.Option>
            <Select.Option value="RURAL">Rural</Select.Option>
          </Select>
        </Form.Item>

        <Typography.Title level={3}>Coordenadas</Typography.Title>

        <Tooltip title="Usar localização atual">
          <Button
            id="button-localizacao-atual"
            onClick={handleCompleteGeoLocation}
          >
            <EnvironmentOutlined />
          </Button>
        </Tooltip>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
          >
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "150px",
              }}
            >
              Latitude:
            </Typography>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
            <Form.Item
              label="Grau"
              name="latGrau"
              labelAlign="left"
              style={{ maxWidth: "150px" }}
              rules={[{ required: true }]}
            >
              <Input
                maxLength={5}
                onChange={(e) => {
                  form.setFieldValue("latGrau", coordMask(e.target.value));
                }}
              />
            </Form.Item>

            <Form.Item
              label="Min"
              name="latMin"
              rules={[{ required: true }]}
              style={{ maxWidth: "150px" }}
            >
              <Input
                maxLength={5}
                onChange={(e) => {
                  form.setFieldValue("latMin", coordMask(e.target.value));
                }}
              />
            </Form.Item>

            <Form.Item
              label="Seg"
              name="latSeg"
              rules={[{ required: true }]}
              style={{ maxWidth: "150px" }}
            >
              <Input
                maxLength={5}
                onChange={(e) => {
                  form.setFieldValue("latSeg", coordMask(e.target.value));
                }}
              />
            </Form.Item>
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            style={{ display: "flex", alignItems: "center", minWidth: "150px" }}
          >
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "150px",
              }}
            >
              Longitude:
            </Typography>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
            <Form.Item
              label="Grau"
              name="longGrau"
              rules={[{ required: true }]}
              labelAlign="left"
              style={{ maxWidth: "150px" }}
            >
              <Input
                maxLength={5}
                onChange={(e) => {
                  form.setFieldValue("longGrau", coordMask(e.target.value));
                }}
              />
            </Form.Item>

            <Form.Item
              label="Min"
              name="longMin"
              rules={[{ required: true }]}
              labelAlign="left"
              style={{ maxWidth: "150px" }}
            >
              <Input
                maxLength={5}
                onChange={(e) => {
                  form.setFieldValue("longMin", coordMask(e.target.value));
                }}
              />
            </Form.Item>

            <Form.Item
              label="Seg"
              name="longSeg"
              rules={[{ required: true }]}
              labelAlign="left"
              style={{ maxWidth: "150px" }}
            >
              <Input
                maxLength={5}
                onChange={(e) => {
                  form.setFieldValue("longSeg", coordMask(e.target.value));
                }}
              />
            </Form.Item>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button htmlType="submit" type="primary" disabled={isLoading}>
            Salvar
          </Button>
        </div>
      </Form>
    </>
  );
}
