import { IUnidadeMedida } from "@interfaces/IUnidadeMedida";
import { apiService } from "@services/api";
import { unidadeMedidaAction } from "@store/actions/unidadeMedidaAction";
import { getUnidadeMedida } from "@store/feature/unidadeMedidaSlice";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

export const useUnidadeMedida = () => {
  const appDispatch = useDispatch();
  const listaUnidadeMedidaSync = useSelector(getUnidadeMedida);
  const { addUnidadeMedida, removeUnidadeMedida } = unidadeMedidaAction;

  const { data: listaUnidadeMedida } = useQuery({
    queryKey: ["UNIDADE_MEDIDA"],
    initialData: listaUnidadeMedidaSync,
    queryFn: () => apiService.unidadeMedida(),
  });

  const adicionarUnidadesMedidas = (
    unidades: IUnidadeMedida[],
    persistedAt: number
  ) => {
    appDispatch(addUnidadeMedida(unidades, persistedAt));
  };

  const removerUnidadesMedida = () => {
    appDispatch(removeUnidadeMedida());
  };

  return {
    listaUnidadeMedida,
    adicionarUnidadesMedidas,
    removerUnidadesMedida,
  };
};
