import { openNotification } from "@components/Notifications";
import { useAreaAtividades } from "@hooks/useAreaAtividades";
import { apiService } from "@services/api";
import { useTIF } from "pages/tif/hooks/useTIF";
import { useState } from "react";
import { DownloadingDadosTermo } from "../DownloadingDadosTermo";

export function SyncDadosTIF() {
  const { adicionarProgramas, removerProgramas } = useAreaAtividades();
  const {
    tifPersistedAtItems,
    removerPersistedAtTif,
    adicionarPersistedAtTif,
  } = useTIF();

  const [isDownloading, setIsDownloading] = useState(false);

  const tifDownloadDados = async (action: string) => {
    const dateTimeExpire = Date.now();

    if (action === "adicionar" || action === "atualizar") {
      if (!navigator.onLine) {
        openNotification({
          type: "error",
          message: "Erro ao baixar informações",
          description:
            "Sem accesso a internet para baixar dados. Tente novamente mais tarde.",
        });
        return;
      }

      setIsDownloading(true);

      await Promise.allSettled([apiService.programas()]).then((res) => {
        const requestRejected = res.filter(
          (data) => data.status === "rejected"
        );

        if (requestRejected.length > 0) {
          openNotification({
            type: "error",
            message: `Erro ao ${
              action === "adicionar" ? "baixar" : "atualizar"
            } informações`,
            description:
              "Tente novamente, se o erro persistir entre em contato com o suporte",
          });
          return;
        }

        adicionarPersistedAtTif(dateTimeExpire);

        if (res[0].status === "fulfilled") {
          adicionarProgramas(res[0].value, dateTimeExpire);
        }

        openNotification({
          type: "success",
          message: `Dados para o TIF ${
            action === "adicionar" ? "sincronizados" : "atualizados"
          } com Sucesso!`,
        });
      });

      setIsDownloading(false);
    } else {
      removerProgramas();
      removerPersistedAtTif();

      openNotification({
        type: "success",
        message: "Dados sincronizados do TIF removidos com Sucesso!",
      });
    }
  };

  return (
    <DownloadingDadosTermo
      title="Dados para o TIF:"
      dadosTermoDownloading={isDownloading}
      handleChange={tifDownloadDados}
      isDataDownloaded={tifPersistedAtItems > 0}
      timeExpireData={tifPersistedAtItems}
      listaDadosSync={["Area de atividades"]}
      tipoTermo="TIF"
    />
  );
}
