import { AxiosError } from "axios";

export function formatMessageError(error: unknown) {
  if (error instanceof AxiosError) {
    return `Erro ${error.response?.status}: ${error.response?.data?.detail}.`;
  }

  if (error instanceof Error) {
    return `Erro: ${error.message}`;
  }

  return "Erro desconhecido. Favor entrar em contato com o suporte";
}
