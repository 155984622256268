import { CloseCircleOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { formatMessageError } from "@functions/formatMessageError";
import { IArmadilha } from "@interfaces/IArmadilha";
import { apiService } from "@services/api";
import {
  Button,
  DatePicker,
  Form,
  Input,
  List,
  message,
  Modal,
  Tooltip,
} from "antd";
import { Moment } from "moment";
import { useState } from "react";

interface FormValues {
  dataRemocao: Moment;
  motivoRemocao: string;
}

export function ItemListaArmadilha({ item }: { item: IArmadilha }) {
  const [form] = Form.useForm<FormValues>();
  const [armadilhaRemove, setArmadilhaRemove] = useState<IArmadilha | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleInativar = async ({ dataRemocao, motivoRemocao }: FormValues) => {
    try {
      setIsLoading(true);
      message.loading({
        content: "Inativando armadilha...",
        key: "loading",
      });
      console.log(dataRemocao, motivoRemocao);

      await apiService.armadilha.inativar(item.id, {
        motivo: motivoRemocao,
        dataRemocao: dataRemocao.format("YYYY-MM-DD"),
      });

      setArmadilhaRemove(null);
      message.destroy("loading");
      setIsLoading(false);
      openNotification({
        type: "success",
        message: "Armadilha inativada com sucesso!",
      });

      window.location.reload();
    } catch (error) {
      message.destroy("loading");
      setIsLoading(false);
      console.log(error);
      const messageError = formatMessageError(error);
      openNotification({
        type: "error",
        message: "Não foi possivel inativar armadilha",
        description: messageError,
      });
    }
  };

  return (
    <>
      <List.Item key={item.id}>
        <List.Item.Meta
          title={
            <span
              className="titulo-card"
              style={{ opacity: item.ativo ? 1 : 0.7 }}
            >
              Armadilha {item.codigo} {!item.ativo && "(Inativa)"}
            </span>
          }
          description={
            <span className="subtitulo-card">{item.municipio.nome}</span>
          }
        />

        <div>
          <Tooltip title="Inativar">
            <Button
              danger
              disabled={!navigator.onLine || !item.ativo}
              onClick={() => setArmadilhaRemove(item)}
            >
              <CloseCircleOutlined />
            </Button>
          </Tooltip>
        </div>
      </List.Item>

      <Modal
        open={armadilhaRemove !== null}
        onCancel={() => {
          setArmadilhaRemove(null);
        }}
        title={
          <h1
            style={{
              fontSize: 18,
              textAlign: "center",
              marginBottom: 0,
              padding: "0px 15px",
            }}
          >
            Inativar Armadilha
          </h1>
        }
        okText="Sim"
        cancelText="Nao"
        cancelButtonProps={{
          htmlType: "reset",
          form: "form",
          disabled: isLoading,
        }}
        okButtonProps={{
          form: "form",
          htmlType: "submit",
          disabled: isLoading,
        }}
      >
        <Form id="form" onFinish={handleInativar} form={form} layout="vertical">
          <Form.Item
            label="Data Remoção"
            style={{ width: "100%" }}
            name={"dataRemocao"}
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}
          >
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              disabledDate={(date) => {
                if (date.valueOf() > Date.now()) {
                  return true;
                }
                return false;
              }}
            />
          </Form.Item>

          <Form.Item
            label="Motivo"
            style={{ width: "100%" }}
            name={"motivoRemocao"}
            rules={[{ required: true }]}
            labelCol={{ span: 24 }}
          >
            <Input.TextArea maxLength={200} showCount rows={3} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
