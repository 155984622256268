import { IFormValuesTV } from "@interfaces/IFormValuesTV";
import { IListagemFormularioPerguntas } from "@interfaces/IListagemFormularioPerguntas";
import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { Divider, Form, FormInstance, Select, Typography } from "antd";
import { Alternativa } from "./components/Alternativa";
import { Campo } from "./components/Campo";
import { Descritiva } from "./components/Descritiva";
import { MultiplaEscolha } from "./components/MultiplaEscolha";
import styles from "./style.module.css";

interface Props {
  form: FormInstance<IFormValuesTV>;
  perguntas: IPerguntaTermoVistoria[];
  setPerguntas: React.Dispatch<React.SetStateAction<IPerguntaTermoVistoria[]>>;
  listaPerguntas: IListagemFormularioPerguntas[];
}

export function PerguntaTV({
  form,
  perguntas,
  setPerguntas,
  listaPerguntas,
}: Props) {
  const handleChangeForm = (value: number | undefined) => {
    for (let i = 0; i < perguntas.length; i++) {
      form.setFieldValue(
        `listaPerguntas.${perguntas[i].pergunta.id}.respostaAlternativa`,
        ""
      );

      perguntas[i].pergunta.campos.forEach((_, ind) => {
        form.setFieldValue(
          `listaPerguntas.${perguntas[i].pergunta.id}.campos.${ind}.resposta`,
          ""
        );
      });

      form.setFieldValue(
        `listaPerguntas.${perguntas[i].pergunta.id}.descricao`,
        ""
      );
    }

    if (!value) {
      setPerguntas([]);
      return;
    }
    listaPerguntas.forEach((formPerguntas) => {
      if (formPerguntas.id === value) {
        let auxPerg = formPerguntas.formularioPerguntas.filter(
          (perg) => perg.pergunta.antecessora === null
        );

        // console.log(auxPerg);
        auxPerg.sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }

          if (a.order > b.order) {
            return 1;
          }

          return 0;
        });

        console.log(auxPerg);

        setPerguntas(
          auxPerg.map((perg, ind) => {
            return { ...perg, indPergunta: `${ind + 1}` };
          })
        );
      }
    });
  };

  const handleAnswerQuestion = (
    respostas: number[],
    pergunta: IPerguntaTermoVistoria
  ) => {
    const tipoEstabelecimentoId = form.getFieldValue("tipoEstabelecimentoId");
    let auxPerguntas: IPerguntaTermoVistoria[] = [];

    // SEPARAR O FORMULARIO DE PERGUNTAS UTILIZADO
    const formSelected = listaPerguntas.find(
      (formPergunta) => formPergunta.id === tipoEstabelecimentoId
    )?.formularioPerguntas;

    if (!formSelected) return;

    // PEGAR AS PERGUNTAS DEPENDENTES QUE PODEM TER DE ACORDO COM A RESPOSTA DADA
    let perguntasDependentes = formSelected.filter(
      (perg) =>
        perg.pergunta.antecessora?.id === pergunta.pergunta.id &&
        respostas.includes(perg.pergunta.respostaCondicional?.id as number)
    );

    //verificando se pergunta ja foi mostrada
    perguntasDependentes = perguntasDependentes.filter((pergunta) => {
      return perguntas.findIndex((aux) => aux.id === pergunta.id) === -1;
    });

    let filterPerguntas = perguntas.filter((perg) => {
      if (
        perg.pergunta.antecessora?.id === pergunta.pergunta.id &&
        !respostas.includes(perg.pergunta.respostaCondicional?.id as number)
      ) {
        form.setFieldValue(
          `listaPerguntas.${perg.pergunta.id}.respostaAlternativa`,
          ""
        );

        form.setFieldValue(
          `listaPerguntas.${perg.pergunta.id}.respostaMultiplaEscolha`,
          ""
        );

        perg.pergunta.campos.forEach((_, ind) => {
          form.setFieldValue(
            `listaPerguntas.${perg.pergunta.id}.campos.${ind}.resposta`,
            ""
          );
        });

        form.setFieldValue(`listaPerguntas.${perg.pergunta.id}.descricao`, "");

        return null;
      }

      return perg;
    });

    auxPerguntas = filterPerguntas.concat(
      perguntasDependentes
        .sort((a, b) => a.order - b.order)
        .map((perg, indice) => ({
          ...perg,
          indPergunta: `${pergunta.indPergunta}.${indice + 1}`,
        }))
    );

    auxPerguntas.sort((a, b) => a.order - b.order);

    filterPerguntas = auxPerguntas.filter((perg, ind, todasPerguntas) => {
      if (perg.pergunta.antecessora) {
        if (
          todasPerguntas.findIndex(
            (aux) => aux.pergunta.id === perg.pergunta.antecessora?.id
          ) === -1
        ) {
          return null;
        }

        return perg;
      }

      return perg;
    });

    setPerguntas(filterPerguntas);
  };

  return (
    <>
      <Form.Item
        label="Tipo Estabelecimento"
        name="tipoEstabelecimentoId"
        rules={[{ required: true }]}
      >
        <Select
          style={{ minWidth: "190px" }}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input) ||
            (option!.children as unknown as string).includes(
              input.toUpperCase()
            )
          }
          placeholder="Selecione um formulário"
          onChange={handleChangeForm}
        >
          {listaPerguntas.map((form, ind) => {
            if (form.ativo) {
              return (
                <Select.Option value={form.id} key={ind}>
                  {form.nome}
                </Select.Option>
              );
            }

            return null;
          })}
        </Select>
      </Form.Item>

      {perguntas.length > 0 && (
        <Typography.Title level={3}>Perguntas</Typography.Title>
      )}

      <div className={styles["container-list-question"]}>
        {perguntas.map((perg, ind) => {
          return (
            <div key={ind}>
              <div className={styles["container-title-question"]}>
                <Typography.Title level={5}>
                  {perg.indPergunta}. {perg.pergunta.nome}
                </Typography.Title>
              </div>

              {perg.pergunta.tipoResposta === "ALTERNATIVA" && (
                <Alternativa
                  onAnswerQuestion={handleAnswerQuestion}
                  question={perg}
                />
              )}

              {perg.pergunta.tipoResposta === "MULTIPLA_ESCOLHA" && (
                <MultiplaEscolha
                  question={perg}
                  onAnswerQuestion={handleAnswerQuestion}
                />
              )}

              {perg.pergunta.tipoResposta === "DESCRITIVA" && (
                <Descritiva question={perg} form={form} />
              )}

              {perg.pergunta.tipoResposta === "ALTERNATIVA_DESCRITIVA" && (
                <>
                  <Alternativa
                    question={perg}
                    onAnswerQuestion={handleAnswerQuestion}
                  />
                  <Descritiva question={perg} form={form} />
                </>
              )}

              {perg.pergunta.tipoResposta === "MULTIPLA_ESCOLHA_DESCRITIVA" && (
                <>
                  <MultiplaEscolha
                    question={perg}
                    onAnswerQuestion={handleAnswerQuestion}
                  />
                  <Descritiva question={perg} form={form} />
                </>
              )}

              <Campo form={form} question={perg} />

              <Divider />
            </div>
          );
        })}
      </div>
    </>
  );
}
