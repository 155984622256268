import { ExclamationCircleOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { TabelaServidoresForm } from "@components/TabelaServidoresForm";
import { UploadFilesForm } from "@components/UploadFilesForm";
import { ASSINATURA_FISICA } from "@constants/ASSINATURA_FISICA";
import { INITIAL_VALUES_ASSINATURA } from "@constants/INITIAL_VALUES_ASSINATURA";
import { converterBase64ToFile } from "@functions/converterBase64ToFile";
import { formatarData } from "@functions/formatarData";
import { formatMessageError } from "@functions/formatMessageError";
import { getAnexosFormatadosBase64 } from "@functions/getAnexosFormatadosBase64";
import { masks } from "@functions/mascaras";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { useForms } from "@hooks/useForms";
import { useMunicipio } from "@hooks/useMunicipio";
import { useServidores } from "@hooks/useServidores";
import { IAnexos } from "@interfaces/IAnexos";
import { IAssinante } from "@interfaces/IAssinante";
import { IFormValuesTAL } from "@interfaces/IFormValuesTAL";
import { IContentListagemTIF } from "@interfaces/IListagemTIF";
import { IMunicipio } from "@interfaces/IMunicipio";
import { IServidorForm } from "@interfaces/IServidorForm";
import { ITermoApreensaoLiberacao } from "@interfaces/ITermoApreensaoLiberacao";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Result,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import confirm from "antd/lib/modal/confirm";
import fs from "file-saver";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import { TermoApreensaoSubmited } from "../../../../@types/TermoApreensaoSubmited";
import { AssinaturaTAL } from "../../components/AssinaturaTAL";
import { DadosFiscalizacaoTAL } from "../../components/DadosFiscalizacaoTAL";
import { FiscalizadoRegistroTAL } from "../../components/FiscalizadoRegistroTAL";
import { ItensTAL } from "../../components/ItensTAL";
import { RegistroDepositarioTAL } from "../../components/RegistroDepositarioTAL";
import { formatToFormData } from "../../functions/formatToFormData";
import { resetarDepositarioFields } from "../../functions/resetarDepositarioFields";
import { useTAL } from "../../hooks/useTAL";

export function EdicaoTAL() {
  const [form] = Form.useForm<IFormValuesTAL>();
  const { numTermo } = useParams();
  const {
    adicionarTermo,
    removerTermo,
    atualizarTermo,
    listaTermos,
    adicionarItem,
    removerTodosItems,
    listaItens,
    getTermoApreensao,
  } = useTAL();
  const termoApreensaoLiberacao = listaTermos.find(
    (termo) => termo.numero === numTermo?.replaceAll("_", "/")
  );
  const [formServidoresEdit] = Form.useForm();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const { messageSubmitFailed } = useForms();
  const { municipiosBrasil, getMunicipioPorId } = useMunicipio();
  const {
    servidoresSelecionados,
    selecionarServidor,
    removerTodosServidoresSelecionados,
  } = useServidores();

  const { cepMask, cpfCnpjMask, telefoneMask } = masks;

  const [isSavingTermo, setIsSavingTermo] = useState(false);
  const [isProprio, setIsProprio] = useState(true);
  const [isTifExisist, setIsTifExisist] = useState(true);
  const [isValidDocument, setIsValidDocument] = useState(false);
  const [isLiberacao, setIsLiberacao] = useState(false);
  const [hasTermoApreensao, setHasTermoApreensao] = useState(false);
  const [isAssinadoFisicamente, setIsAssinadoFisicamente] = useState(false);
  const [termoApreensaoSelected, setTermoApreensaoSelected] =
    useState<ITermoApreensaoLiberacao | null>(null);
  const [anexo, setAnexo] = useState<any[]>([]);
  const [tifSelected, setTifSelected] = useState<any>();
  const [servidores, setServidores] = useState<IServidorForm[]>([]);
  const [municipioDepositarioOptions, setMunicipioDepositarioOptions] =
    useState<IMunicipio[]>([]);
  const [motivoNaoAssinaturaDepositario, setMotivoNaoAssinaturaDepositario] =
    useState<CheckboxValueType[]>([]);
  const [motivoNaoAssinaturaFiscalizado, setMotivoNaoAssinaturaFiscalizado] =
    useState<CheckboxValueType[]>([]);
  const [checkBoxAssinaturaFisica, setCheckBoxAssinaturaFisica] = useState<
    CheckboxValueType[]
  >([]);
  const [fiscalizadoSignature, setFiscalizadoSignature] = useState<IAssinante>(
    INITIAL_VALUES_ASSINATURA
  );
  const [depositarioSignature, setDepositarioSignature] = useState<IAssinante>(
    INITIAL_VALUES_ASSINATURA
  );

  const handleSearchTermoApreensao = async (numTermoApreensao: string) => {
    if (!form.getFieldValue("termo")) {
      openNotification({
        type: "error",
        message: "Erro ao buscar Termo de Apreensão.",
        description:
          "Preencha se o termo é de Apreensão ou Liberação para prosseguir.",
      });
      return;
    }

    const { termo: termoApreensao, message } = await getTermoApreensao(
      numTermoApreensao.trim()
    );

    if (!termoApreensao) {
      openNotification({
        type: "info",
        message: "Não foi possivel buscar Termo de Apreensão",
        description: message,
      });
      setHasTermoApreensao(false);
      setIsTifExisist(false);
      setIsProprio(true);
      return;
    }

    termoApreensao.itens.forEach((item, ind) =>
      adicionarItem({
        ...item,
        key: ind,
        dataValidade: item.dataValidade?.includes("/")
          ? item.dataValidade
          : moment(item.dataValidade).format("DD/MM/YYYY"),
      })
    );

    setTermoApreensaoSelected(termoApreensao);
    form.setFieldsValue({
      numTIF: termoApreensao.tif?.numero,
      dataTIF: termoApreensao.dataFiscalizacao?.includes("/")
        ? moment(termoApreensao.dataFiscalizacao, "DD/MM/YYYY")
        : moment(termoApreensao.dataFiscalizacao),
      nomeFiscalizado: termoApreensao.nomeFiscalizado,
      cpfCnpjFiscalizado: cpfCnpjMask(termoApreensao.cpfCnpjFiscalizado),
      ufFiscalizadoId: termoApreensao.municipioFiscalizado?.uf.id,
      municipioFiscalizadoId: termoApreensao.municipioFiscalizado?.id,
      enderecoFiscalizado: termoApreensao.enderecoFiscalizado,
      cepFiscalizado: cepMask(termoApreensao.cepFiscalizado),
      telefoneFiscalizado: telefoneMask(termoApreensao.telefoneFiscalizado),
      emailFiscalizado: termoApreensao.emailFiscalizado,
      municipioFiscalizacaoId: termoApreensao.municipioFiscalizacao?.id,
      depositario: termoApreensao.cpfCnpjDepositario ? "TERCEIRO" : "PROPRIO",
      nomeDepositario: termoApreensao.nomeDepositario,
      cpfCnpjDepositario: cpfCnpjMask(termoApreensao.cpfCnpjDepositario),
      ufDepositarioId: termoApreensao.municipioDepositario?.uf.id,
      municipioDepositarioId: termoApreensao.municipioDepositario?.id,
      enderecoDepositario: termoApreensao.enderecoDepositario,
      cepDepositario: termoApreensao.cepDepositario,
      emailDepositario: termoApreensao.emailDepositario,
      telefoneDepositario: termoApreensao.telefoneDepositario,
      rgDepositario: termoApreensao.inscricaoEstadualDepositario,
      renasemDepositario: termoApreensao.renasemDepositario,
      rgFiscalizado: termoApreensao.inscricaoEstadualFiscalizado,
      renasemFiscalizado: termoApreensao.renasemFiscalizado,
    });

    if (termoApreensao.cpfCnpjDepositario) {
      setIsValidDocument(true);
    }

    setMunicipioDepositarioOptions(
      municipiosBrasil.filter(
        (municipio) =>
          municipio.uf.id === termoApreensao.municipioDepositario?.uf.id
      )
    );

    setIsProprio(!termoApreensao.cpfCnpjDepositario);

    setHasTermoApreensao(true);
    setIsTifExisist(true);
    setTifSelected(termoApreensao.tif);

    setFiscalizadoSignature({
      assinatura: null,
      nomeAssinante: termoApreensao.nomeAssinanteFiscalizado,
      funcaoAssinante: termoApreensao.funcaoAssinanteFiscalizado,
      cpfAssinante: termoApreensao.cpfAssinanteFiscalizado,
    });

    setDepositarioSignature({
      assinatura: null,
      nomeAssinante: termoApreensao.nomeAssinanteDepositario,
      funcaoAssinante: termoApreensao.funcaoAssinanteDepositario,
      cpfAssinante: termoApreensao.cpfAssinanteDepositario,
    });
  };

  const handleChangeNumTermoApreensao = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;

    form.resetFields([
      "numTIF",
      "nomeFiscalizado",
      "cpfCnpjFiscalizado",
      "ufFiscalizadoId",
      "municipioFiscalizadoId",
      "rgFiscalizado",
      "renasemFiscalizado",
      "enderecoFiscalizado",
      "cepFiscalizado",
      "telefoneFiscalizado",
      "emailFiscalizado",
      "municipioFiscalizacaoId",
      "depositario",
      "cpfCnpjDepositario",
      "nomeDepositario",
      "ufDepositarioId",
      "municipioDepositarioId",
      "rgDepositario",
      "renasemDepositario",
      "enderecoDepositario",
      "cepDepositario",
      "telefoneDepositario",
      "emailDepositario",
      "atuadoDepositario",
    ]);

    form.setFieldsValue({
      dataTIF: moment(),
      numTermoApreensao: value,
    });

    setIsProprio(true);
    setHasTermoApreensao(false);
    setIsTifExisist(false);
  };

  const handleChangeTermo = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setIsTifExisist(false);
    setHasTermoApreensao(false);
    setIsProprio(true);
    setIsValidDocument(false);
    removerTodosItems();
    setIsLiberacao(value === "LIBERACAO");

    form.resetFields();
    form.setFieldValue("termo", value);
  };

  const handleChangeTipoDepositario = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setIsProprio(value === "PROPRIO");

    if (value === "PROPRIO") {
      setIsValidDocument(false);
      resetarDepositarioFields(form);
      setDepositarioSignature(INITIAL_VALUES_ASSINATURA);
    }
  };

  const handleSubmit = async (values: IFormValuesTAL) => {
    const keyMessage = "termo-apreensao-liberacao";

    if (!termoApreensaoLiberacao) return;

    //VALIDAÇÕES
    if (!tifSelected) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Adicione um TIF válido ao Termo.",
      });
      return;
    }

    if (listaItens.length === 0) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Adicione ao menos um item para finalizar o Termo.",
      });
      return;
    }

    if (servidoresSelecionados.length === 0) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Adicione ao menos um servidor",
      });
      return;
    }

    if (
      (!fiscalizadoSignature.nomeAssinante ||
        !fiscalizadoSignature.cpfAssinante ||
        !fiscalizadoSignature.funcaoAssinante) &&
      motivoNaoAssinaturaFiscalizado.length === 0
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Preencha os dados do assinante Fiscalizado",
      });

      return;
    }

    if (
      (!depositarioSignature.nomeAssinante ||
        !depositarioSignature.cpfAssinante ||
        !depositarioSignature.funcaoAssinante) &&
      motivoNaoAssinaturaDepositario.length === 0 &&
      !isProprio
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Preencha os dados do assinante Depositario",
      });

      return;
    }

    if (
      !hasServidorAssinatura() ||
      !hasAssinatura(
        motivoNaoAssinaturaFiscalizado[0],
        fiscalizadoSignature.assinatura
      ) ||
      (!isProprio &&
        !hasAssinatura(
          motivoNaoAssinaturaDepositario[0],
          depositarioSignature.assinatura
        ))
    ) {
      openNotification({
        type: "info",
        message: "Não foi possivel salvar Termo de Apreensão/Liberação.",
        description: "Preencha as assinaturas pendentes",
      });
      return;
    }

    const tifSubmited: IContentListagemTIF = {
      ativo: tifSelected.ativo,
      dataFiscalizacao:
        moment(tifSelected.dataFiscalizacao).locale("pt-br").format("L") ===
        "Data inválida"
          ? tifSelected.dataFiscalizacao
          : moment(tifSelected.dataFiscalizacao).locale("pt-br").format("L"),
      id: tifSelected.id,
      municipioFiscalizacao: tifSelected.municipioFiscalizacao,
      nomeFiscalizado: tifSelected.nomeFiscalizado,
      nomePropriedade: tifSelected.nomePropriedade,
      numero: tifSelected.numero,
      statusAssinatura: tifSelected.statusAssinatura,
      enderecoPropriedade: tifSelected.enderecoPropriedade || "",
    };

    const termoApreensaoSubmited: TermoApreensaoSubmited =
      termoApreensaoSelected
        ? {
            amparoLegal: termoApreensaoSelected.amparoLegal,
            dataFiscalizacao:
              moment(termoApreensaoSelected.dataFiscalizacao).format(
                "DD/MM/YYYY"
              ) === "Data inválida"
                ? termoApreensaoSelected.dataFiscalizacao
                : moment(termoApreensaoSelected.dataFiscalizacao).format(
                    "DD/MM/YYYY"
                  ),
            id: termoApreensaoSelected.id,
            municipioFiscalizacao: termoApreensaoSelected.municipioFiscalizacao,
            nomeFiscalizado: termoApreensaoSelected.nomeFiscalizado,
            produto: termoApreensaoSelected.produto,
            numero: termoApreensaoSelected.numero,
            providencias: termoApreensaoSelected.providencias,
            tipoTermo: termoApreensaoSelected.tipoTermo,
          }
        : null;

    const servidorSelected = servidores.map((serv) => {
      return {
        assinatura:
          getStatusAssinatura(motivoNaoAssinaturaFiscalizado) ===
          ASSINATURA_FISICA
            ? ""
            : serv.assinatura,
        servidor: {
          cpfCnpj: serv.servidor.cpfCnpj,
          id: serv.servidor.id,
          inscricaoEstadual: serv.servidor.inscricaoEstadual,
          matricula: serv.servidor.matricula,
          nome: serv.servidor.nome,
        },
      };
    });

    const formatItensToTermoApreensaoLiberacao = listaItens.map((item) => {
      return {
        ...item,
        dataValidade:
          moment(item.dataValidade).format("DD/MM/YYYY") === "Data inválida"
            ? ""
            : moment(item.dataValidade).format("DD/MM/YYYY"),
      };
    });

    let termo: ITermoApreensaoLiberacao = {
      produto: "",
      descricao: "",
      itens: formatItensToTermoApreensaoLiberacao,
      numero: termoApreensaoLiberacao.numero,
      termoApreensao: termoApreensaoSubmited,
      tipoTermo: values.termo,
      nomeDepositario: values.nomeDepositario || "",
      nomeFiscalizado: values.nomeFiscalizado || "",
      cpfCnpjDepositario: values.cpfCnpjDepositario
        ? values.cpfCnpjDepositario.replace(/[^\d]+/g, "")
        : "",
      cpfCnpjFiscalizado: values.cpfCnpjFiscalizado
        ? values.cpfCnpjFiscalizado.replace(/[^\d]+/g, "")
        : "",
      emailDepositario: values.emailDepositario || "",
      emailFiscalizado: values.emailFiscalizado || "",
      telefoneDepositario: values.telefoneDepositario
        ? values.telefoneDepositario.replace(/[^\d]+/g, "")
        : "",
      telefoneFiscalizado: values.telefoneFiscalizado
        ? values.telefoneFiscalizado?.replace(/[^\d]+/g, "")
        : "",
      cepDepositario: values.cepDepositario || "",
      cepFiscalizado: values.cepFiscalizado || "",
      inscricaoEstadualDepositario: values.rgDepositario || "",
      inscricaoEstadualFiscalizado: values.rgFiscalizado || "",
      renasemDepositario: values.renasemDepositario || "",
      renasemFiscalizado: values.renasemFiscalizado || "",
      municipioDepositario: getMunicipioPorId(values.municipioDepositarioId),
      municipioFiscalizado: getMunicipioPorId(values.municipioFiscalizacaoId),
      municipioFiscalizacao: getMunicipioPorId(values.municipioFiscalizacaoId),
      assinaturaDepositario:
        motivoNaoAssinaturaDepositario.length === 0
          ? depositarioSignature.assinatura?.getTrimmedCanvas().toDataURL() ||
            ""
          : "",
      assinaturaFiscalizado:
        motivoNaoAssinaturaFiscalizado.length === 0
          ? fiscalizadoSignature?.assinatura?.getTrimmedCanvas().toDataURL() ||
            ""
          : "",
      nomeAssinanteDepositario: depositarioSignature.nomeAssinante,
      nomeAssinanteFiscalizado: fiscalizadoSignature.nomeAssinante,
      cpfAssinanteDepositario: depositarioSignature.cpfAssinante?.replace(
        /[^\d]+/g,
        ""
      ),
      cpfAssinanteFiscalizado: fiscalizadoSignature.cpfAssinante.replace(
        /[^\d]+/g,
        ""
      ),
      funcaoAssinanteDepositario: depositarioSignature.funcaoAssinante,
      funcaoAssinanteFiscalizado: fiscalizadoSignature.funcaoAssinante,
      tif: tifSubmited,
      amparoLegal: values.amparoLegal || "",
      providencias: values.providencias || "",
      servidores: servidorSelected,
      statusAssinaturaDepositario: isProprio
        ? "ASSINADO_DIGITALMENTE"
        : getStatusAssinatura(motivoNaoAssinaturaDepositario),
      statusAssinaturaFiscalizado: getStatusAssinatura(
        motivoNaoAssinaturaFiscalizado
      ),
      enderecoDepositario: values.enderecoDepositario || "",
      enderecoFiscalizado: values.enderecoFiscalizado || "",
      dataFiscalizacao: moment(values.dataTIF).format("DD/MM/YYYY"),
      localDeposito: values.enderecoDepositario || "",
      anexos: [],
    };

    let anexosSubmitToTermo: (File | undefined)[] = [];

    if (anexo.length > 0) {
      await getAnexosFormatadosBase64(anexo).then((res) => {
        termo = { ...termo, anexos: res };

        const aux = res.map((anexo) => {
          return converterBase64ToFile(anexo.arquivo, anexo.nomeArquivo);
        });

        anexosSubmitToTermo = aux.filter((anexo) => anexo !== undefined);
      });
    }

    const formData = formatToFormData(termo, anexosSubmitToTermo);

    const tipoTermoFormatado =
      termo.tipoTermo === "APREENSAO" ? "Apreensão" : "Liberação";
    try {
      if (navigator.onLine) {
        setIsSavingTermo(true);
        message.loading({
          key: keyMessage,
          duration: 0,
          content: "Salvando Termo...",
        });

        await apiService.termoApreensaoLiberacao
          .enviar(formData)
          .then((res) => {
            atualizarTermo({ ...res.data, anexos: termo.anexos });
            setIsSavingTermo(false);
            message.destroy(keyMessage);
            openNotification({
              type: "success",
              message: `Termo de ${tipoTermoFormatado} Atualizado com sucesso!`,
            });
            navigate("/termo-apreensao-liberacao/listagem");
          });
      } else {
        removerTermo(termoApreensaoLiberacao.numero);
        adicionarTermo(termo);

        modal.info({
          title: `Gostaria de baixar um backup do Termo de ${tipoTermoFormatado}?`,
          icon: <ExclamationCircleOutlined />,
          okText: "Sim",
          onOk: () => {
            let blob = new Blob([JSON.stringify(termo)], {
              type: "text/plain;charset=utf-8",
            });
            fs.saveAs(
              blob,
              `Termo de ${tipoTermoFormatado} ${termo.numero}.txt`,
              {
                autoBom: true,
              }
            );
            openNotification({
              type: "success",
              message: `Termo de ${tipoTermoFormatado} Atualizado com sucesso!`,
              description: `Salvamento da edição do Termo de ${tipoTermoFormatado} pendente, para quando aplicativo houver conexão com a internet.`,
            });

            navigate("/termo-apreensao-liberacao/listagem");
          },
        });
      }
    } catch (error) {
      console.log(error);
      setIsSavingTermo(false);
      message.destroy(keyMessage);
      const messageError = formatMessageError(error);
      openNotification({
        type: "error",
        message: `Não foi possivel editar Termo de ${tipoTermoFormatado}`,
        description: messageError,
      });
    }
  };

  const hasAssinatura = (
    motivoNaoAssinatura: CheckboxValueType,
    assinatura: ReactSignatureCanvas | null
  ) => {
    if (motivoNaoAssinatura === ASSINATURA_FISICA) {
      return true;
    }

    if (
      (assinatura?.toData().length === 0 || !assinatura) &&
      !motivoNaoAssinatura
    ) {
      return false;
    }

    return true;
  };
  const hasServidorAssinatura = () => {
    if (motivoNaoAssinaturaFiscalizado[0] === ASSINATURA_FISICA) return true;

    const isSignatureServEmpty = servidores.some((serv) => !serv.assinatura);

    return !isSignatureServEmpty;
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (e.key === "Enter") {
      document.getElementById(id)?.focus();
    }
  };

  const modalConfirm = () => {
    confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        removerTodosServidoresSelecionados();
        removerTodosItems();
        navigate("/termo-apreensao-liberacao/listagem");
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  const formatAnexos = (anexos: IAnexos[]) => {
    const auxAnexos = anexos.map((anexo) => {
      return {
        uid: anexo.nomeArquivo,
        name: anexo.nomeArquivo,
        status: "done",
        url: anexo.arquivo,
      };
    });

    return auxAnexos;
  };

  useEffect(() => {
    document.title = "Edição Termo Apreensão/Liberação | SISDEV-mobile";

    if (termoApreensaoLiberacao) {
      removerTodosServidoresSelecionados();
      removerTodosItems();
      setFiscalizadoSignature({
        nomeAssinante: termoApreensaoLiberacao.nomeAssinanteFiscalizado,
        cpfAssinante: termoApreensaoLiberacao.cpfAssinanteFiscalizado,
        assinatura: null,
        funcaoAssinante: termoApreensaoLiberacao.funcaoAssinanteFiscalizado,
      });
      setTifSelected(termoApreensaoLiberacao.tif);

      if (termoApreensaoLiberacao.termoApreensao) {
        const auxTermo = listaTermos.filter(
          (termo) =>
            termo.numero === termoApreensaoLiberacao.termoApreensao?.numero &&
            termo.tipoTermo === "APREENSAO"
        );

        setTermoApreensaoSelected(auxTermo[0]);
      }

      if (termoApreensaoLiberacao.anexos.length > 0) {
        setAnexo(formatAnexos(termoApreensaoLiberacao.anexos));
      }

      if (termoApreensaoLiberacao.tipoTermo === "LIBERACAO") {
        form.setFieldValue(
          "numTermoApreensao",
          termoApreensaoLiberacao.termoApreensao?.numero
        );
        setIsLiberacao(true);
        setHasTermoApreensao(true);
        setIsTifExisist(true);
      }

      if (termoApreensaoLiberacao.itens) {
        termoApreensaoLiberacao.itens.forEach((item: any) => {
          adicionarItem({
            key: Date.now(),
            ...item,
          });
        });
      }

      if (termoApreensaoLiberacao.cpfCnpjDepositario) {
        setIsProprio(false);
        setIsValidDocument(true);
        setDepositarioSignature({
          nomeAssinante: termoApreensaoLiberacao.nomeAssinanteDepositario,
          cpfAssinante: termoApreensaoLiberacao.cpfAssinanteDepositario,
          assinatura: null,
          funcaoAssinante: termoApreensaoLiberacao.funcaoAssinanteDepositario,
        });
      }

      termoApreensaoLiberacao.servidores.map((servidor) => {
        selecionarServidor(servidor);
        return servidor;
      });

      setMunicipioDepositarioOptions(
        municipiosBrasil.filter(
          (municipio) =>
            municipio.uf.id ===
            termoApreensaoLiberacao.municipioDepositario?.uf.id
        )
      );

      form.setFieldsValue({
        termo: termoApreensaoLiberacao.tipoTermo,
        // numTermoApreensao: termoApreensao.termoApreensao.numero,
        numTIF: termoApreensaoLiberacao.tif?.numero,
        dataTIF: moment(formatarData(termoApreensaoLiberacao.dataFiscalizacao)),
        nomeFiscalizado: termoApreensaoLiberacao.nomeFiscalizado,
        cpfCnpjFiscalizado: cpfCnpjMask(
          termoApreensaoLiberacao.cpfCnpjFiscalizado || ""
        ),
        ufFiscalizadoId: termoApreensaoLiberacao.municipioFiscalizado?.uf.id,
        municipioFiscalizadoId:
          termoApreensaoLiberacao.municipioFiscalizado?.id,
        rgFiscalizado: termoApreensaoLiberacao.inscricaoEstadualFiscalizado,
        renasemFiscalizado: termoApreensaoLiberacao.renasemFiscalizado,
        enderecoFiscalizado: termoApreensaoLiberacao.enderecoFiscalizado,
        cepFiscalizado: cepMask(termoApreensaoLiberacao.cepFiscalizado || ""),
        telefoneFiscalizado: telefoneMask(
          termoApreensaoLiberacao.telefoneFiscalizado || ""
        ),
        emailFiscalizado: termoApreensaoLiberacao.emailFiscalizado,
        depositario: termoApreensaoLiberacao.cpfCnpjDepositario
          ? "TERCEIRO"
          : "PROPRIO",
        cpfCnpjDepositario: cpfCnpjMask(
          termoApreensaoLiberacao.cpfCnpjDepositario || ""
        ),
        nomeDepositario: termoApreensaoLiberacao.nomeDepositario,
        ufDepositarioId: termoApreensaoLiberacao.municipioDepositario
          ? termoApreensaoLiberacao.municipioDepositario.uf.id
          : "",
        municipioDepositarioId: termoApreensaoLiberacao.municipioDepositario
          ? termoApreensaoLiberacao.municipioDepositario.id
          : "",
        rgDepositario: termoApreensaoLiberacao.inscricaoEstadualDepositario,
        renasemDepositario: termoApreensaoLiberacao.renasemDepositario,
        enderecoDepositario: termoApreensaoLiberacao.enderecoDepositario,
        cepDepositario: termoApreensaoLiberacao.cepDepositario,
        emailDepositario: termoApreensaoLiberacao.emailDepositario,
        telefoneDepositario: termoApreensaoLiberacao.telefoneDepositario,
        municipioFiscalizacaoId:
          termoApreensaoLiberacao.municipioFiscalizacao?.id,
        amparoLegal: termoApreensaoLiberacao.amparoLegal,
        providencias: termoApreensaoLiberacao.providencias,
      });
    }

    //eslint-disable-next-line
  }, []);

  if (!termoApreensaoLiberacao) {
    return (
      <>
        <Result
          status="error"
          title="Erro ao buscar Termo de Apreensão/Liberação"
          subTitle="Por favor tente novamente, se o erro persistir entre em contato com o suporte."
        />
      </>
    );
  }

  return (
    <Fragment>
      {contextHolder}
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Termo de Apreensão/ Liberação (Edição)
      </Typography.Title>

      <Divider />

      <Form
        id="form"
        form={form}
        onFinish={handleSubmit}
        onReset={modalConfirm}
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "200px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onFinishFailed={messageSubmitFailed}
      >
        <Form.Item label="Termo" name="termo" rules={[{ required: true }]}>
          <Radio.Group onChange={handleChangeTermo}>
            <Radio value="APREENSAO">Apreensão</Radio>
            <Radio value="LIBERACAO">Liberação</Radio>
          </Radio.Group>
        </Form.Item>

        {isLiberacao && (
          <Form.Item
            label="N° Termo de Apreensao"
            name="numTermoApreensao"
            rules={[{ required: isLiberacao }]}
          >
            <Input.Search
              id="numTermoApreensao"
              enterButton="Buscar"
              placeholder="Digite o N° do Termo de Apreensão"
              onSearch={handleSearchTermoApreensao}
              onChange={handleChangeNumTermoApreensao}
              onKeyDown={(e) => handleKeyPress(e, "numTermo")}
            />
          </Form.Item>
        )}

        <FiscalizadoRegistroTAL
          form={form}
          isTifExisist={isTifExisist}
          setFiscalizadoSignature={setFiscalizadoSignature}
          setIsTifExisist={setIsTifExisist}
          setTifSelected={setTifSelected}
          isLiberacaoFound={isLiberacao && !hasTermoApreensao}
        />

        <Divider />

        <Typography.Title level={3}>Depositário</Typography.Title>
        <Form.Item label="Tipo" name="depositario" rules={[{ required: true }]}>
          <Radio.Group onChange={handleChangeTipoDepositario}>
            <Radio value="PROPRIO">Próprio</Radio>
            <Radio value="TERCEIRO">Terceiro</Radio>
          </Radio.Group>
        </Form.Item>

        {!isProprio && (
          <RegistroDepositarioTAL
            isTifExisist={isTifExisist}
            isProprio={isProprio}
            formApreensao={form}
            depositarioSignature={depositarioSignature}
            setDepositarioSignature={setDepositarioSignature}
            isValidDocument={isValidDocument}
            setIsValidDocument={setIsValidDocument}
            municipioDepositarioOptions={municipioDepositarioOptions}
            setMunicipioDepositarioOptions={setMunicipioDepositarioOptions}
            isEdit
          />
        )}

        <Divider />

        <DadosFiscalizacaoTAL isTifExisting={isTifExisist} />

        <Divider />

        <UploadFilesForm setAnexos={setAnexo} anexos={anexo} />

        <Divider />

        <Form.Item label="Amparo Legal" name="amparoLegal">
          <Input.TextArea maxLength={5000} showCount rows={4} />
        </Form.Item>

        <Form.Item label="Providências" name="providencias">
          <Input.TextArea maxLength={5000} showCount rows={4} />
        </Form.Item>
      </Form>

      <ItensTAL isLiberacao={isLiberacao} />

      <Divider />
      <TabelaServidoresForm form={formServidoresEdit} />

      <AssinaturaTAL
        ativarModalInformarNumTermo={() => {}}
        formApreensao={form}
        servidores={servidores}
        setServidores={setServidores}
        fiscalizadoSignature={fiscalizadoSignature}
        setFiscalizadoSignature={setFiscalizadoSignature}
        motivoNaoAssinaturaFiscalizado={motivoNaoAssinaturaFiscalizado}
        setMotivoNaoAssinaturaFiscalizado={setMotivoNaoAssinaturaFiscalizado}
        depositarioSignature={depositarioSignature}
        setDepositarioSignature={setDepositarioSignature}
        motivoNaoAssinaturaDepositario={motivoNaoAssinaturaDepositario}
        setMotivoNaoAssinaturaDepositario={setMotivoNaoAssinaturaDepositario}
        isProprio={isProprio}
        checkBoxAssinaturaFisica={checkBoxAssinaturaFisica}
        setCheckBoxAssinaturaFisica={setCheckBoxAssinaturaFisica}
        isAssinadoFisicamente={isAssinadoFisicamente}
        setIsAssinadoFisicamente={setIsAssinadoFisicamente}
      />

      <ButtonFooterContainer>
        <Button form="form" htmlType="reset" disabled={isSavingTermo}>
          Cancelar
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          form="form"
          disabled={isSavingTermo}
        >
          Salvar
        </Button>
      </ButtonFooterContainer>
    </Fragment>
  );
}
