import { ICategoria } from "@interfaces/ICategoria";
import { apiService } from "@services/api";
import { categoriaAction } from "@store/actions/categoriaAction";
import { getCategorias } from "@store/feature/categoriaSlice";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

export const useCategoriaSemente = () => {
  const appDispatch = useDispatch();
  const listaCategoriaSementeSync = useSelector(getCategorias);
  const { addCategorias, removeCategorias: removeCategoriaRedux } =
    categoriaAction;

  const { data: listaCategoriaSementes } = useQuery({
    queryKey: ["CATEGORIA_SEMENTE"],
    initialData: listaCategoriaSementeSync,
    queryFn: () => apiService.categorias(),
    onSuccess: (data) => data,
  });

  const syncCategorias = (categoria: ICategoria[], persistedAt: number) => {
    appDispatch(addCategorias(categoria, persistedAt));
  };

  const removeCategorias = () => {
    appDispatch(removeCategoriaRedux());
  };

  return {
    listaCategoriaSementes,
    syncCategorias,
    removeCategorias,
    isCategoriaSync: listaCategoriaSementeSync.length > 0,
  };
};
