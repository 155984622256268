import { CACHE_VERSION } from "@constants/CACHE_VERSION";
import { Button, Typography } from "antd";
import { iconsLogo } from "../../icons";
import styles from "./style.module.css";

export function Login({ singIn }: { singIn: () => void }) {
  return (
    <>
      <div className={styles["login-panel"]}>
        <div className={styles["login-form"]}>
          <Typography className={styles["titulo-sisdev"]}>
            SISDEV-mobile
          </Typography>
          <img
            src={iconsLogo[4].image}
            alt={iconsLogo[4].name}
            className={styles["login-logo"]}
          />

          <Button type="primary" onClick={singIn}>
            Entrar
          </Button>
        </div>
      </div>
      <div className={styles["container-cache-version"]}>
        Versão {CACHE_VERSION}
      </div>
    </>
  );
}
