import { openNotification } from "@components/Notifications";
import { generateId } from "@functions/generateId";
import { masks } from "@functions/mascaras";
import { useEstabelecimento } from "@hooks/useEstabelecimento";
import { useMunicipio } from "@hooks/useMunicipio";
import { usePropriedade } from "@hooks/usePropriedade";
import { useUle } from "@hooks/useUle";
import { IAssinante } from "@interfaces/IAssinante";
import { IFitoAmostrasPDF } from "@interfaces/IFitoAmostrasPDF";
import { IFormValuesTermoColeta } from "@interfaces/IFormValuesTermoColeta";
import { IOficialAmostrasPDF } from "@interfaces/IOficialAmosrasPDF";
import { ITermoColetaAmostras } from "@interfaces/ITermoColetaAmostras";
import { Button, FormInstance } from "antd";
import moment from "moment";
import { useTCA } from "pages/termoColeta/hooks/useTCA";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { FITOSSANITARIA, OFICIAL } from "../../constants";
import { FitoImpressao } from "../ImpressaoTermoColeta/FitoImpressao";
import { OficialImpressao } from "../ImpressaoTermoColeta/OficialImpressao";

interface IPreviaPdfProps {
  formColeta: FormInstance;
  getAmostrador: (cpfCnpj: string) => any;
  assinaturaFiscalizado: IAssinante;
}

export function PreviaTermoColeta({
  formColeta,
  getAmostrador,
  assinaturaFiscalizado,
}: IPreviaPdfProps) {
  const fitoRef = useRef(null);
  const oficialTermoRef = useRef(null);
  const { listaUles } = useUle();
  const { listaAmostras, getEspecieSelected } = useTCA();
  const { getMunicipioPorId } = useMunicipio();
  const { buscarPropriedadePeloCpfCnpj } = usePropriedade();
  const { buscarEstabelecimentoPeloCnpj } = useEstabelecimento();
  const { coordMask } = masks;

  const [printedTermo, setPrintedTermo] = useState<ITermoColetaAmostras | null>(
    null
  );
  const [oficialAmostras, setOficialAmostras] = useState<IOficialAmostrasPDF[]>(
    []
  );
  const [fitoAmostras, setFitoAmostras] = useState<IFitoAmostrasPDF[]>([]);

  const handlePreviewTermo = async () => {
    const values: IFormValuesTermoColeta = formColeta.getFieldsValue();

    try {
      const cpfCnpj = values.cpfCnpjOrigem.replace(/[^\d]+/g, "");
      const amostrador = getAmostrador(
        values.matriculaAmostrador.replace(/\D/g, "") || ""
      );
      const especieSelected = getEspecieSelected(values.especieId);

      const uleSelect = listaUles.filter(
        (ule) =>
          ule.municipio.id === values.municipioColetaId &&
          ule.tipoUnidade === "UL"
      )[0];

      const numTermoColeta = `${generateId()}/${uleSelect.codigo.slice(
        uleSelect.codigo.length - 3
      )}/${moment().year()}`;

      let formValues: ITermoColetaAmostras = {
        cpfAssinante: assinaturaFiscalizado.cpfAssinante,
        funcaoAssinante: assinaturaFiscalizado.funcaoAssinante,
        nomeAssinante: assinaturaFiscalizado.nomeAssinante,
        assinaturaFiscalizado: "",
        assinaturaAmostrador: "",
        statusAssinatura: "ASSINADO_FISICAMENTE",
        dispensadoColetaDuplicata: values.declaracoes
          ? values.declaracoes.some(
              (declaracao: string) => declaracao === "dispensado"
            )
          : false,
        recebidoColetaDuplicata: values.declaracoes
          ? values.declaracoes.some(
              (declaracao: string) => declaracao === "recebido"
            )
          : false,
        propriedade: null,
        produtor: null,
        municipioColeta: getMunicipioPorId(values.municipioColetaId),
        destino: values.destino,
        nomeLaboratorio: values.nomeLaboratorio || "",
        termoFiscalizacao: values.numeroTIF || "",
        dataColeta: moment(values.dataColeta)
          .locale("pt-br")
          .format()
          .slice(0, 10),
        especie: especieSelected,
        safra: values.safra || "",
        tipoColeta: values.tipoAmostra,
        renasemOrigemSementes: values.renasemOrigem || "",
        paisOrigem: values.paisOrigem || "",
        dadosTratamentoSementes: values.dadosAgrotoxicos || "",
        observacao: values.observacoes || "",
        termoColetaAmostra: numTermoColeta || "",
        itens: listaAmostras,
        dataImpressao: moment().locale("pt-br").format("YYYY-MM-DD[T]HH:mm:ss"),
        nomeArquivo: `Termo de Coleta ${numTermoColeta}`,
        cpfCnpjRequerente: cpfCnpj,
        nomeRequerente: values.nomeOrigem,
        enderecoRequerente: "",
        requerente: null,
        creaAmostrador: "",
        nomeAmostrador: amostrador ? amostrador.nome : "",
        amostrador: amostrador,
        municipio: null,
        nomeUpload: `Termo de Coleta ${numTermoColeta}`,
        codigo: 0,
        dar: "",
        renasemMatriculaAmostrador: formColeta.getFieldValue(
          "matriculaAmostrador"
        ),
        situacao: "ANALISADA",
        tipo: "FISCALIZAÇÃO",
        plantaVoluntaria: false,
      };

      if (values.propriedadeId && !values.estabelecimentoId) {
        const dadosProdutor = await buscarPropriedadePeloCpfCnpj(
          values.cpfCnpjOrigem.replace(/[^\d]+/g, "")
        );
        const propriedade = dadosProdutor.find(
          (prop) => prop.id === values.propriedadeId
        );

        formValues = {
          ...formValues,
          propriedade: propriedade || null,
          produtor: propriedade?.produtores[0],
          municipio: propriedade?.municipio || null,
        };
      } else {
        const estabelecimento = await buscarEstabelecimentoPeloCnpj(
          values.cpfCnpjOrigem.replace(/[^\d]+/g, "")
        );

        formValues = {
          ...formValues,
          produtor: estabelecimento[0],
          municipio: estabelecimento[0].endereco.municipio,
        };
      }

      setPrintedTermo(formValues);

      if (values.tipoAmostra === OFICIAL) {
        const auxOficial = listaAmostras.map((amostra) => {
          return {
            numAmostra: amostra.nrAmostraLasgal,
            cultivar: amostra.cultivar?.nome,
            categoria: amostra.categoria?.nome,
            lote: amostra.lote,
            qtdEmbalagens: amostra.qtdEmbalagens,
            valorEmbalagens: amostra.valorEmbalagem,
            pesoEmbalagens: amostra.pesoEmbalagem,
            validadeTesteGerminacao: amostra.validadeTesteGerminacao,
            pureza: amostra.pureza,
            germinacao: amostra.germinacao,
            tetrazolio: amostra.tetrazolio,
            sementesDuras: amostra.sementesDura,
            outrosFatores: amostra.valorOutrosFatores,
          };
        });

        setOficialAmostras(auxOficial);
      } else {
        const auxFito = listaAmostras.map((amostra) => {
          return {
            numAmostra: amostra.nrAmostraLsv,
            latitude: `${coordMask(amostra.latGrau.toString())}°${coordMask(
              amostra.latMin.toString()
            )}'${coordMask(amostra.latSeg.toString())}"`,
            longitude: `${coordMask(amostra.longGrau.toString())}°${coordMask(
              amostra.longMin.toString()
            )}'${coordMask(amostra.longSeg.toString())}"`,
            dataPlantio: amostra.dataPlantio,
            cultivar: amostra.cultivar?.nome,
            estadio: amostra.estadio?.nome,
            qtdColetada: amostra.qtdEmbalagens,
            partePlanta: amostra.partePlanta?.nome,
            pragas: "",
          };
        });

        setFitoAmostras(auxFito);
      }
    } catch (error) {
      console.log(error);
      openNotification({
        type: "error",
        message: "Não foi possivel gerar prévia do termo de coleta",
        description:
          "Verifique se os campos do termo de coleta estão preenchidos corretamente.",
      });
    }
  };

  const handleAfterPrint = useCallback(() => {
    setPrintedTermo(null);
    // tslint:disable-line no-console
  }, []);

  const handlePrint = useReactToPrint({
    contentRef:
      printedTermo?.tipoColeta === FITOSSANITARIA ? fitoRef : oficialTermoRef,
    documentTitle: `Termo de coleta ${printedTermo?.termoColetaAmostra}`,
    pageStyle: `@page { size: landscape A3 !important;}`,
    suppressErrors: true,
    onAfterPrint: handleAfterPrint,
    onPrintError: (errorLocation, error) => {
      openNotification({
        type: "error",
        message: "Não foi possivel fazer impressão desse Termo de Coleta",
      });
    },
  });

  useEffect(() => {
    if (printedTermo) {
      handlePrint();
    }
    //eslint-disable-next-line
  }, [printedTermo]);

  return (
    <Fragment>
      <Button onClick={handlePreviewTermo}>Gerar prévia PDF</Button>

      <div style={{ display: "none" }}>
        {printedTermo?.tipoColeta === FITOSSANITARIA && (
          <FitoImpressao
            ref={fitoRef}
            termo={printedTermo}
            listaAmostras={fitoAmostras}
            marcaDagua="MINUTA"
          />
        )}

        {printedTermo?.tipoColeta === OFICIAL && (
          <OficialImpressao
            ref={oficialTermoRef}
            termo={printedTermo}
            listaAmostras={oficialAmostras}
            marcaDagua="MINUTA"
          />
        )}
      </div>
    </Fragment>
  );
}
