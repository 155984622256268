import { masks } from "@functions/mascaras";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import moment from "moment";
import styled from "styled-components";

const ContainerCSS = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  height: 2vh;
  border-top: 1px solid black;
`;

const ContentFiscalizacaoCSS = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

export function Footer({
  termo,
}: {
  termo: ITermoVistoria;
}) {
  const { cpfCnpjMask } = masks;

  return (
    <>
      <tfoot
        style={{
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          borderBottom: "1px solid black",
        }}
      >
        <tr>
          <td>
            <ContainerCSS style={{ display: "flex" }}>
              <ContentFiscalizacaoCSS
                style={{
                  width: "50%",
                  borderRight: "1px solid black",
                }}
              >
                <span>Municipio: {termo.municipioFiscalizado?.nome}</span>
              </ContentFiscalizacaoCSS>
              <ContentFiscalizacaoCSS
                style={{
                  width: "25%",
                  borderRight: "1px solid black",
                }}
              >
                <span>
                  Data: {moment(termo.dataCadastro).format("DD/MM/YYYY")}
                </span>
              </ContentFiscalizacaoCSS>
              <ContentFiscalizacaoCSS style={{ width: "25%" }}>
                <span>
                  Horário:{" "}
                  {termo.dataCadastro && termo.dataCadastro.slice(11, 16)}
                </span>
              </ContentFiscalizacaoCSS>
            </ContainerCSS>

            <div
              style={{
                display: "flex",
                gap: 20,
                padding: 20,
                justifyContent: "center",
              }}
            >
              <div>
                {termo?.statusAssinaturaServidor === "ASSINADO_FISICAMENTE" ? (
                  <div
                    style={{
                      width: 300,
                      height: "2.5cm",
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                      borderBottom: "1px solid black",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: 300,
                      borderBottom: "1px solid black",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="4cm"
                      height="2.5cm"
                      viewBox="0 0 645 600"
                      enableBackground="new 0 0 645 600"
                      xmlSpace="preserve"
                    >
                      <image
                        id="image0"
                        width="17cm"
                        height="15.875cm"
                        x="0"
                        y="0"
                        href={termo?.assinaturaServidor}
                      />
                    </svg>
                  </div>
                )}

                <div
                  style={{
                    width: 300,
                    textAlign: "center",
                    fontSize: 10,
                    height: 40,
                  }}
                >
                  Assinatura do do Responsável pela Vistoria
                </div>

                <div
                  style={{
                    border: "1px solid black",
                    padding: "0px 4px",
                    width: 300,
                    fontSize: 11,
                  }}
                >
                  <div style={{ borderBottom: "1px solid black" }}>
                    Nome:{termo.servidor?.pessoa.nome}
                  </div>
                  <div style={{ borderBottom: "1px solid black" }}>
                    Função:Servidor
                  </div>
                  <div style={{ borderBottom: "1px solid black" }}>
                    Matrícula:{termo?.servidor?.matricula}
                  </div>
                  <div>
                    ULE:{termo?.servidor?.ule ? termo?.servidor.ule.nome : ""}
                  </div>
                </div>
              </div>

              <div>
                {termo?.statusAssinaturaFiscalizado ===
                  "ASSINADO_DIGITALMENTE" && (
                  <div
                    style={{
                      width: 300,
                      borderBottom: "1px solid black",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="4cm"
                      height="2.5cm"
                      viewBox="0 0 645 600"
                      enableBackground="new 0 0 645 600"
                      xmlSpace="preserve"
                    >
                      <image
                        id="image0"
                        width="17cm"
                        height="15.875cm"
                        x="0"
                        y="0"
                        href={termo?.assinaturaFiscalizado}
                      />
                    </svg>
                  </div>
                )}

                {(termo?.statusAssinaturaFiscalizado === "AUSENTE" ||
                  termo?.statusAssinaturaFiscalizado === "RECUSADO") && (
                  <p
                    style={{
                      borderBottom: "1px solid black",
                      width: 300,
                      height: navigator.onLine ? "2.5cm" : "2cm",
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                      marginBottom: 0,
                    }}
                  >
                    {termo?.statusAssinaturaFiscalizado}
                  </p>
                )}

                {termo?.statusAssinaturaFiscalizado ===
                  "ASSINADO_FISICAMENTE" && (
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      width: 300,
                      height: "2.5cm",
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                    }}
                  />
                )}
                <div
                  style={{
                    textAlign: "center",
                    width: 300,
                    fontSize: 10,
                    height: 40,
                  }}
                >
                  Assinatura do Proprietário / Preposto / Responsável Técnico
                </div>
                <div
                  style={{
                    border: "1px solid black",
                    padding: "0px 4px",
                    width: 300,
                    fontSize: 11,
                  }}
                >
                  <div style={{ borderBottom: "1px solid black" }}>
                    Nome:
                    {termo?.statusAssinaturaFiscalizado === "AUSENTE" ||
                    termo?.statusAssinaturaFiscalizado === "RECUSADO"
                      ? ""
                      : termo?.nomeAssinanteFiscalizado}
                  </div>
                  <div style={{ borderBottom: "1px solid black" }}>
                    CPF/CNPJ:
                    {termo?.statusAssinaturaFiscalizado === "AUSENTE" ||
                    termo?.statusAssinaturaFiscalizado === "RECUSADO"
                      ? ""
                      : cpfCnpjMask(termo?.cpfAssinanteFiscalizado || "")}
                  </div>
                  <div>Cargo:{termo?.funcaoAssinanteFiscalizado}</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </>
  );
}
