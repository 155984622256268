import { IEmpresaAgrotoxico } from "@interfaces/IEmpresaAgrotoxico";
import { apiService } from "@services/api";
import { empresaAgrotoxicoAction } from "@store/actions/empresaAgrotoxicoAction";
import { getEmpresasAgrotoxicos } from "@store/feature/empresaAgrotoxicoSlice";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

export const useEmpresasAgrotoxico = () => {
  const appDispatch = useDispatch();
  const listaEmpresasAgrotoxicoSync = useSelector(getEmpresasAgrotoxicos);
  const { addEmpresas, removeEmpresas } = empresaAgrotoxicoAction;

  const { data: listaEmpresasAgrotoxico } = useQuery({
    queryKey: ["EMPRESAS_AGROTOXICOS"],
    initialData: listaEmpresasAgrotoxicoSync,
    queryFn: () => apiService.empresasAgrotoxicos(),
  });

  const adicionarEmpresasAgrotoxico = (
    empresas: IEmpresaAgrotoxico[],
    persistedAt: number
  ) => {
    appDispatch(addEmpresas(empresas, persistedAt));
  };

  const removerEmpresasAgrotoxico = () => {
    appDispatch(removeEmpresas());
  };

  return {
    listaEmpresasAgrotoxico,
    adicionarEmpresasAgrotoxico,
    removerEmpresasAgrotoxico,
  };
};
