import { ExclamationCircleOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { formatMessageError } from "@functions/formatMessageError";
import { masks } from "@functions/mascaras";
import { getStatusAssinatura } from "@functions/statusAssinatura";
import { transformStringToNumber } from "@functions/transformStringToNumber";
import { useForms } from "@hooks/useForms";
import { useMunicipio } from "@hooks/useMunicipio";
import { useServidores } from "@hooks/useServidores";
import { IAssinante } from "@interfaces/IAssinante";
import { IFormValuesTV } from "@interfaces/IFormValuesTV";
import { IMunicipio } from "@interfaces/IMunicipio";
import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { ITermoVistoria } from "@interfaces/ITermoVistoria";
import { apiService } from "@services/api";
import { ButtonFooterContainer } from "@styles/ButtonFooterContainer";
import {
  Button,
  Divider,
  Form,
  message,
  Modal,
  Result,
  Typography,
} from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import confirm from "antd/lib/modal/confirm";
import fs from "file-saver";
import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { AssinaturaTV } from "../../components/AssinaturaTV";
import { FiscalizadoTV } from "../../components/FiscalizadoTV";
import { PerguntaTV } from "../../components/PerguntaTV";
import { ServidorTV } from "../../components/ServidorTV";
import { getPerguntasEnumeradas } from "../../functions/getPerguntasEnumeradas";
import { getRespostasVistoria } from "../../functions/getRespostasVistoria";
import { useTV } from "../../hooks/useTV";

export function EdicaoTV() {
  const { numTermo } = useParams();
  const {
    submitTermo,
    validateTermo,
    atualizarTermo,
    removerTermo,
    listaTermos,
    listaFormsPerguntaTermoVistoria,
  } = useTV();
  const termoVistoria = listaTermos.find(
    (termo) => termo.numero === numTermo?.replaceAll("_", "/")
  );
  const { getMunicipioPorId } = useMunicipio();
  const { getServidorPorMatricula } = useServidores();
  const { messageSubmitFailed } = useForms();

  const [form] = Form.useForm<IFormValuesTV>();
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const refServidor = useRef<any>(null);
  const { cpfCnpjMask, coordMask, telefoneMask } = masks;

  const [perguntas, setPerguntas] = useState<IPerguntaTermoVistoria[]>([]);
  const [municipiosOptions, setMuncipiosOptions] = useState<IMunicipio[]>([]);
  const [isValidDocument, setIsValidDocument] = useState(true);
  const [fiscalizado, setFiscalizado] = useState<IAssinante>({
    assinatura: null,
    cpfAssinante: "",
    funcaoAssinante: "",
    nomeAssinante: "",
  });
  const [isServidorSelected, setIsServidorSelected] = useState(true);
  const [statusAssinatura, setStatusAssinatura] = useState<CheckboxValueType[]>(
    []
  );
  const [assinaturaServidor, setAssinaturaServidor] = useState("");
  const [isSavingTermo, setIsSavingTermo] = useState(false);

  const { data } = useQuery({
    initialData: listaFormsPerguntaTermoVistoria,
    queryFn: () => apiService.listagemFormularios(),
    queryKey: "FORMULARIO_PERGUNTAS_TERMO_VISTORIA",
  });

  const preencherPerguntas = (perguntas: IPerguntaTermoVistoria[]) => {
    if (!termoVistoria) return;

    perguntas.forEach((perg) => {
      if (perg.pergunta.tipoResposta.includes("ALTERNATIVA")) {
        const resposta = termoVistoria.respostas.find(
          (res) => res.pergunta.id === perg.pergunta.id
        )?.alternativas[0]?.alternativa.id;

        form.setFieldValue(
          `listaPerguntas.${perg.pergunta.id}.respostaAlternativa`,
          resposta
        );
      }

      if (perg.pergunta.tipoResposta.includes("MULTIPLA_ESCOLHA")) {
        const resposta = termoVistoria.respostas
          .find((res) => res.pergunta.id === perg.pergunta.id)
          ?.alternativas.map((alt) => alt.alternativa.id);

        form.setFieldValue(
          `listaPerguntas.${perg.pergunta.id}.respostaMultiplaEscolha`,
          resposta
        );
      }

      if (perg.pergunta.tipoResposta.includes("DESCRITIVA")) {
        const resposta = termoVistoria.respostas.find(
          (res) => res.pergunta.id === perg.pergunta.id
        )?.descricao;

        form.setFieldValue(
          `listaPerguntas.${perg.pergunta.id}.descricao`,
          perg.pergunta.formatoResposta === "DATE" ? moment(resposta) : resposta
        );
      }

      termoVistoria.respostas.forEach((res) => {
        if (res.pergunta.id === perg.pergunta.id) {
          res.campos.forEach((campo, ind) => {
            form.setFieldValue(
              `listaPerguntas.${perg.pergunta.id}.campos.${ind}.resposta`,
              campo.campo.formatoResposta === "DATE"
                ? moment(campo.resposta)
                : campo.resposta
            );
          });
        }
      });
    });
  };

  const modalConfirm = () => {
    confirm({
      title: "Tem certeza que deseja cancelar todas as alterações?",
      okText: "Sim",
      cancelText: "Não",
      okType: "danger",
      onOk: () => {
        form.resetFields();
        navigate("/termo-vistoria/listagem");
      },
      icon: <ExclamationCircleOutlined />,
    });
  };

  const getFormSelected = (formId: number) => {
    return data?.filter((pergunta) => pergunta.id === formId)[0];
  };

  const handleSubmit = async (values: IFormValuesTV) => {
    const keyMessage = "termo-vistoria";

    if (!termoVistoria) return;

    //FAZER VALIDACOES
    const dadosValidacao = {
      assinaturaServidor,
      dadosFiscalizado: fiscalizado,
      statusAssinatura,
    };

    if (!validateTermo(dadosValidacao)) return;

    try {
      let formValues: ITermoVistoria = {
        cpfCnpjFiscalizado:
          values.cnpjFiscalizado?.replace(/[^\d]+/g, "") || "",
        nomeFiscalizado: values.nomeFiscalizado || "",
        emailFiscalizado: values.emailFiscalizado || "",
        telefoneFiscalizado:
          values.telefoneFiscalizado?.replace(/[^\d]+/g, "") || "",
        cepFiscalizado: values.cepFiscalizado || "",
        respostas: getRespostasVistoria(perguntas, values),
        municipioFiscalizado: getMunicipioPorId(values.municipioFiscalizado),
        formulario: getFormSelected(values.tipoEstabelecimentoId || 0) || null,
        assinaturaFiscalizado:
          statusAssinatura.length === 0
            ? fiscalizado?.assinatura?.getTrimmedCanvas().toDataURL() || ""
            : "",
        nomeAssinanteFiscalizado: fiscalizado.nomeAssinante,
        cpfAssinanteFiscalizado: fiscalizado.cpfAssinante.replace(
          /[^\d]+/g,
          ""
        ),
        funcaoAssinanteFiscalizado: fiscalizado.funcaoAssinante,
        assinaturaServidor: assinaturaServidor,
        servidor:
          getServidorPorMatricula(values.matriculaServidor || "") || null,
        numero: termoVistoria.numero,
        statusAssinaturaFiscalizado: getStatusAssinatura(statusAssinatura),
        statusAssinaturaServidor: getStatusAssinatura(statusAssinatura),
        enderecoFiscalizado: values.enderecoFiscalizado || "",
        latGrauFiscalizado:
          transformStringToNumber(values.latGrauFiscalizado || "") || 0,
        latMinFiscalizado:
          transformStringToNumber(values.latMinFiscalizado || "") || 0,
        latSegFiscalizado:
          transformStringToNumber(values.latSegFiscalizado || "") || 0,
        longGrauFiscalizado:
          transformStringToNumber(values.longGrauFiscalizado || "") || 0,
        longMinFiscalizado:
          transformStringToNumber(values.longMinFiscalizado || "") || 0,
        longSegFiscalizado:
          transformStringToNumber(values.longSegFiscalizado || "") || 0,
        dataCadastro: `${moment().format("YYYY-MM-DD[T]HH:mm:ss")}Z`,
        inscricaoEstadualFiscalizado: "",
      };

      if (navigator.onLine) {
        setIsSavingTermo(true);
        message.loading({
          key: keyMessage,
          duration: 0,
          content: "Salvando Termo...",
        });

        await apiService.termoVistoria.enviar(formValues).then((res) => {
          atualizarTermo(res.data);
          message.destroy(keyMessage);
          setIsSavingTermo(false);
          openNotification({
            type: "success",
            message: "Termo de Vistoria Atualizado com sucesso",
            description: "Termo de Vistoria salvo no banco de dados!",
          });
          navigate("/termo-vistoria/listagem");
        });
      } else {
        removerTermo(termoVistoria.numero);
        submitTermo(formValues);

        modal.info({
          title: `Gostaria de baixar um backup do Termo de Vistoria?`,
          icon: <ExclamationCircleOutlined />,
          okText: "Sim",
          onOk: () => {
            let blob = new Blob([JSON.stringify(formValues)], {
              type: "text/plain;charset=utf-8",
            });
            fs.saveAs(blob, `Termo de Vistoria ${formValues.numero}.txt`, {
              autoBom: true,
            });
            openNotification({
              type: "success",
              message: "Termo de Vistoria Atualizado com sucesso!",
              description:
                "Salvamento da edição do Termo de Vistoria pendente, para quando aplicativo houver conexão com a internet.",
            });
            navigate("/termo-vistoria/listagem");
          },
        });
      }
    } catch (error) {
      console.log(error);
      message.destroy(keyMessage);
      setIsSavingTermo(false);
      const messageError = formatMessageError(error);
      openNotification({
        type: "error",
        message: `Não foi possivel editar Termo de Vistoria`,
        description: messageError,
      });
    }
  };

  useEffect(() => {
    document.title = "Edição Termo de vistoria | SISDEV-mobile";
    if (termoVistoria) {
      const perguntasTermo = getPerguntasEnumeradas(termoVistoria);
      setPerguntas(perguntasTermo);
      preencherPerguntas(perguntasTermo);
      form.setFieldsValue({
        cnpjFiscalizado: cpfCnpjMask(termoVistoria.cpfCnpjFiscalizado || ""),
        nomeFiscalizado: termoVistoria.nomeFiscalizado,
        cepFiscalizado: termoVistoria.cepFiscalizado,
        enderecoFiscalizado: termoVistoria.enderecoFiscalizado,
        telefoneFiscalizado: telefoneMask(
          termoVistoria.telefoneFiscalizado || ""
        ),
        emailFiscalizado: termoVistoria.emailFiscalizado,
        ufFiscalizado: termoVistoria.municipioFiscalizado?.uf.id,
        municipioFiscalizado: termoVistoria.municipioFiscalizado?.id,
        latGrauFiscalizado: coordMask(
          termoVistoria.latGrauFiscalizado.toString() || ""
        ),
        latMinFiscalizado: coordMask(
          termoVistoria.latMinFiscalizado.toString() || ""
        ),
        latSegFiscalizado: coordMask(
          termoVistoria.latSegFiscalizado.toString() || ""
        ),
        longGrauFiscalizado: coordMask(
          termoVistoria.longGrauFiscalizado.toString() || ""
        ),
        longMinFiscalizado: coordMask(
          termoVistoria.longMinFiscalizado.toString() || ""
        ),
        longSegFiscalizado: coordMask(
          termoVistoria.longSegFiscalizado.toString() || ""
        ),
        matriculaServidor: termoVistoria.servidor?.matricula,
        nomeServidor: termoVistoria.servidor?.pessoa.nome,
        tipoEstabelecimentoId: termoVistoria.formulario?.id,
      });

      setFiscalizado({
        assinatura: null,
        cpfAssinante: cpfCnpjMask(termoVistoria.cpfAssinanteFiscalizado),
        funcaoAssinante: termoVistoria.funcaoAssinanteFiscalizado,
        nomeAssinante: termoVistoria.nomeAssinanteFiscalizado,
      });
    }

    //eslint-disable-next-line
  }, []);

  if (!termoVistoria) {
    return (
      <>
        <Result
          status="error"
          title="Erro ao buscar Termo de Vistoria"
          subTitle="Por favor tente novamente, se o erro persistir entre em contato com o suporte."
        />
      </>
    );
  }

  return (
    <Fragment>
      {contextHolder}
      <Typography.Title level={2} style={{ textAlign: "center" }}>
        Termo de Vistoria
      </Typography.Title>
      <Divider />

      <Form
        id="form-vistoria"
        labelWrap
        labelAlign="left"
        labelCol={{ flex: "200px", span: 20 }}
        wrapperCol={{ flex: 1, span: 14 }}
        form={form}
        onFinish={handleSubmit}
        onReset={modalConfirm}
        onFinishFailed={messageSubmitFailed}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <FiscalizadoTV
          formVistoria={form}
          municipiosOptions={municipiosOptions}
          setMunicipiosOptions={setMuncipiosOptions}
          isValidDocument={isValidDocument}
          setIsValidDocument={setIsValidDocument}
          setFiscalizado={setFiscalizado}
        />

        <Divider />

        <PerguntaTV
          perguntas={perguntas}
          setPerguntas={setPerguntas}
          form={form}
          listaPerguntas={data || []}
        />

        <Divider />
        <ServidorTV form={form} setIsServidorSelected={setIsServidorSelected} />
      </Form>

      <Divider />

      <AssinaturaTV
        ativarModalInformarNumTermo={() => {}}
        fiscalizado={fiscalizado}
        setFiscalizado={setFiscalizado}
        form={form}
        assinaturaServidor={assinaturaServidor}
        setAssinaturaServidor={setAssinaturaServidor}
        setStatusAssinatura={setStatusAssinatura}
        statusAssinatura={statusAssinatura}
        isServidorSelected={isServidorSelected}
        refServidor={refServidor}
      />

      <ButtonFooterContainer>
        <Button form="form-vistoria" htmlType="reset" disabled={isSavingTermo}>
          Cancelar
        </Button>
        <Button
          type="primary"
          form="form-vistoria"
          htmlType="submit"
          disabled={isSavingTermo}
        >
          Salvar
        </Button>
      </ButtonFooterContainer>
    </Fragment>
  );
}
