import { IPerguntaTermoVistoria } from "@interfaces/IPerguntaTermoVistoria";
import { DatePicker, Form, FormInstance, Input } from "antd";
import styles from "../../style.module.css";

export function Descritiva({
  question,
  form,
}: {
  question: IPerguntaTermoVistoria;
  form: FormInstance;
}) {
  return (
    <div
      style={{
        display: "flex",
        gap: 5,
        flexDirection: "column",
        marginBottom: 10,
      }}
    >
      <label
        className={
          question.pergunta.nome === "OBSERVAÇÕES"
            ? ""
            : styles["label-campo-vistoria"]
        }
      >
        Especificar:
      </label>
      {(!question.pergunta.formatoResposta ||
        question.pergunta.formatoResposta === "TEXTO") && (
        <Form.Item
          name={`listaPerguntas.${question.pergunta.id}.descricao`}
          rules={[
            {
              required: question.pergunta.nome !== "OBSERVAÇÕES",
              message: "Pro favor, insira uma resposta",
            },
          ]}
          noStyle={true}
        >
          <Input
            placeholder={question.pergunta.orientacao}
            style={{ maxWidth: 400 }}
            maxLength={255}
          />
        </Form.Item>
      )}

      {question.pergunta.formatoResposta === "NUMBER" && (
        <Form.Item
          name={`listaPerguntas.${question.pergunta.id}.descricao`}
          rules={[
            {
              required: true,
              message: "Pro favor, insira uma resposta",
            },
          ]}
          noStyle={true}
        >
          <Input
            placeholder={question.pergunta.orientacao}
            style={{ maxWidth: 400 }}
            onChange={(e) => {
              let inputValue = e.target.value;

              // Remove caracteres não numéricos, exceto vírgula
              inputValue = inputValue.replace(/[^0-9,]/g, "");

              // Garante que tenha no máximo uma vírgula
              const parts = inputValue.split(",");
              if (parts.length > 2) {
                inputValue = parts[0] + "," + parts.slice(1).join("");
              }

              form.setFieldValue(
                `listaPerguntas.${question.pergunta.id}.descricao`,
                inputValue
              );
            }}
            maxLength={255}
          />
        </Form.Item>
      )}

      {question.pergunta.formatoResposta === "DATE" && (
        <Form.Item
          name={`listaPerguntas.${question.pergunta.id}.descricao`}
          rules={[
            {
              required: true,
              message: "Pro favor, insira uma resposta",
            },
          ]}
          noStyle={true}
        >
          <DatePicker format={"DD/MM/YYYY"} />
        </Form.Item>
      )}
    </div>
  );
}
