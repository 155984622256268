import { IEstadioFenologico } from "@interfaces/IEstadioFenologico";
import { apiService } from "@services/api";
import { estadioFenologicoAction } from "@store/actions/estadioFenologicoAction";
import { getEstadioFenologico } from "@store/feature/estadioFenologicoSlice";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

export const useEstadioFenologico = () => {
  const estadiosFenologicosSync = useSelector(getEstadioFenologico);
  const { addEstadioFenologico, removeEstadioFenologico } =
    estadioFenologicoAction;
  const appDispatch = useDispatch();

  const { data: listaEstadioFenologico } = useQuery({
    queryKey: ["ESTADIO_FENOLOGICO"],
    initialData: estadiosFenologicosSync,
    queryFn: () => apiService.estadiosFenologicos(),
    onSuccess: (data) => data,
  });

  const syncEstadios = (
    estadios: IEstadioFenologico[],
    persistedAt: number
  ) => {
    appDispatch(addEstadioFenologico(estadios, persistedAt));
  };

  const removeEstadios = () => {
    appDispatch(removeEstadioFenologico());
  };

  return {
    listaEstadioFenologico,
    removeEstadios,
    syncEstadios,
    isEstadioSync: estadiosFenologicosSync.length > 0,
  };
};
