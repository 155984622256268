import { IAnexos } from "@interfaces/IAnexos";
import { ITID } from "@interfaces/ITid";
import { Header } from "../Header";

export function Images({ termo, anexo }: { termo: ITID; anexo: IAnexos }) {
  return (
    <>
      <table
        style={{ width: 780, pageBreakBefore: "always" }}
        className="tid-font"
      >
        <Header tid={termo} />

        <tbody>
          <tr>
            <td
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                textAlign: "center",
                backgroundColor: "#D9D9D9",
                fontWeight: "bold",
                display: "block",
              }}
            >
              Anexo de imagens
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                borderBottom: "1px solid black",
                display: "block",
                minHeight: "80vh",
              }}
            >
              <div
                style={{
                  padding: 50,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={anexo.arquivo}
                  alt={anexo.nomeArquivo}
                  width="75%"
                  // height="auto"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
