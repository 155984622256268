import { ISubmitArmadilha } from "@interfaces/ISubmitArmadilha";
import { createAction } from "@reduxjs/toolkit";
import { IArmadilha } from "../../interfaces/IArmadilha";

const syncArmadilhaByMunicipio = createAction(
  "ARMADILHA/syncArmadilhaByMunicipio",
  (armadilhas: IArmadilha[], municipioId: number, dateTimeCreate: number) => ({
    payload: { armadilhas, municipioId, dateTimeCreate },
  })
);

const removeSyncArmadilhaByMunicipio = createAction(
  "ARMADILHA/removeSyncArmadilhaByMunicipio",
  (municipioId: number) => ({
    payload: { municipioId },
  })
);

const addArmadilhaToSync = createAction(
  "ARMADILHA/addArmadilhaToSync",
  (armadilha: ISubmitArmadilha) => ({
    payload: { armadilha },
  })
);

const removeArmadilhaToSync = createAction(
  "ARMADILHA/removeArmadilhaToSync",
  (offlineId: number) => ({
    payload: { offlineId },
  })
);

const updatePersistedAtItemsSync = createAction(
  "ARMADILHA/updatePersistedAtItemsSync",
  (persistedAtItemsSync: number) => ({
    payload: { persistedAtItemsSync },
  })
);

export const armadilhaAction = {
  syncArmadilhaByMunicipio,
  removeSyncArmadilhaByMunicipio,
  addArmadilhaToSync,
  removeArmadilhaToSync,
  updatePersistedAtItemsSync,
};
