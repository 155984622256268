import { IEspecie } from "@interfaces/IEspecie";
import { api } from "@services/api";

export const getEspecies = async ({isAnaliseSemente}:{isAnaliseSemente:boolean}) => {
  const { data } = await api.get<IEspecie[]>("/vegetal/especies",{
    params: {
      analiseSemente: isAnaliseSemente 
    }
  });
  return data;
};
