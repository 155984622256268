import { IProdutoAgrotoxico } from "@interfaces/IProdutoAgrotoxico";
import { apiService } from "@services/api";
import { produtoAgrotoxicoAction } from "@store/actions/produtoAgrotoxicoAction";
import { getProdutosAgrotoxicos } from "@store/feature/produtoAgrotoxicoSlice";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

export const useProdutosAgrotoxicos = () => {
  const appDispatch = useDispatch();
  const listaProdutosAgrotoxicosSync = useSelector(getProdutosAgrotoxicos);
  const { addProdutosAgrotoxico, removeProdutosAgrotoxico } =
    produtoAgrotoxicoAction;

  const { data: listaProdutosAgrotoxicos } = useQuery({
    queryKey: ["PRODUTOS_AGROTOXICOS"],
    initialData: listaProdutosAgrotoxicosSync,
    queryFn: () => apiService.produtosAgrotoxico(),
  });

  const adicionarProdutos = (
    produtos: IProdutoAgrotoxico[],
    persistedAt: number
  ) => {
    appDispatch(addProdutosAgrotoxico(produtos, persistedAt));
  };

  const removerProdutos = () => {
    appDispatch(removeProdutosAgrotoxico());
  };

  return {
    listaProdutosAgrotoxicos,
    adicionarProdutos,
    removerProdutos,
  };
};
