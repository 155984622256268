import { SyncOutlined } from "@ant-design/icons";
import { openNotification } from "@components/Notifications";
import { formatMessageError } from "@functions/formatMessageError";
import { useMunicipio } from "@hooks/useMunicipio";
import { useServidores } from "@hooks/useServidores";
import { useUle } from "@hooks/useUle";
import { apiService } from "@services/api";
import { Button, Col, List, message, Row, Tooltip } from "antd";

const DADOS_SYNC_AUTOMATICO: {
  label: string;
  value: "ules" | "municipios" | "servidores";
}[] = [
  {
    label: "Servidores",
    value: "servidores",
  },
  {
    label: "Municipios Brasil",
    value: "municipios",
  },
  {
    label: "ULES",
    value: "ules",
  },
];

export function SyncAutomatico() {
  const { adicionarListaComTodosMunicipios } = useMunicipio();
  const { adicionarTodosServidores } = useServidores();
  const { adicionarUles } = useUle();

  const handleUpdate = async (value: "ules" | "municipios" | "servidores") => {
    try {
      message.loading({
        content: "Atualizando dados...",
        key: "loading",
      });

      if (value === "servidores") {
        const result = await apiService.servidores.todos();
        adicionarTodosServidores(result);
      }

      if (value === "municipios") {
        const result = await apiService.municipios();
        adicionarListaComTodosMunicipios(result);
      }

      if (value === "ules") {
        const result = await apiService.ules();
        adicionarUles(result);
      }

      message.destroy("loading");
      openNotification({
        type: "success",
        message: "Dados atualizados com sucesso",
      });
    } catch (error) {
      console.log(error);
      message.destroy("loading");
      const messageError = formatMessageError(error);
      openNotification({
        type: "error",
        message: "Não foi possivel atualizar os dados",
        description: messageError,
      });
    }
  };

  return (
    <>
      <Row align="middle" justify="center">
        <Col span={24}>
          <List
            size="default"
            dataSource={DADOS_SYNC_AUTOMATICO}
            bordered
            renderItem={(item) => (
              <List.Item key={item.value}>
                <List.Item.Meta title={item.label} style={{ opacity: 0.7 }} />
                <div>
                  <Tooltip title="Atualizar">
                    <Button onClick={() => handleUpdate(item.value)}>
                      <SyncOutlined />
                    </Button>
                  </Tooltip>
                </div>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}
